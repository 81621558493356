define('supr-web-ember-v2/controllers/unauthenticated/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    session: service(),
    flashService: service(),
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var _getProperties = this.getProperties('identification', 'password'),
            identification = _getProperties.identification,
            password = _getProperties.password;

        this.send('showLoadingSpinner');

        this.get('session').authenticate('authenticator:torii', 'firebase', {
          provider: 'password',
          email: identification,
          password: password
        }).catch(function (reason) {
          _this.send('hideLoadingSpinner');
          _this.get('flashService').error(reason.message);
          console.error(reason);
        });
      }
    }
  });
});