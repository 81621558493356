define('supr-web-ember-v2/controllers/authenticated/admin/registrations/new', ['exports', 'supr-web-ember-v2/utils/time-helper', 'moment', 'supr-web-ember-v2/mixins/file-handler', 'supr-web-ember-v2/config/constants'], function (exports, _timeHelper, _moment, _fileHandler, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var A = Ember.A;
  var EmberObject = Ember.Object;
  var computed = Ember.computed;
  var observer = Ember.observer;
  var union = Ember.computed.union;
  var isEmpty = Ember.isEmpty;
  var isPresent = Ember.isPresent;
  var debug = Ember.debug;
  var service = Ember.inject.service;
  exports.default = Controller.extend(_fileHandler.default, {
    fileService: service(),
    flashService: service(),
    academyService: service(),

    selectedDay: null,
    selectedTime: null,
    newReservations: A(),
    // reservations are all existing reservations, newReservations are created here
    combinedReservations: union('reservations', 'newReservations'),
    // This should absolutely not be necessary. See the WTFs below.
    observerHack: null,
    selectedGuardian: null,

    planObserver: observer('selectedPlan', function () {
      var plan = this.get('selectedPlan');
      if (plan == _constants.default.plan.NONE) {
        this.set('newReservations', A());
        this.set('selectedStartDate', (0, _moment.default)());
      }
    }),

    availableGuardians: computed('guardians', 'selectedGuardians', 'selectedGuardians.@each', function () {
      var selectedGuardians = this.get('selectedGuardians');
      var unavailableIds = selectedGuardians.map(function (guardian) {
        return guardian.id;
      });
      return this.get('guardians').filter(function (guardian) {
        return !unavailableIds.includes(guardian.id);
      });
    }),

    noGuardianSelection: computed('selectedGuardian', function () {
      return isEmpty(this.get('selectedGuardian'));
    }),

    // Initially available reservation slots are the availabilityTimeSlots where
    // the guide doesn't have any reservations. Once a reservation is pending it
    // shouldn't show up in this list. WTF: Cache isn't getting cleared on
    // slotReservation changes. Weird that the function that observes
    // newReservations fires as expected. Using observerHack to make it work. From
    // the docs "Changing a dependency will not immediately trigger an update of
    // the computed property, but will instead clear the cache so that it is
    // updated when the next get is called on the property."
    availableReservationSlots: computed('availabilityTimeSlots', 'availabilityTimeSlots.@each', 'newReservations', 'newReservations.@each', 'observerHack', function () {
      var availabilities = this.get('availabilityTimeSlots');
      var bookingDuration = this.get('academyService.academy.schedule.bookingDuration');
      var bookingIncrement = this.get('academyService.academy.schedule.bookingIncrement');
      var reservations = this.get('combinedReservations');
      var availableSlots = A();

      var ReservationSlot = EmberObject.extend({
        startTime: null,
        duration: bookingDuration,

        day: computed('startTime', function () {
          // Monday, Tuesday, etc.
          return _moment.default.weekdays()[(0, _moment.default)(this.get('startTime')).day()];
        }),

        displayTime: computed('startTime', function () {
          return (0, _moment.default)(this.get('startTime')).format('h:mm a');
        })
      });

      var isReserved = function isReserved(guide, startTime) {
        var existing = reservations.filterBy('guide.id', guide.get('id')).filter(function (reservation) {
          // check if the startTime is within a reservation or if the
          // startTime + duration is within a reservation
          return (0, _moment.default)(reservation.get('startTime')).day() === (0, _moment.default)(startTime).day() && (_timeHelper.default.isSameTime(reservation.get('startTime'), startTime) || _timeHelper.default.isBetweenTimes(startTime, reservation.get('startTime'), reservation.get('duration')) || _timeHelper.default.isBetweenTimes((0, _moment.default)(startTime).add(bookingDuration, 'm'), reservation.get('startTime'), reservation.get('duration')));
        });
        if (isPresent(existing)) {
          return true;
        } else {
          return false;
        }
      };

      for (var i = 0; i < availabilities.get('length'); i++) {
        var availability = availabilities.objectAt(i);
        var reservation = null;
        var numSlotsCovered = Math.floor(availability.get('duration') / bookingDuration);
        var numIncrementsCovered = availability.get('duration') / bookingIncrement;
        var startTime = (0, _moment.default)(availability.get('startTime'));
        if (numSlotsCovered > 0) {
          for (var j = 0; j < numIncrementsCovered; j++) {
            // This should prevent showing a slot time as an option if you can't
            // make a Booking with it, i.e. if there isn't enough time in the
            // availability to add the booking duration to the start time.
            if (startTime.clone().add(bookingDuration, 'minutes').isBefore((0, _moment.default)(availability.get('endTime')))) {
              if (!isReserved(availability.get('guide'), startTime.toDate())) {
                reservation = ReservationSlot.create({
                  startTime: startTime.clone().toDate(),
                  duration: bookingDuration,
                  guide: availability.get('guide')
                });
                availableSlots.addObject(reservation);
              }
            }
            startTime.add(bookingIncrement, 'minutes');
          }
          // Because you can make a reservation starting anywhere in the
          // time-slot if the duration doesn't exceed the end of the time-slot
          // we check a final slot whose start time is the the time-slot end
          // minus the booking duration
          startTime = (0, _moment.default)(availability.get('endTime')).subtract(bookingDuration, 'minutes');
          if (!isReserved(availability.get('guide'), startTime.toDate())) {
            reservation = ReservationSlot.create({
              startTime: startTime.clone().toDate(),
              duration: bookingDuration,
              guide: availability.get('guide')
            });
            availableSlots.addObject(reservation);
          }
        }
      }

      return availableSlots;
    }),

    reservationObserver: observer('newReservations', 'newReservations.@each', function () {
      this.get('openReservationDays');
      this.get('openReservationTimes');
      this.get('openReservationGuides');
    }),

    // WTF: Super weird. Computed properties aren't clearning caches if
    //      newReservations array changes. Using Ember.A() vs [] makes no difference
    //      (and it shouldn't because we haven't disabled prototype extensions
    //      http://stackoverflow.com/questions/10345083/in-ember-js-what-is-the-difference-between-and-ember-a)
    openReservationDays: computed('selectedDay', 'selectedGuide', 'selectedTime', 'observerHack', function () {
      var slots = this.get('availableReservationSlots');
      var days = [];
      for (var i = 0; i < slots.get('length'); i++) {
        var date = slots.objectAt(i).get('startTime');
        days.addObject((0, _moment.default)(date).format('dddd'));
      }

      return days;
    }),

    daysAvailable: computed('openReservationDays', 'openReservationDays.@each', function () {
      return this.get('openReservationDays.length') > 0;
    }),

    openReservationTimes: computed('selectedDay', 'selectedGuide', 'selectedTime', 'observerHack', function () {
      var slots = this.get('availableReservationSlots');
      var times = [];
      for (var i = 0; i < slots.get('length'); i++) {
        var date = slots.objectAt(i).get('startTime');
        if ((0, _moment.default)(date).format('dddd') === this.get('selectedDay')) {
          times.addObject((0, _moment.default)(date).format('h:mm a'));
        }
      }

      return times;
    }),

    timesAvailable: computed('openReservationTimes', function () {
      return this.get('openReservationTimes.length') > 0;
    }),

    openReservationGuides: computed('selectedDay', 'selectedGuide', 'selectedTime', 'observerHack', function () {
      // console.log('-- openReservationGuides called.');
      var slots = this.get('availableReservationSlots');
      var guides = [];
      for (var i = 0; i < slots.get('length'); i++) {
        var slot = slots.objectAt(i);
        var date = (0, _moment.default)(slot.get('startTime'));
        if (date.format('dddd') === this.get('selectedDay') && date.format('h:mm a') === this.get('selectedTime')) {
          guides.addObject(slot.get('guide'));
        }
      }

      return guides;
    }),

    guidesAvailable: computed('openReservationGuides', function () {
      return this.get('openReservationGuides.length') > 0;
    }),

    actions: {
      submit: function submit() {
        this.send('createRegistration');
      },

      /* File Actions */
      courseSyllabusSelected: function courseSyllabusSelected(file) {
        // TODO disable button and show spinner while saving
        var self = this;
        return this.get('fileService').save(file, null).then(function (theFile) {
          self.set('learningProfileFields.courseSyllabus', theFile);
        }).catch(function (err) {
          console.log(err);
          debug('failed to save course syllabus ' + err);
        });
      },
      removeCourseSyllabus: function removeCourseSyllabus(file) {
        // TODO disable button and show spinner while saving
        this.set('learningProfileFields.courseSyllabus', null);
        this.set('pickCourseSyllabus', false);
        return this.get('fileService').delete(file);
      },
      courseScheduleSelected: function courseScheduleSelected(file) {
        // TODO disable button and show spinner while saving
        var self = this;
        return this.get('fileService').save(file, null).then(function (theFile) {
          self.set('learningProfileFields.courseSchedule', theFile);
        }).catch(function (err) {
          console.log(err);
          debug('failed to save course schedule ' + err);
        });
      },
      removeCourseSchedule: function removeCourseSchedule(file) {
        // TODO disable button and show spinner while saving
        this.set('learningProfileFields.courseSchedule', null);
        this.set('pickCourseSchedule', false);
        return this.get('fileService').delete(file);
      },
      agreementSelected: function agreementSelected(file) {
        // TODO disable button and show spinner while saving
        var self = this;
        return this.get('fileService').save(file, null).then(function (theFile) {
          self.set('fields.agreement', theFile);
        }).catch(function (err) {
          console.log(err);
          debug('failed to save agreement file ' + err);
        });
      },
      removeAgreement: function removeAgreement(file) {
        // TODO disable button and show spinner while saving
        this.set('fields.agreement', null);
        this.set('pickAgreement', false);
        return this.get('fileService').delete(file);
      },

      addGuardian: function addGuardian(guardian) {
        this.get('selectedGuardians').addObject(guardian);
        this.set('selectedGuardian', null);
      },

      removeGuardian: function removeGuardian(guardian) {
        this.get('selectedGuardians').removeObject(guardian);
      },

      selectReservationDay: function selectReservationDay(day) {
        this.set('selectedDay', day);
      },

      selectReservationTime: function selectReservationTime(time) {
        this.set('selectedTime', time);
      },

      selectReservationGuide: function selectReservationGuide(selectedGuide) {
        var self = this;

        // Converting the temporary ReservationSlot objects to reservation records
        // will happen when the registration is updated
        this.get('availableReservationSlots').forEach(function (reservation) {
          var date = (0, _moment.default)(reservation.get('startTime'));
          var day = _moment.default.weekdays()[date.day()];
          var time = date.format('h:mm a');
          var guide = reservation.get('guide');

          if (day === self.get('selectedDay') && time === self.get('selectedTime') && guide.get('id') === selectedGuide.get('id')) {
            self.get('newReservations').addObject(reservation);
            self.set('observerHack', Date.now());
            self.set('selectedDay', null);
            self.set('selectedTime', null);
            self.set('selctedGuide', null);
          }
        });
      },

      removeReservation: function removeReservation(reservedSlot) {
        // reset so slot appears available again
        this.get('newReservations').removeObject(reservedSlot);
        this.set('selectedDay', null);
        this.set('selectedTime', null);
        this.set('selectedGuide', null);
        this.set('observerHack', Date.now());
      }
    }
  });
});