define('supr-web-ember-v2/models/registration', ['exports', 'ember-data', 'supr-web-ember-v2/models/base', 'moment'], function (exports, _emberData, _base, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _base.default.extend({
    isActive: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    plan: _emberData.default.attr('string'), // One of CONSTANTS.plan
    startDate: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    student: _emberData.default.belongsTo('student', {
      async: true
    }),
    guardians: _emberData.default.hasMany('guardian', {
      async: true
    }),
    agreement: _emberData.default.belongsTo('file', {
      async: true
    }),
    registrationDate: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    renewalDate: _emberData.default.attr('date'),
    notes: _emberData.default.attr('string'),

    displayCreatedAtDate: computed('createdAt', function () {
      return (0, _moment.default)(this.get('createdAt')).format('ddd, MMM Do, h:mm a z');
    }),

    displayRegistrationDate: computed('registrationDate', function () {
      return (0, _moment.default)(this.get('registrationDate')).format('ddd, MMM Do, h:mm a z');
    })
  });
});