define('supr-web-ember-v2/models/reservation', ['exports', 'ember-data', 'supr-web-ember-v2/models/scheduable', 'moment'], function (exports, _emberData, _scheduable, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var notEmpty = Ember.computed.notEmpty;
  var uniq = Ember.computed.uniq;
  exports.default = _scheduable.default.extend({
    student: _emberData.default.belongsTo('student', {
      async: true
    }),
    guide: _emberData.default.belongsTo('guide', {
      async: true
    }),
    bookings: _emberData.default.hasMany('booking', {
      async: true,
      inverse: 'reservation'
    }),

    // A Booking has booked over this Reservation
    bookedOverReservationConflicts: _emberData.default.hasMany('booking-over-reservation-conflict', {
      async: true
    }),
    // This Reservation has booked over a Booking
    bookerReservationConflicts: _emberData.default.hasMany('reservation-over-booking-conflict', {
      async: true
    }),

    hasBookedOverReservationConflicts: notEmpty('bookedOverReservationConflicts'),
    hasBookerReservationConflicts: notEmpty('bookerReservationConflicts'),

    hasConflicts: computed('hasBookedOverReservationConflicts', 'hasBookerReservationConflicts', function () {
      return this.get('hasBookedOverReservationConflicts') || this.get('hasBookerReservationConflicts');
    }),

    hasActiveBookings: computed('bookings', 'bookings.@each', function () {
      var hasActive = false;
      this.get('bookings').forEach(function (booking) {
        if (booking.get('isActive')) {
          hasActive = true;
        }
      });
      return hasActive;
    }),

    conflicts: uniq('bookedOverReservationConflicts', 'bookerReservationConflicts'),

    // TODO: scheduable is returning a date instead of a moment. Make them the same
    //        so we don't have to define this here
    endTime: function endTime() {
      return (0, _moment.default)(this.get('startTime')).add(this.get('duration', 'minutes'));
    },

    // TODO: This is almost the same as scheduable.displayStartTime. Make them the
    //       same
    displayTime: computed('startTime', function () {
      return (0, _moment.default)(this.get('startTime')).format('h:mm a');
    })
  });
});