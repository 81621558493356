define('supr-web-ember-v2/controllers/authenticated/admin/registrations', ['exports', 'supr-web-ember-v2/utils/archive-helper'], function (exports, _archiveHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var observer = Ember.observer;
  var filter = Ember.computed.filter;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    flashService: service(),

    showAll: false,
    showModal: false,

    activeRegistrations: filter('model.@each.isActive', function (registration) {
      return registration.get('isActive');
    }),

    archiveToggle: observer('showAll', function () {
      if (this.get('showAll')) {
        this.set('registrations', this.get('model'));
      } else {
        this.set('registrations', this.get('activeRegistrations'));
      }
    }),

    actions: {
      archive: function archive(registration) {
        this.set('showModal', true);
        this.set('selectedRegistration', registration);
      },

      archiveRegistration: function archiveRegistration() {
        var _this = this;

        var self = this;
        var registration = this.get('selectedRegistration');

        _archiveHelper.default.archiveRegistration(registration).then(function () {
          self.get('flashService').success('Archived');
          self.transitionToRoute('authenticated.admin.registrations');
        }).catch(function (err) {
          self.get('flashService').error('Registration archival failed.\n' + err);
        }).finally(function () {
          self.set('showModal', false);
          self.set('selectedRegistration', null);
          // Trigger update of the filter
          if (!self.get('showAll')) {
            self.set('registrations', _this.get('activeRegistrations'));
          }
        });
      },

      unArchiveRegistration: function unArchiveRegistration(registration) {
        var self = this;
        _archiveHelper.default.unarchiveRegistration(registration).then(function () {
          self.get('flashService').success('Un-archived', 'success');
          self.transitionToRoute('authenticated.admin.registrations');
        }).catch(function (err) {
          self.get('flashService').error('Registration un-archival failed.\n' + err);
        });
      }
    }
  });
});