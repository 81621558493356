define('supr-web-ember-v2/routes/application', ['exports', 'supr-web-ember-v2/mixins/application-route-mixin', 'supr-web-ember-v2/mixins/progress'], function (exports, _applicationRouteMixin, _progress) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  var later = Ember.run.later;
  exports.default = Route.extend(_applicationRouteMixin.default, _progress.default, {
    currentUserService: service(),
    academyService: service(),
    flashService: service(),
    spinner: service(),

    // NOTE:
    // We were having the issue where logging out and back in would not take the user to the
    // post login configured route, the dashboard.
    // We fixed an issue along the way with trying to load the current user immediately after
    // log out but there is still a transition aborted error that happens.
    // Logout causes invalidationSucceeded to fire which triggers the a transition to the root
    // URL in order to clear any in memory data. Because our root URL is our authenticated route
    // the transition is abored and retried instead of using this.transitionTo(this.get('routeAfterAuthentication'));
    // We fixed the transition handling to the routeAfterAuthentication by extending the Ember Simple Auth
    // ApplicationRouteMixin and handling the case.
    // Our store is getting cleared but we still have a pesky TransitionAborted error that app.js catches.
    // It's unclear
    // See: https://github.com/simplabs/ember-simple-auth/issues/1855
    sessionAuthenticated: function sessionAuthenticated() {
      var _this = this,
          _arguments = arguments;

      console.log('Handle session authenticationSucceeded...');

      var _super = this._super;

      // Make sure the current user loads before the transition happens
      // See: https://github.com/simplabs/ember-simple-auth/issues/1838
      this._loadCurrentUser().then(function () {
        _super.call.apply(_super, [_this].concat(Array.prototype.slice.call(_arguments)));
      });
    },
    invalidationSucceeded: function invalidationSucceeded() {
      console.log('Handle session invalidationSucceeded...');
      this.get('spinner').hide('auth-loading-spinner');
      this._super.apply(this, arguments);
    },
    _loadCurrentUser: function _loadCurrentUser() {
      var _this2 = this;

      return this.get('currentUserService').load().catch(function (err) {
        _this2.get('flashService').error(err);

        // Delay added so that error messages are visible before the app resets
        // due to the invalidation
        later(_this2, function () {
          this.get('session').invalidate();
        }, 2000);
      }).finally(function () {
        _this2.get('spinner').hide('auth-loading-spinner');
      });
    },


    actions: {
      showLoadingSpinner: function showLoadingSpinner() {
        this.get('spinner').show('auth-loading-spinner');
      },

      hideLoadingSpinner: function hideLoadingSpinner() {
        this.get('spinner').hide('auth-loading-spinner');
      }
    }
  });
});