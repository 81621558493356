define('supr-web-ember-v2/routes/authenticated/admin/registrations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var all = Ember.RSVP.all;
  exports.default = Route.extend({
    model: function model() {
      return this.store.findAll('registration');
    },

    afterModel: function afterModel(model) {
      // preload the guardians so we can determine if they have multiple active students
      return all(model.map(function (registration) {
        return registration.get('guardians');
      }));
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('registrations', controller.get('activeRegistrations'));
    }
  });
});