define('supr-web-ember-v2/services/academy-service', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var isEmpty = Ember.isEmpty;
  var all = Ember.RSVP.all;
  var debug = Ember.debug;
  exports.default = Service.extend({
    store: service(),
    flashService: service(),

    load: function load() {
      var _this = this;

      var self = this;
      return this.get('store').findAll('academy').then(function (acme) {
        var academy = acme.get('firstObject');
        var promises = [];

        if (isEmpty(academy)) {
          debug('No academy found. One will be created');
          // If we don't specify at least one property this doesn't get saved to the db
          // May have to do save in a second step but don't think so
          academy = self.get('store').createRecord('academy', { name: 'SuprAcademy' });
          promises.push(academy.save().then(function (academy) {
            self.set('academy', academy);

            var address = self.get('store').createRecord('address', {
              city: 'Toronto'
            });
            promises.push(address.save());

            academy.set('address', address);
            var schedule = self.get('store').createRecord('academy-schedule', {
              bookingFirstTime: (0, _moment.default)().hours(9).minutes(0).seconds(0), // 9 am
              bookingLastTime: (0, _moment.default)().hours(20).minutes(0).seconds(0), // 8 pm
              bookingDuration: 60, // 60 min
              bookingGap: 30, // 30 min
              bookingIncrement: 30, // 30 min
              semesterStart: new Date(),
              semesterEnd: (0, _moment.default)().add(1, 'M').toDate()
            });
            promises.push(schedule.save());

            academy.set('schedule', schedule);
            promises.push(academy.save());
          }));
        } else {
          self.set('academy', academy);
          promises.push(academy.get('address').then(function () {
            return academy.get('schedule');
          }));
        }

        return all(promises);
      }).catch(function (err) {
        return _this.get('flashService').error(err);
      });
    }
  });
});