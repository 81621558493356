define('supr-web-ember-v2/controllers/authenticated/admin/guardians/guardian/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var isPresent = Ember.isPresent;
  var all = Ember.RSVP.all;
  exports.default = Controller.extend({
    unlinkedStudents: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('unlinkedStudents', []);
    },

    actions: {
      // Linking a student will also add the Guardian to the students regstration
      linkStudent: function linkStudent() {
        this.set('showStudentList', true);
      },

      unlinkStudent: function unlinkStudent(student) {
        this.get('linkableStudents').push(student);
        var guardian = this.get('model');
        guardian.get('students').removeObject(student);
        // Remove the students registration from the Guardians
        student.get('registration').then(function (registration) {
          if (isPresent(registration)) {
            guardian.get('registrations').then(function (registrations) {
              registrations.removeObject(registration);
            });
            registration.get('guardians').removeObject(guardian);
          }
        });
        student.get('guardians').then(function (guardians) {
          guardians.removeObject(guardian);
        });
        this.get('unlinkedStudents').addObject(student);
      },

      save: function save() {
        var self = this;
        var promises = [];
        var guardian = this.get('model');
        this.get('model.address').then(function (address) {
          // Address is not always created initially
          if (isPresent(address)) {
            promises.push(address.save());
          }
        });
        if (isPresent(this.get('selectedStudent'))) {
          var student = this.get('selectedStudent');

          // NOTE:
          // Now that we can create Students outside of a registration, there
          // may not be a registration at the time of linking a Guardian and
          // a Student.
          student.get('registration').then(function (registration) {
            if (registration) {
              guardian.get('registrations').then(function (registrations) {
                registrations.addObject(registration);
                promises.push(guardian.save());
                promises.push(student.save());
              });
            }
            guardian.get('students').then(function (students) {
              students.addObject(student);
              promises.push(student.save());
              promises.push(guardian.save());
            });
          });
          student.get('guardians').then(function (guardians) {
            guardians.addObject(guardian);
            promises.push(student.save());
          });
        }
        if (isPresent(this.get('unlinkedStudents'))) {
          // Case where no registration but an unlink occurs
          promises.push(guardian.save());
          this.get('unlinkedStudents').forEach(function (student) {
            promises.push(student.save());
            student.get('registration').then(function (registration) {
              if (isPresent(registration)) {
                promises.push(registration.save());
              }
            });
          });
        }
        all(promises).then(function () {
          self.get('model').save();
          self.transitionToRoute('authenticated.admin.guardians.guardian');
        });
      },

      cancel: function cancel() {
        this.transitionToRoute('authenticated.admin.guardians');
      }
    }
  });
});