define('supr-web-ember-v2/routes/authenticated/admin/bookings', ['exports', 'supr-web-ember-v2/utils/booking-helper'], function (exports, _bookingHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  var all = Ember.RSVP.all;
  exports.default = Route.extend({
    flashService: service(),
    notifyService: service(),
    currentUserService: service(),

    // Bookings can be made manually and are also created automatically when a
    // registration is created or renewed.
    model: function model() {
      return this.store.findAll('booking');
    },

    // Preload the conflicts
    afterModel: function afterModel(model) {
      // FIXME:
      // We lose the relations if we navigate to a different route and back.
      return all(model.map(function (booking) {
        return booking.get('loadRelations');
      }));

      // FIXME:
      // Not sure why the following doesn't work but the above does.
      // return all(model.map(booking => DataHelper.loadRelations(booking)));
    },

    actions: {
      cancel: function cancel(booking) {
        var self = this;
        var student = void 0;
        booking.set('status', 'cancelled');
        booking.set('cancelledOn', new Date());
        booking.set('cancelledBy', self.get('session.currentUser.userType') + ' ' + self.get('session.currentUser.fullName'));
        booking.save().then(function () {
          return _bookingHelper.default.destroyConflicts(booking.get('conflicts'));
        }).then(function () {
          return _bookingHelper.default.safeDestroyLearningSession(booking);
        }).then(function () {
          return booking.get('student');
        }).then(function (theStudent) {
          student = theStudent;
          return booking.get('guide');
        }).then(function (theGuide) {
          var recipients = [{ model: 'student', id: student.get('id') }, { model: 'guide', id: theGuide.get('id') }];
          var guardians = student.get('guardians');
          guardians.forEach(function (guardian) {
            recipients.addObject({ model: 'guardian', id: guardian.get('id') });
          });
          self.get('notifyService').sendNotification(recipients, 'The booking for ' + student.get('fullName') + ' with ' + theGuide.get('fullName') + ' on ' + booking.get('displayDate') + ' has been cancelled.', { route: 'authenticated.bookings' });
          this.get('flashService').success('Booking cancelled');
        });
      },

      confirmBooking: function confirmBooking(booking) {
        booking.set('status', 'confirmed');
        booking.save().then(function () {
          this.get('flashService').success('Booking confirmed.');
        }).catch(function (err) {
          this.get('flashService').error('Failed to confirm Booking.' + err);
        });
      }
    }
  });
});