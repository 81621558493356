define('supr-web-ember-v2/router', ['exports', 'supr-web-ember-v2/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberRouter = Ember.Router;


  var Router = EmberRouter.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL // Required for serving statically from Firebase or similar
  });

  Router.map(function () {
    this.route('unauthenticated', { path: '/u' }, function () {
      this.route('login');
      this.route('password-reset');
      this.route('password-reset-finish', { path: '/finishReset' });
      this.route('password-update');
      // Sign Up
      this.route('signup');
      this.route('new-account', {
        path: '/new'
      });
    });
    this.route('authenticated', { path: '' }, function () {
      this.route('unauthorized');

      // Super User
      this.route('super', function () {
        this.route('users');
        this.route('repair');
        this.route('files');
        this.route('test');
      });

      // Admin
      this.route('admin', function () {
        this.route('registrations', function () {
          this.route('new', { path: '/new' });
          this.route('registration', { path: '/registration/:registration_id' });
          this.route('edit', { path: '/registration/:registration_id/edit' });
        });

        this.route('academy', function () {
          this.route('edit', {
            path: '/:academy_id/edit'
          });
        });
        // this.route('calendar');
        this.route('create-user');
        this.route('students', function () {
          this.route('student', {
            path: '/:student_id'
          }, function () {
            this.route('edit');
          });
        });
        this.route('guardians', function () {
          this.route('guardian', {
            path: '/:guardian_id'
          }, function () {
            this.route('edit');
          });
        });
        this.route('guides', function () {
          this.route('guide', {
            path: '/:guide_id'
          }, function () {
            this.route('edit');
          });
        });

        this.route('bookings', function () {
          this.route('new', { path: '/new' });
          this.route('edit', { path: '/:booking_id/edit' });
        });

        this.route('reservations');
      });

      // All Users
      this.route('profile');
      this.route('dashboard');
      this.route('todos', {
        path: '/todos'
      }, function () {
        this.route('todo', {
          path: '/:todo_id'
        }, function () {
          this.route('preview', {
            path: '/:file_id'
          });
        });
      });
      this.route('room', {
        path: '/room'
      });
      this.route('files', {
        path: '/files'
      }, function () {
        this.route('file', {
          path: '/:file_id'
        });
      });

      // Guardians Only
      this.route('guardian', { path: '/guardian' }, function () {
        this.route('student', { path: '/:student_id' });
      });

      // Guide Only
      this.route('availability');
      this.route('bookings');
      this.route('students', {
        path: '/students'
      }, function () {
        this.route('student', {
          path: '/:student_id'
        }, function () {
          this.route('dashboard', {
            path: '/dashboard'
          });

          this.route('todos', {
            path: '/todos'
          }, function () {
            this.route('todo', {
              path: '/:todo_id'
            });
          });

          this.route('files', {
            path: '/files'
          }, function () {
            this.route('file', {
              path: '/:file_id'
            });
          });

          this.route('profile', {
            path: '/profile'
          });
        });
      });
    });
  });

  exports.default = Router;
});