define('supr-web-ember-v2/controllers/authenticated/admin/students/student/edit', ['exports', 'supr-web-ember-v2/mixins/file-handler'], function (exports, _fileHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  var debug = Ember.debug;
  var all = Ember.RSVP.all;
  exports.default = Controller.extend(_fileHandler.default, {
    fileService: service(),

    selectedSpecialProgram: '',
    selectedTextbook: '',

    reset: function reset() {
      this.set('selectedPrimaryGuide', null);
      this.set('selectedSpecialProgram', null);
      this.set('selectedTextbook', null);
    },

    actions: {
      submit: function submit() {
        this.send('save');
      },

      courseSyllabusSelected: function courseSyllabusSelected(file) {
        // TODO: disable button and show spinner while saving
        var self = this;
        return this.get('fileService').save(file).then(function (theFile) {
          return self.get('model.learningProfile').then(function (theLearningProfile) {
            theLearningProfile.set('courseSyllabus', theFile);
            return theLearningProfile.save();
          });
        }).catch(function (err) {
          console.log(err);
          debug('Failed to save course syllabus ' + err);
        });
      },

      removeCourseSyllabus: function removeCourseSyllabus(file) {
        var self = this;
        var promises = [];
        // TODO:
        // Disable button and show spinner while saving
        this.get('model.learningProfile').then(function (theLearningProfile) {
          theLearningProfile.set('courseSyllabus', null);
          self.set('pickCourseSyllabus', false);
          promises.push(theLearningProfile.save());
          promises.push(self.get('fileService').delete(file));
          // TODO:
          // Failed file operations are caught in the service. Presumably we should
          // add a catch for failing to save the learning profile.
          return all(promises).catch(function (err) {
            console.log(err);
            debug('Failed to remove course syllabus ' + err);
          });
        });
      },

      courseScheduleSelected: function courseScheduleSelected(file) {
        // TODO: disable button and show spinner while saving
        var self = this;
        return this.get('fileService').save(file).then(function (theFile) {
          return self.get('model.learningProfile').then(function (theLearningProfile) {
            theLearningProfile.set('courseSchedule', theFile);
            return theLearningProfile.save();
          });
        }).catch(function (err) {
          console.log(err);
          debug('Failed to save course schedule ' + err);
        });
      },

      removeCourseSchedule: function removeCourseSchedule(file) {
        var self = this;
        var promises = [];
        // TODO: disable button and show spinner while saving
        this.get('model.learningProfile').then(function (theLearningProfile) {
          self.set('pickCourseSchedule', false);
          theLearningProfile.set('courseSchedule', null);
          promises.push(theLearningProfile.save());
          promises.push(self.get('fileService').delete(file));
          return all(promises).catch(function (err) {
            console.log(err);
            debug('Failed to remove course schedule ' + err);
          });
        });
      }
    }
  });
});