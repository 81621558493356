define('supr-web-ember-v2/models/schedule-conflict', ['exports', 'ember-data', 'supr-web-ember-v2/config/constants', 'supr-web-ember-v2/models/base'], function (exports, _emberData, _constants, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _base.default.extend({
    status: _emberData.default.attr('string', {
      defaultValue: _constants.default.scheduleConflict.status.UNRESOLVED
    }),
    resolved: computed('status', function () {
      return this.get('status') === _constants.default.scheduleConflict.status.RESOLVED;
    })
  });
});