define('supr-web-ember-v2/routes/authenticated/todos/todo', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    flashService: service(),
    can: service(),

    afterModel: function afterModel(model) {
      if (this.get('can').cannot('view todo', model)) {
        this.get('flashService').error('You are not authorized to view this todo.');
        this.transitionTo('authenticated.unauthorized');
      }
    }
  });
});