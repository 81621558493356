define('supr-web-ember-v2/components/toggle-archive', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['toggle-archive'],

    actions: {
      toggleArchive: function toggleArchive() {
        this.sendAction();
      }
    }
  });
});