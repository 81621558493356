define('supr-web-ember-v2/services/flash-service', ['exports', 'ember-uuid'], function (exports, _emberUuid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var A = Ember.A;
  exports.default = Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('messages', A());
    },


    // Adds new lines if the incoming msg already has content
    buildMessage: function buildMessage(msg, addition) {
      if (msg && msg.length > 0) {
        return msg + ('\n\n' + addition);
      }
      return addition;
    },
    showMessage: function showMessage(msg, t) {
      this.get('messages').pushObject({
        id: (0, _emberUuid.v1)(),
        text: msg.replace(/\n/g, '<br>'),
        type: t
      });
    },
    removeMessage: function removeMessage(id) {
      var item = this.get('messages').find(function (msg) {
        return msg.id == id;
      });
      this.get('messages').removeObject(item);
    },
    error: function error(msg) {
      this.showMessage(msg, 'error');
    },
    success: function success(msg) {
      this.showMessage(msg, 'success');
    },
    info: function info(msg) {
      this.showMessage(msg, 'info');
    }
  });
});