define('supr-web-ember-v2/components/mt-booking-start-time', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['mt-booking-start-time'],

    time: computed('record.startTime', function () {
      return this.get('record.startTime');
    })
  });
});