define('supr-web-ember-v2/controllers/authenticated/super/test', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  var resolve = Ember.RSVP.resolve;
  exports.default = Controller.extend({
    flashService: service(),
    mailService: service(),

    actions: {
      throwError: function throwError() {
        throw new Error('Test error via super user');
      },
      unhandledPromiseError: function unhandledPromiseError() {
        return resolve().then(function () {
          throw new Error('Unhandled Promise error test via super user');
        });
      },
      sendTestEmail: function sendTestEmail() {
        var msg = {
          email: 'jeffcressman+test@bitfarmers.io',
          name: 'Jeff',
          subject: 'Test email from app',
          body: 'Just a test'
        };
        this.get('mailService').sendEmail(msg);
      }
    }
  });
});