define('supr-web-ember-v2/components/pill-nav-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: 'li',
    ariaRole: 'presentation',
    classNameBindings: ['active'],

    actions: {
      click: function click() {
        this.sendAction('navItemSelected', this.get('model'));
      }
    }
  });
});