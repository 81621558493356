define('supr-web-ember-v2/controllers/authenticated/profile', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  var observer = Ember.observer;
  var all = Ember.RSVP.all;
  exports.default = Controller.extend({
    flashService: service(),
    fileService: service(),
    pick: false,

    // IMPROVE:
    // This works is set to a particular attribute but not if trying to watch
    // changes to the model with model.hasDirtyAttributes
    // For now observe all the attributes by name...
    // This is all around a stupid way to handle UI state around dirtiness.
    // hasDirtyAttributes returns true if you make a non-null attribute have a value
    // and then remove the value... like it's a different null value...
    // We probably want to use something like bootstrap changeset validations:
    // https://www.ember-bootstrap.com/#/addons
    disableEdit: true,
    dirty: observer('model.{firstName,lastName,cellPhoneNumber,homePhoneNumber,emergencyContactNumber,emergencyContactName,workEmail,workPhoneNumber,address.street,address.city,address.province,address.postCode}', function () {
      if (this.get('model.hasDirtyAttributes') || this.get('model.address.hasDirtyAttributes')) {
        this.set('disableEdit', false);
      } else {
        this.set('disableEdit', true);
      }
    }),

    // Cause the computed property to be updated and the image to reload
    imageObserver: observer('model.avatarURL', function () {
      this.get('model.avatarURL');
    }),

    actions: {
      upload: function upload() {
        this.set('pick', true);
      },

      // NOTE Because we just want the URL we don't save the File on the user. This may make a case for having file/ and profile/ etc. paths in storage
      fileSelected: function fileSelected(file) {
        var self = this;

        return this.get('fileService').save(file, null).then(function (file) {
          self.send('showLoadingSpinner');
          self.set('model.avatarURL', file.get('url'));
          return self.get('model').save();
        }).catch(function (err) {
          return self.get('flashService').error('Failed to update Profile image', err);
        }).finally(function () {
          return self.send('hideLoadingSpinner');
        });
      },

      cancelProfileImageUpdate: function cancelProfileImageUpdate() {
        this.currentModel.rollbackAttribute('avatarURL');
      },
      imageUploadError: function imageUploadError(error) {
        this.get('flashService').showMessage(error, 'error');
      },
      updateUser: function updateUser() {
        var self = this;
        this.send('showLoadingSpinner');

        var promises = [this.get('model').save(), this.get('model').get('address.content').save()];

        all(promises).then(function () {
          self.get('flashService').success('Profile updated');
        }).catch(function (err) {
          self.get('flashService').error(err);
          self.get('model').rollbackAttributes();
        }).finally(function () {
          self.send('hideLoadingSpinner');
        });
      },
      cancelUpdate: function cancelUpdate() {
        this.get('model').rollbackAttributes();
        this.get('model.address.content').rollbackAttributes();
        this.get('flashService').success('Profile changes cancelled.');
      },
      onClose: function onClose() {
        this.set('pick', false);
      },
      onError: function onError(file, error) {
        this.get('flashService').error(error);
      }
    }
  });
});