define('supr-web-ember-v2/utils/data-helper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var all = Ember.RSVP.all;
  exports.default = {
    addhttp: function addhttp(url) {
      if (url === '') {
        return url;
      }

      if (!/^https?:\/\//.test(url)) {
        url = 'https://' + url;
      }
      return url;
    },
    // FIXME:
    // This dosn't work but the loadRelations function on the bookings model does
    loadRelations: function loadRelations(model) {
      var result = [];
      model.eachRelationship(function (name) {
        result.push(model.get(name));
      });
      return all(result);
    }
  };
});