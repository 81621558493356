define('supr-web-ember-v2/models/duplicate-account', ['exports', 'ember-data', 'supr-web-ember-v2/models/base'], function (exports, _emberData, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    email: _emberData.default.attr('string'),
    accounts: _emberData.default.attr()
  });
});