define('supr-web-ember-v2/utils/time-helper', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    // Unlinke moment.isBefore this only checks the hours and minutes
    isBeforeTime: function isBeforeTime(dateA, dateB) {
      var momentA = (0, _moment.default)(dateA);
      var momentB = (0, _moment.default)(dateB);

      return momentA.hour() < momentB.hour() || momentA.hour() == momentB.hour() && momentA.minutes() < momentB.minutes();
    },

    // Unlinke moment.isAfter this only checks the hours and minutes
    isAfterTime: function isAfterTime(dateA, dateB) {
      var momentA = (0, _moment.default)(dateA);
      var momentB = (0, _moment.default)(dateB);

      return momentA.hour() > momentB.hour() || momentA.hour() == momentB.hour() && momentA.minutes() > momentB.minutes();
    },

    isSameTime: function isSameTime(dateA, dateB) {
      var momentA = (0, _moment.default)(dateA);
      var momentB = (0, _moment.default)(dateB);

      // Ember.Logger.debug('TimeHelper.isSameTime ' + momentA.hour() + 'h ' + momentB.hour() + 'h ' + momentA.minutes() + 'min ' + momentB.minutes() + 'min');
      // Ember.Logger.debug('TimeHelper.isSameTime=' + (momentA.hour() === momentB.hour() && momentA.minutes() === momentB.minutes()));

      return momentA.hour() === momentB.hour() && momentA.minutes() === momentB.minutes();
    },

    // Returns true if checkDate time is between targetDate time and targetDate time + targetDate duration
    // The targetDate day is discarded
    isBetweenTimes: function isBetweenTimes(checkDate, targetDate, duration, inclusivity) {
      var myInclusivity = '()'; // same as Moment default
      var tempA = (0, _moment.default)(checkDate);
      var day = _moment.default.weekdays()[tempA.day()];
      var tempB = (0, _moment.default)(targetDate);
      var momentA = (0, _moment.default)().day(day).hour(tempA.hour()).minutes(tempA.minutes());
      var momentB = (0, _moment.default)().day(day).hour(tempB.hour()).minutes(tempB.minutes());
      var momentC = (0, _moment.default)(momentB).add(duration, 'minutes');

      if (inclusivity && (inclusivity === '()' || inclusivity === '[)' || inclusivity === '(]' || inclusivity === '[]')) {
        myInclusivity = inclusivity;
      }

      return momentA.isBetween(momentB, momentC, 'minutes', myInclusivity);
    },

    /**
     * Returns true if the inner time range is within the outer time range, irrespective of
     * the day and date.
     */
    rangeIsWithinRange: function rangeIsWithinRange(innerStartTime, innerEndTime, outerStartTime, outerEndTime) {
      var day = _moment.default.weekdays()[(0, _moment.default)().day()];
      var mInnerStart = (0, _moment.default)(innerStartTime);
      var mInnerEnd = (0, _moment.default)(innerEndTime);
      var mOuterStart = (0, _moment.default)(outerStartTime);
      var mOuterEnd = (0, _moment.default)(outerEndTime);

      var innerStart = (0, _moment.default)().day(day).hour(mInnerStart.hour()).minutes(mInnerStart.minutes());
      var innerEnd = (0, _moment.default)().day(day).hour(mInnerEnd.hour()).minutes(mInnerEnd.minutes());
      var outerStart = (0, _moment.default)().day(day).hour(mOuterStart.hour()).minutes(mOuterStart.minutes());
      var outerEnd = (0, _moment.default)().day(day).hour(mOuterEnd.hour()).minutes(mOuterEnd.minutes());

      var startWithin = innerStart.isSame(outerStart) || innerStart.isAfter(outerStart) && innerStart.isBefore(outerEnd);
      var endWithin = innerEnd.isSame(outerEnd) || innerEnd.isBefore(outerEnd) && innerEnd.isAfter(outerStart);
      return startWithin && endWithin;
    },

    containsTime: function containsTime(dateArray, date) {
      if (dateArray.get('length') === 0) {
        return false;
      }

      for (var i = 0; i < dateArray.get('length'); i++) {
        if (this.isSameTime(dateArray.objectAt(i), date)) {
          return true;
        }
      }
      return false;
    }
  };
});