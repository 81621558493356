define('supr-web-ember-v2/controllers/authenticated/admin/reservations', ['exports', 'supr-web-ember-v2/utils/booking-helper'], function (exports, _bookingHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    flashService: service(),

    hasActiveBookings: computed('model.{reservations,reservations.@each}', function () {
      var hasActive = false;

      this.get('model').forEach(function (reservation) {
        if (reservation.get('hasActiveBookings')) {
          hasActive = true;
        }
      });
      return hasActive;
    }),

    actions: {
      cancelReservation: function cancelReservation(reservation) {
        if (this.get('hasActiveBookings')) {
          this.set('showBookingsModal', true);
          this.set('selectedReservation', reservation);
        } else {
          this.send('deleteReservation', reservation);
        }
      },

      keepBookings: function keepBookings(reservation) {
        this.set('showBookingsModal', false);
        reservation.set('_deletePendingBookings', false);
        this.send('deleteReservation', reservation);
      },

      deleteBookings: function deleteBookings(reservation) {
        this.set('showBookingsModal', false);
        reservation.set('_deletePendingBookings', true);
        this.send('deleteReservation', reservation);
      },

      deleteReservation: function deleteReservation(reservation) {
        var self = this;
        var options = {};
        if (reservation.get('_deletePendingBookings')) {
          options.pending = true;
        }
        this.set('selectedReservation', null);
        _bookingHelper.default.safeDestroyReservation(reservation, options).then(function () {
          self.get('flashService').success('Reservation cancelled');
        }).catch(function (err) {
          return self.get('flashService').error('Failed to delete reservation', err);
        });
      }
    }
  });
});