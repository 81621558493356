define('supr-web-ember-v2/initializers/my-crumbly', ['exports', 'supr-web-ember-v2/components/my-bread-crumbs'], function (exports, _myBreadCrumbs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.register('component:my-bread-crumbs', _myBreadCrumbs.default);
    application.inject('component:my-bread-crumbs', 'applicationRoute', 'route:application');
  }

  exports.default = {
    name: 'my-crumbly',
    initialize: initialize
  };
});