define('supr-web-ember-v2/components/profile-image', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var isEmpty = Ember.isEmpty;
  exports.default = Component.extend({
    classNames: ['profile-image'],
    imageURL: null,

    hasImage: computed('imageUrl', function () {
      if (isEmpty(this.get('imageURL'))) {
        return false;
      }
      return true;
    })

    // FIXME Due to this change we'll need to migrate all images to preserve avatars.
    //
    // preparedURL: computed('imageURL', function () {
    //   if (config.environment === 'development') {
    //     return this.get('imageURL').replace('https', 'http');
    //   } else {
    //     return this.get('imageURL');
    //   }
    // })
  });
});