define('supr-web-ember-v2/controllers/authenticated/admin/students', ['exports', 'supr-web-ember-v2/utils/archive-helper'], function (exports, _archiveHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var observer = Ember.observer;
  var filter = Ember.computed.filter;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    flashService: service(),
    showAll: false,
    showModal: false,

    activeStudents: filter('model.@each.isActive', function (student) {
      return student.get('isActive');
    }),

    archiveToggle: observer('showAll', function () {
      if (this.get('showAll')) {
        this.set('students', this.get('allStudents'));
      } else {
        this.set('students', this.get('activeStudents'));
      }
    }),

    actions: {
      archive: function archive(student) {
        this.set('showModal', true);
        this.set('selectedStudent', student);
      },

      toggleArchivedChanged: function toggleArchivedChanged() {
        this.toggleProperty('showAll');
        this.notifyPropertyChange('model.@each.isActive');
      },

      archiveStudent: function archiveStudent() {
        var _this = this;

        var student = this.get('selectedStudent');
        var self = this;
        _archiveHelper.default.archiveStudent(student).then(function () {
          self.get('flashService').success('Archived');
          self.transitionToRoute('authenticated.admin.students');
        }).catch(function (err) {
          self.get('flashService').error('Student archival failed.\n' + err);
        }).finally(function () {
          self.set('showModal', false);
          self.set('selectedStudent', null);
          // Trigger update of the filter
          if (!self.get('showAll')) {
            _this.set('students', _this.get('activeStudents'));
          }
        });
      },

      unArchiveStudent: function unArchiveStudent(student) {
        var self = this;

        _archiveHelper.default.unarchiveStudent(student).then(function () {
          self.get('flashService').success('Un-archived');
          self.transitionToRoute('authenticated.admin.students');
        }).catch(function (err) {
          self.get('flashService').error('Student unarchival failed.\n' + err);
        });
      }
    }
  });
});