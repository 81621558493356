define('supr-web-ember-v2/abilities/app', ['exports', 'ember-can'], function (exports, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = _emberCan.Ability.extend({
    currentUserService: service(),

    canAdminister: computed('currentUserService.user.isAdmin', function () {
      return this.get('currentUserService.user.isAdmin');
    }),
    canViewAvailabilities: computed('currentUserService.user.isGuide', function () {
      return this.get('currentUserService.user.isGuide');
    }),
    canViewStudents: computed('currentUserService.user.isGuide', function () {
      return this.get('currentUserService.user.isGuide');
    }),
    canAccess: computed('currentUserService.user.isActive', function () {
      return this.get('currentUserService.user.isActive');
    }),
    canSuper: computed('currentUserService.user.isSuperAdmin', function () {
      return this.get('currentUserService.user.isSuperAdmin');
    }),
    canGuard: computed('currentUserService.user.isGuardian', function () {
      return this.get('currentUserService.user.isGuardian');
    })
  });
});