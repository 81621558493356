define('supr-web-ember-v2/components/my-bread-crumb', ['exports', 'supr-web-ember-v2/templates/components/my-bread-crumb'], function (exports, _myBreadCrumb) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var oneWay = Ember.computed.oneWay;
  var bool = Ember.computed.bool;
  exports.default = Component.extend({
    layout: _myBreadCrumb.default,
    tagName: 'li',
    classNameBindings: ['crumbClass'],

    crumbClass: oneWay('breadCrumbs.crumbClass'),
    linkClass: oneWay('breadCrumbs.linkClass'),
    hasBlock: bool('template').readOnly()
  });
});