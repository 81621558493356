define('supr-web-ember-v2/routes/unauthenticated/signup', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend(_unauthenticatedRouteMixin.default, {
    session: service(),
    beforeModel: function beforeModel(transition) {
      // Hide signup button
      this.get('session').set('willSignUp', true);
      this._super(transition);
    },
    actions: {
      willTransition: function willTransition() {
        // Show signup button
        this.get('session').set('willSignUp', false);
      }
    }
  });
});