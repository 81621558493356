define('supr-web-ember-v2/controllers/authenticated/students/student/files', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    currentUserService: service(),
    flashService: service(),
    notifyService: service(),
    fileService: service(),
    selectedFile: null,
    isEditing: false,
    pick: false,

    actions: {
      edit: function edit() {
        this.set('isEditing', true);
      },
      finishEdit: function finishEdit() {
        this.set('isEditing', false);
      },
      // FIXME:
      // There are some issues here with realtime updates. If student and guide are
      // open in 2 different browsers the file does not disappear from the guide if
      // the student deletes the file. With file creation we're seeing updates
      // (although the image doesn't load without a page refresh).
      delete: function _delete(file) {
        var self = this;
        var student = this.get('student');
        this.model.removeObject(file);
        return this.get('fileService').delete(file).then(function () {
          self.set('isEditing', false);
          self.set('selectedFile', null);
          self.transitionToRoute('authenticated.students.student.files', student);
        });
      },
      download: function download(file) {
        this.get('fileService').download(file);
      },
      upload: function upload() {
        this.set('pick', true);
      },
      fileSelected: function fileSelected(file) {
        var student = this.get('student');
        var currentUser = this.get('currentUserService.user');
        var studentId = student.get('id');
        var uploaderID = currentUser.get('id');
        var self = this;

        return this.get('fileService').save(file, studentId, 'student', uploaderID).then(function () {
          // TODO:
          // Add the file URLs to the notification from fileRecords
          // coming in from the save call.
          //
          // Send notifications
          self.get('notifyService').sendNotification([{
            model: 'student',
            id: student.get('id')
          }], currentUser.get('fullName') + ' uploaded one or more files for you.', {
            route: 'authenticated.files'
          });
        }).catch(function (err) {
          self.get('flashService').error('Failed to upload file.\n' + err);
        });
      },
      onClose: function onClose() {
        this.set('pick', false);
      },
      onError: function onError(file, error) {
        this.get('flashService').error(error);
      }
    }
  });
});