define('supr-web-ember-v2/models/guide', ['exports', 'ember-data', 'supr-web-ember-v2/models/user', 'supr-web-ember-v2/mixins/user-roles', 'moment'], function (exports, _emberData, _user, _userRoles, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _user.default.extend(_userRoles.default, {
    startDate: _emberData.default.attr('date'),
    students: _emberData.default.hasMany('student', {
      async: true
    }),
    bookings: _emberData.default.hasMany('booking', {
      async: true
    }),
    reservations: _emberData.default.hasMany('reservation', {
      async: true
    }),
    learningSessions: _emberData.default.hasMany('learning-session', {
      async: true
    }),
    timeSlots: _emberData.default.hasMany('time-slot', {
      async: true
    }),
    notifications: _emberData.default.hasMany('notification', {
      async: true
    }),

    displayStartDate: computed('startDate', function () {
      return (0, _moment.default)(this.get('startDate')).format('ddd, MMM Do, h:mm a z');
    })
  });
});