define('supr-web-ember-v2/services/current-user-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var Service = Ember.Service;
  var service = Ember.inject.service;
  var isEmpty = Ember.isEmpty;
  var isPresent = Ember.isPresent;
  var reject = Ember.RSVP.reject;
  var all = Ember.RSVP.all;
  var resolve = Ember.RSVP.resolve;
  var debug = Ember.debug;
  exports.default = Service.extend({
    session: service(),
    store: service(),
    loading: false,

    load: function load() {
      var _this = this;

      // NOTE It's true we don't want to try and load the user a second time while
      // an existing load is in progress but we're not stopping the case where
      // application.js tries once, fails, and then authenticated.js tries once,
      // yielding two error messages. This is probably not a condition to be
      // worrying about at this point, the showing of 2 error messages.
      if (this.get('loading')) {
        console.log('User load in progress, skipping.');
        return resolve();
      } else {
        this.set('loading', true);
      }

      var self = this;
      var email = this.get('session.data.authenticated.email');
      var userLookup = null;

      // Skip load attempt if not authenticated
      // TODO: Should probably move this check into where it is called.
      if (!this.get('session.isAuthenticated')) {
        console.error('Tried to load current user for unauthenticated session');
        this.set('loading', false);
        return resolve();
      }

      // Skip load if it's already loaded
      //
      // We were triggering a current user load in authenticated.js afterModel AND
      // in application.js sessionAuthenticated as we need both to handle normal
      // login and page reloads. There is probably a way to move it all into one
      // place, the loading of the user, but this works for now.
      if (isPresent(self.get('user'))) {
        console.log('Attempted to load user more than once');
        this.set('loading', false);
        return resolve();
      }

      // User could be one of student, guide, or guardian so must try loading from
      // each collection
      if (!isEmpty(email)) {
        userLookup = all([this.get('store').query('student', {
          orderBy: 'email',
          equalTo: email
        }), this.get('store').query('guide', {
          orderBy: 'email',
          equalTo: email
        }), this.get('store').query('guardian', {
          orderBy: 'email',
          equalTo: email
        })]).then(function (result) {
          // This all seems rather weird but query returns an array of
          // InternalModels (objects), not Records. To get the records after they
          // have been fetched we have to peek for them in the store.
          // https://github.com/emberjs/ember.js/issues/15256 We could just fetcha
          // all of the users like we used to, which sucks, or try dealing with
          // this.

          var userId = null;
          var modelType = null;

          var _result = _slicedToArray(result, 3),
              student = _result[0],
              guide = _result[1],
              guardian = _result[2];

          if (!isEmpty(student)) {
            userId = student.content[0].id;
            modelType = 'student';
          }
          if (!isEmpty(guide)) {
            userId = guide.content[0].id;
            modelType = 'guide';
          }
          if (!isEmpty(guardian)) {
            userId = guardian.content[0].id;
            modelType = 'guardian';
          }
          // return err if id empty
          if (isEmpty(userId)) {
            self.set('loading', false);
            return reject('No user record found for ' + email);
          }

          return _this.get('store').peekRecord(modelType, userId);
        });
      } else {
        self.set('loading', false);
        return reject('authenticated.email is empty');
      }

      // Look up the user then load the user roles
      return userLookup.then(function (user) {
        if (isEmpty(user)) {
          return reject('Couldn\'t find user for email ' + email);
        }
        self.set('user', user);

        // Preload the roles
        return user.get('roles').then(function () {
          // FIXME: V1: This isn't working to get the users roles loaded by the
          // time the menu is being evaluated. Assuming it has to do with can
          // helpers in template not re-evaluating after the roles have been
          // loaded.
          return user.reload();
        });
      }).catch(function (err) {
        self.set('loading', false);
        debug("Session: Couldn't find user for email=" + email + ' ' + err);
        return reject(err);
      });
    }
  });
});