define('supr-web-ember-v2/mixins/file-handler', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var service = Ember.inject.service;
  exports.default = Mixin.create({
    fileService: service(),
    flashService: service(),

    actions: {
      pick: function pick(picker) {
        this.set(picker, true);
      },
      onClose: function onClose(picker) {
        this.set(picker, false);
      },

      download: function download(file) {
        this.get('fileService').download(file);
      },
      delete: function _delete(file) {
        return this.get('fileService').delete(file);
      },
      onError: function onError(error) {
        this.get('flashService').error(error.message);
      }
    }
  });
});