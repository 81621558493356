define("supr-web-ember-v2/models/reservation-over-booking-conflict", ["exports", "ember-data", "supr-web-ember-v2/models/schedule-conflict"], function (exports, _emberData, _scheduleConflict) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _scheduleConflict.default.extend({
    bookedOver: _emberData.default.belongsTo("booking", {
      async: true
    }),
    booker: _emberData.default.belongsTo("reservation", {
      async: true
    })
  });
});