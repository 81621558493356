define('supr-web-ember-v2/controllers/authenticated/admin/bookings/index', ['exports', 'supr-web-ember-v2/config/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var get = Ember.get;
  var set = Ember.set;
  var service = Ember.inject.service;
  var all = Ember.RSVP.all;
  exports.default = Controller.extend({
    currentUserService: service(),
    flashService: service(),

    actions: {
      displayDataChanged: function displayDataChanged(data) {
        var multipleSelection = data.selectedItems.length > 1;
        var pendingStatus = data.selectedItems.reduce(function (status, booking) {
          if (!status) {
            return false;
          }
          return booking.get('status') == 'pending';
        }, true);
        var cancellable = data.selectedItems.reduce(function (status, booking) {
          if (!status) {
            return false;
          }
          return booking.get('status') == 'confirmed' || booking.get('status') == 'pending';
        }, true);

        if (multipleSelection && pendingStatus) {
          set(this, 'showConfirmAll', true);
        } else {
          set(this, 'showConfirmAll', false);
        }

        if (multipleSelection && cancellable) {
          set(this, 'showCancelAll', true);
        } else {
          set(this, 'showCancelAll', false);
        }
      },
      confirmAll: function confirmAll() {
        var _this = this;

        var self = this;
        var bookingsToConfirm = get(this, 'selectedItems');
        return all(bookingsToConfirm.map(function (booking) {
          booking.set('status', _constants.default.booking.status.CONFIRMED);
          return booking.save();
        })).then(function () {
          get(self, 'flashService').success('Bookings confirmed');
          set(_this, 'showConfirmAll', false);
        }).catch(function (err) {
          get(self, 'flashService').error('Failed to confirm Bookings ' + { err: err });
        });
      },
      cancelAll: function cancelAll() {
        var self = this;

        set(this, 'showCancelAll', false);
        set(this, 'showConfirmAll', false);

        var bookingsToCancel = get(this, 'selectedItems');
        // TODO A bulk way of doing this would be preferable
        // Actually, without
        bookingsToCancel.forEach(function (booking) {
          self.send('cancel', booking);
        });
      }
    }
  });
});