define('supr-web-ember-v2/services/student-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var all = Ember.RSVP.all;
  var Service = Ember.Service;
  exports.default = Service.extend({
    store: service(),
    createLearningProfile: function createLearningProfile(fields, student) {
      var profile = this.get('store').createRecord('learning-profile', fields);
      student.set('learningProfile', profile);
      profile.set('student', student);
      return all([profile.save(), student.save()]);
    },
    createLearningObjective: function createLearningObjective(fields, student) {
      var objective = this.get('store').createRecord('learning-objective', fields);
      student.set('learningObjective', objective);
      objective.set('student', student);
      return all([objective.save(), student.save()]);
    },
    createTestStudent: function createTestStudent() {
      var unique = new Date().toISOString();
      var fields = {
        grade: 4,
        firstName: 'Kid',
        lastName: unique,
        email: 'kid.' + unique + '@example.com'
      };
      return this.get('store').createRecord('student', fields).save();
    }
  });
});