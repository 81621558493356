define('supr-web-ember-v2/models/attendee', ['exports', 'ember-data', 'supr-web-ember-v2/models/base'], function (exports, _emberData, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    attendeeID: _emberData.default.attr('sting'), // !!! POLYMORPHISM until we have polymorphism working and can just use 'user'
    learningSession: _emberData.default.belongsTo('learning-session', {
      async: true
    })
  });
});