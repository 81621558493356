define('supr-web-ember-v2/components/booking-entry', ['exports', 'supr-web-ember-v2/config/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = Component.extend({
    currentUserService: service(),

    classNames: ['booking-entry'],
    classNameBindings: ['isCancelled', 'isPending', 'isConfirmed', 'isAttended', 'isMissed', 'isMissedWith24Hrs', 'isMissedAcademy', 'isMissedGuide'],

    isCancelled: computed('booking.status', function () {
      return this.get('booking.status') == _constants.default.booking.status.CANCELLED;
    }),
    isPending: computed('booking.status', function () {
      return this.get('booking.status') == _constants.default.booking.status.PENDING;
    }),
    isConfirmed: computed('booking.status', function () {
      return this.get('booking.status') == _constants.default.booking.status.CONFIRMED;
    }),
    isAttended: computed('booking.status', function () {
      return this.get('booking.status') == _constants.default.booking.status.ATTENDED;
    }),
    isMissed: computed('booking.status', function () {
      return this.get('booking.status') == _constants.default.booking.status.MISSED;
    }),
    isMissedWith24Hrs: computed('booking.status', function () {
      return this.get('booking.status') == _constants.default.booking.status.MISSED_WITH_24HRS;
    }),
    isMissedAcademy: computed('booking.status', function () {
      return this.get('booking.status') == _constants.default.booking.status.MISSED_ACADEMY;
    }),
    isMissedGuide: computed('booking.status', function () {
      return this.get('booking.status') == _constants.default.booking.status.MISSED_GUIDE;
    }),

    actions: {
      confirm: function confirm(booking) {
        this.get('confirm')(booking);
      },
      cancel: function cancel(booking) {
        this.get('cancel')(booking);
      }
    }
  });
});