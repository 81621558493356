define('supr-web-ember-v2/controllers/authenticated/students/student/dashboard', ['exports', 'supr-web-ember-v2/utils/data-helper', 'moment'], function (exports, _dataHelper, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var alias = Ember.computed.alias;
  var filter = Ember.computed.filter;
  var filterBy = Ember.computed.filterBy;
  var sort = Ember.computed.sort;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    flashService: service(),
    fileService: service(),
    selectedTodo: null,
    priorities: null,
    selectedPriority: 'Medium', // Default
    numTodo: alias('todos.length'),
    showModal: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('priorities', ['High', 'Medium', 'Low']);
    },


    reset: function reset() {
      this.set('selectedPriority', 'Medium');
      this.set('selectedTodo', null);
    },

    preparedTodos: computed('numTodo', function () {
      return this.get('todos').map(function (todo) {
        todo.set('displayDate', (0, _moment.default)(todo.get('sortDate')).format('dddd, MMMM Do YYYY, h:mm a'));
        todo.set('icon', 'fa fa-circle-o fa-2x fa-fw'); // TODO: put this in config
        return todo;
      });
    }),

    _sort: ['sortDate'],
    incompleteTodos: filterBy('preparedTodos', 'status', 'Incomplete'),
    sortedIncompleteTodos: sort('incompleteTodos', '_sort'),

    completeTodos: filter('preparedTodos.@each.status', function (todo) {
      return todo.get('status') !== 'Incomplete';
    }),
    sortedCompleteTodos: sort('completeTodos', '_sort'),

    actions: {
      toggleShowInline: function toggleShowInline(todo) {
        todo.toggleProperty('showInline');
      },
      error: function error(msg) {
        this.get('flashService').error(msg);
      },
      update: function update() {
        this.set('showModal', true);
      },
      updateRoomURL: function updateRoomURL() {
        var roomModel = this.get('roomModel');
        this.set('showModal', false);
        var url = _dataHelper.default.addhttp(roomModel.get('url'));
        roomModel.set('url', url);
        roomModel.set('user.roomURL', url);
        roomModel.get('user').save();
      },
      cancelRoomURL: function cancelRoomURL() {
        var roomModel = this.get('roomModel');
        this.set('showModal', false);
        roomModel.set('url', this.get('model.roomURL'));
      },
      download: function download(file) {
        this.get('fileService').download(file);
      }
    }
  });
});