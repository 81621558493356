define('supr-web-ember-v2/controllers/unauthenticated/password-reset-finish', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    flashService: service(),
    firebaseService: service(),

    actions: {
      finisih: function finisih() {
        var self = this;

        var _getProperties = this.getProperties('code', 'newPassword'),
            code = _getProperties.code,
            newPassword = _getProperties.newPassword;

        return this.get('firebaseService').auth().confirmPasswordReset(code, newPassword).then(function () {
          self.get('flashService').success('Password reset!');
          self.transitionToRoute('login');
        }).catch(function (error) {
          return self.get('flashService').error(error.message);
        });
      }
    }
  });
});