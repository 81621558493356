define('supr-web-ember-v2/components/file-uploader', ['exports', 'firebase', 'uid-safe', 'supr-web-ember-v2/config/mime-types'], function (exports, _firebase, _uidSafe, _mimeTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    firebaseService: service(),
    flashService: service(),
    store: service(),

    allowedTypes: _mimeTypes.default.allowed.join(),

    showPicker: false,
    uploading: false,
    percentComplete: 0,
    onSelection: null,
    onError: null,
    onClose: null,

    getFileType: function getFileType(type) {
      var imageTypes = ['image/gif', 'image/jpg', 'image/jpeg', 'image/png', 'image/svg+xml', 'image/tiff', 'image/webp'];
      return imageTypes.includes(type) ? 'image' : 'text';
    },

    actions: {
      closePicker: function closePicker() {
        // Handle clicks off modal
        if (this.get('uploading')) {
          return;
        }
        this.set('showPicker', false);
        this.set('uploading', false);
        this.set('percentComplete', 0);
        this.get('onClose')();
      },

      // Array of Files passed in.
      didDropFiles: function didDropFiles(files) {
        // Temporary to only allow single upload
        if (files.length > 1) {
          return this.get('flashService').info('Only single file uploads are supported');
        }
        this.send('handleUpload', files[0]);
      },

      // FileList passed in.
      // Template is limiting selection to 1 presently.
      didSelectFiles: function didSelectFiles(files) {
        this.send('handleUpload', files.item(0));
      },

      handleError: function handleError(error) {
        this.get('onError')(error);
        this.send('closerPicker');
      },

      // Presently only a single File will ever be passed in.
      handleUpload: function handleUpload(file) {
        var self = this;

        var storageRef = this.get('firebaseService').storage().ref();

        var fileRecord = this.get('store').createRecord('file');
        fileRecord.set('name', file.name);
        fileRecord.set('fileType', this.get('getFileType')(file.type));

        // TODO:
        // Handle multiple file uploads. The use cases probably aren't that many
        // at the moment and it's a hassle as we need to track the number of
        // success and failure and report on that before passing file records up.

        var fileName = _uidSafe.default.sync(18) + ('-' + file.name);
        fileRecord.set('storagePath', fileName);
        var fileRef = storageRef.child(fileName);

        // Add metadata. Auto option is setting it as 'file' for an image.
        var metadata = {
          contentType: file.type
        };

        // Show uploading spinner
        this.set('uploading', true);

        // Upload file
        var uploadTask = fileRef.put(file, metadata);

        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on('state_changed', function (snapshot) {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;
          console.log('Upload is ' + progress + '% done');
          self.set('percentComplete', Math.floor(progress));
          switch (snapshot.state) {
            case _firebase.default.storage.TaskState.PAUSED:
              // or 'paused'
              console.log('Upload is paused');
              break;
            case _firebase.default.storage.TaskState.RUNNING:
              // or 'running'
              console.log('Upload is running');
              break;
          }
        }, function (err) {
          // Handle unsuccessful uploads
          if (self.get('onError')) {
            self.send('handleError', err);
          }
        }, function () {
          // Handle successful uploads on complete
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            console.log('File available at', downloadURL);
            fileRecord.set('url', downloadURL);
            // TODO:
            // I feel like we should handle checking for the necessary actions
            // elsewhere, like on init.
            if (self.get('onSelection')) {
              self.get('onSelection')(fileRecord);
            }
            self.set('uploading', false);
            self.send('closePicker');
          });
        });
      }
    }
  });
});