define('supr-web-ember-v2/routes/authenticated', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  var all = Ember.RSVP.all;
  var later = Ember.run.later;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    currentUserService: service(),
    academyService: service(),
    flashService: service(),

    breadCrumb: null,

    // On a page reload when already authenticated, sessionAuthenticated in
    // application.js is not triggered so we need to load the current user here as
    // well. current-user-service handles case where user is already loaded on a
    // normal login.
    afterModel: function afterModel(model, transition) {
      return all([this._loadCurrentUser(transition), this.get('academyService').load()]).catch(function (err) {
        return console.error(err);
      });
    },
    _loadCurrentUser: function _loadCurrentUser(transition) {
      var _this = this;

      return this.get('currentUserService').load().catch(function (err) {
        _this.get('flashService').error(err);
        transition.abort();

        // Delay added so that error messages are visible before the app resets
        // due to the invalidation
        later(_this, function () {
          this.get('session').invalidate();
        }, 2000);
      });
    },


    setupController: function setupController(controller) {
      controller.set('errorMessage', null);
      // We add a fields hash to the controller so that for any template where we
      // are only creating one record we can do the following
      // var guest = this.store.createRecord('guest', this.get('fields'));
      // This also empties the hash any time we switch routes
      controller.set('fields', {});
    }
  });
});