define('supr-web-ember-v2/components/mt-expand-all-toggle', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  exports.default = Component.extend({
    classNames: ['mt-expand-all-toggle'],

    actions: {
      collapseAllRows: function collapseAllRows() {
        get(this, 'collapseAllRows')();
      },
      expandAllRows: function expandAllRows() {
        get(this, 'expandAllRows')();
      }
    }
  });
});