define('supr-web-ember-v2/components/availability-slot', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: 'li',
    classNames: ['availability-slot', 'list-group-item'],
    slotValueClasses: computed('slot.{available,reserved,booked,current,selected}', function () {
      var classes = 'slot-value';
      if (this.get('slot.available')) classes = classes + ' available';
      if (this.get('slot.reserved')) classes = classes + ' reserved';
      if (this.get('slot.booked')) classes = classes + ' booked';
      if (this.get('slot.current')) classes = classes + ' current';
      if (this.get('slot.selected')) classes = classes + ' selected';
      return classes;
    }),
    slotIndicatorClasses: computed('slot.{available,reserved,booked,current,selected}', function () {
      var classes = 'slot-indicator';
      if (this.get('slot.available')) classes = classes + ' available';
      if (this.get('slot.reserved')) classes = classes + ' reserved';
      if (this.get('slot.booked')) classes = classes + ' booked';
      if (this.get('slot.current')) classes = classes + ' current';
      if (this.get('slot.selected')) classes = classes + ' selected';
      return classes;
    }),
    click: function click() {
      this.toggle();
    }
  });
});