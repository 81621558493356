define('supr-web-ember-v2/models/address', ['exports', 'ember-data', 'supr-web-ember-v2/models/base', 'moment'], function (exports, _emberData, _base, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _base.default.extend({
    name: _emberData.default.attr('string'),
    street: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    postCode: _emberData.default.attr('string'),
    province: _emberData.default.attr('string'),
    country: _emberData.default.attr('string', {
      defaultValue: 'Canada'
    }),
    mapUrl: _emberData.default.attr('string'),
    note: _emberData.default.attr('string'),
    // don't have a belongsTo user. if we need it then we've probably got the !!! POLYMORPH issue
    displayCreatedAtDate: computed('createdAt', function () {
      return (0, _moment.default)(this.get('createdAt')).format('ddd, MMM Do, h:mm a z');
    })
  });
});