define('supr-web-ember-v2/routes/authenticated/students/student/dashboard', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  var isPresent = Ember.isPresent;
  var EmberObject = Ember.Object;
  var all = Ember.RSVP.all;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    flashService: service(),
    can: service(),

    beforeModel: function beforeModel(transition) {
      if (this.get('can').cannot('viewStudents in app')) {
        this.get('flashService').error("You don't have the necessary permissions to access " + transition.targetName);
        this.transitionTo('authenticated.unauthorized');
      }
    },
    model: function model() {
      return this.modelFor('authenticated.students.student');
    },
    afterModel: function afterModel(model) {
      var _this = this;

      var self = this;
      var promises = [];
      return model.get('todos') // preload
      .then(function (theTodos) {
        // FIXME:
        // This is related to Issue #11
        // https://bitbucket.org/supracademy/supracademy-app-client/issues/11/unable-to-add-todo-to-user-without-setting
        theTodos.forEach(function (todo) {
          if (isPresent(todo.get('assignedById'))) {
            promises.push(todo.set('assignedBy', self.store.findRecord('guide', todo.get('assignedById'))));
          }
        });

        promises.push(model.get('files'));
        return all(promises);
      }).catch(function (err) {
        return _this.get('flashService').error(err.message);
      });
    },
    setupController: function setupController(controller, model) {
      var roomModel = EmberObject.create({
        url: model.get('roomURL'),
        user: model
      });
      controller.set('roomModel', roomModel);

      controller.set('model', model);
      controller.set('todos', model.get('todos'));
      // FIXME:
      // We need to think more about what to do if records are missing.
      // We have a catch on the initial model.get('file') but we ask for
      // it again here and get an uncaught error in the console.
      controller.set('files', model.get('files').slice(0, 5));
    }
  });
});