define('supr-web-ember-v2/routes/authenticated/admin/bookings/index', ['exports', 'supr-web-ember-v2/utils/booking-helper', 'supr-web-ember-v2/config/constants', 'moment'], function (exports, _bookingHelper, _constants, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  var get = Ember.get;
  var set = Ember.set;
  var all = Ember.RSVP.all;
  exports.default = Route.extend({
    flashService: service(),
    currentUserService: service(),
    notifyService: service(),

    // Bookings can be made manually and are also created automatically when a
    // registration is created or renewed.
    model: function model() {
      return this.store.findAll('booking');
    },

    // Preload the conflicts
    afterModel: function afterModel(model) {
      var promises = [];
      model.forEach(function (booking) {
        promises.pushObject(booking.get('bookerBookingConflicts'));
        promises.pushObject(booking.get('bookedOverBookingConflicts'));
        promises.pushObject(booking.get('bookerReservationConflicts'));
        promises.pushObject(booking.get('bookedOverReservationConflicts'));
      });
      return all(promises);
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      set(controller, 'columns', [{ propertyName: 'id', isHidden: true }, {
        title: 'Student',
        propertyName: 'student.fullName'
      }, {
        title: 'Guide',
        propertyName: 'guide.fullName'
      }, {
        title: 'Start Time',
        propertyName: 'startTime',
        component: 'mt-booking-start-time',
        sortFunction: function isBefore(date1, date2) {
          if ((0, _moment.default)(date1).isBefore((0, _moment.default)(date2))) {
            return -1;
          } else {
            return 1;
          }
        }
      }, { propertyName: 'duration', isHidden: true }, {
        title: 'Status',
        propertyName: 'status',
        component: 'mt-booking-status',
        filterFunction: function filterFunction(val, filterVal, row) {
          var _val = get(row, 'status');

          if (filterVal === 'Active') {
            return _val === 'pending' || _val === 'confirmed';
          }
          if (filterVal === 'Pending') {
            return _val === 'pending';
          }
          if (filterVal === 'Confirmed') {
            return _val === 'confirmed';
          }
          if (filterVal === 'Cancelled') {
            return _val === 'cancelled';
          }
          if (filterVal === 'Attended') {
            return _val === 'attended';
          }
          if (filterVal === 'Missed') {
            return _val === 'missed';
          }
          if (filterVal === 'Missed With 24 hrs') {
            return _val === 'missed-with-24hrs';
          }
          if (filterVal === 'Missed Academy') {
            return _val === 'missed-academy';
          }
          if (filterVal === 'Missed Guide') {
            return _val === 'missed-guide';
          }
        },

        filterWithSelect: true,
        predefinedFilterOptions: ['Active', 'Pending', 'Confirmed', 'Cancelled', 'Attended', 'Missed', 'Missed With 24 hrs', 'Missed Academy', 'Missed Guide']
      }, {
        title: 'Actions',
        component: 'mt-booking-actions'
      }]);
      // FIXME We can't use the select all toggle  until we have an alternate
      // toggleAllSelection that only toggles according to filteredContent.
      get(controller, 'columns').unshiftObject({
        component: 'mt-select-row-checkbox',
        useFilter: false,
        mayBeHidden: false
        // componentForSortCell: 'mt-select-all-rows-checkbox',
      });
      set(controller, 'selectedItems', []);
      set(controller, 'expandedItems', []);
      get(controller, 'columns').unshiftObject({
        component: 'mt-expand-toggle',
        componentForFilterCell: 'mt-expand-all-toggle',
        mayBeHidden: false
      });
    },


    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('showConfirmAll', false);
        controller.set('showCancelAll', false);
      }
    },

    actions: {
      didTransition: function didTransition() {
        set(this, 'controller.selectRowCheckboxTemplate', '<span class="{{if isSelected themeInstance.select-row themeInstance.deselect-row}}" onclick={{action "clickOnRow" index record}}></span>');
        set(this, 'controller.selectAllRowsCheckboxTemplate', '<span {{action "toggleAllSelection"}} class="{{if (is-equal selectedItems.length data.length) themeInstance.select-all-rows themeInstance.deselect-all-rows}}"></span>');
        return this._super.apply(this, arguments);
      },


      cancel: function cancel(booking) {
        var self = this;
        var student = void 0;
        booking.set('status', 'cancelled');
        booking.set('cancelledOn', new Date());
        booking.set('cancelledBy', self.get('session.currentUser.userType') + ' ' + self.get('session.currentUser.fullName'));
        booking.save().then(function () {
          return _bookingHelper.default.destroyConflicts(booking.get('conflicts'));
        }).then(function () {
          return _bookingHelper.default.safeDestroyLearningSession(booking);
        }).then(function () {
          return booking.get('student');
        }).then(function (theStudent) {
          student = theStudent;
          return booking.get('guide');
        }).then(function (theGuide) {
          var recipients = [{ model: 'student', id: student.get('id') }, { model: 'guide', id: theGuide.get('id') }];
          var guardians = student.get('guardians');
          guardians.forEach(function (guardian) {
            recipients.addObject({ model: 'guardian', id: guardian.get('id') });
          });
          self.get('notifyService').sendNotification(recipients, 'The booking for ' + student.get('fullName') + ' with ' + theGuide.get('fullName') + ' on ' + booking.get('displayDate') + ' has been cancelled.', { route: 'authenticated.bookings' });
          self.get('flashService').success('Booking cancelled');
        });
      },

      confirmBooking: function confirmBooking(booking) {
        var self = this;
        booking.set('status', 'confirmed');
        booking.save().then(function () {
          self.get('flashService').success('Booking confirmed.');
        }).catch(function (err) {
          self.get('flashService').error('Failed to confirm Booking.' + err);
        });
      },

      acceptConflict: function acceptConflict(conflict) {
        var self = this;
        conflict.set('status', _constants.default.scheduleConflict.status.RESOLVED);
        conflict.save().then(function () {
          self.get('flashService').success('Conflict resolved');
        });
      }
    }
  });
});