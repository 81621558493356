define('supr-web-ember-v2/utils/bugsnag', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = bugsnag;
  exports.getUser = getUser;
  var getProperties = Ember.getProperties;
  var isEmpty = Ember.isEmpty;
  function bugsnag() {
    return true;
  }

  function getUser(owner) {
    var currentUser = owner.lookup('service:current-user-service').get('user');

    if (isEmpty(currentUser)) return {};

    var _getProperties = getProperties(currentUser, 'email', 'id', 'fullName'),
        email = _getProperties.email,
        id = _getProperties.id,
        name = _getProperties.fullName;

    return {
      email: email,
      id: id,
      name: name
    };
  }
});