define('supr-web-ember-v2/models/academy-schedule', ['exports', 'ember-data', 'supr-web-ember-v2/models/base'], function (exports, _emberData, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    academy: _emberData.default.belongsTo('academy', {
      async: true
    }),
    bookingFirstTime: _emberData.default.attr('date'), // first time a reservation can be made. only hours and minutes will be used.
    bookingLastTime: _emberData.default.attr('date'), // last time a reservation can be made. only hours and minutes will be used.
    bookingDuration: _emberData.default.attr('number'), // reservation duration in minutes
    bookingGap: _emberData.default.attr('number'), // required gap in minutes between reservations
    bookingIncrement: _emberData.default.attr('number'), // step in minutes at which to offer reservations, e.g. every half hours
    semesterStart: _emberData.default.attr('date'), // when the semester starts
    semesterEnd: _emberData.default.attr('date') // when semester ends. New registrations use this date to set the number of pending bookings
  });
});