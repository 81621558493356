define('supr-web-ember-v2/instance-initializers/emt-inject', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(appInstance) {
    appInstance.inject('component:models-table', 'themeInstance', 'theme:ember-bootstrap-v3');
    appInstance.inject('component:models-table-server-paginated', 'themeInstance', 'theme:bootstrap-v3');
  }

  exports.default = {
    name: 'emt-inject',
    initialize: initialize
  };
});