define('supr-web-ember-v2/routes/authenticated/students', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    flashService: service(),
    can: service(),

    beforeModel: function beforeModel(transition) {
      if (this.get('can').cannot('viewStudents in app')) {
        this.get('flashService').error("You don't have the necessary permissions to access " + transition.targetName);
        this.transitionTo('authenticated.unauthorized');
      }

      if (this.get('can').cannot('access in app')) {
        this.get('flashService').error('Your account has been archived.\nPlease contact an administrator for support.');
        this.transitionTo('authenticated.unauthorized');
      }
    },
    model: function model() {
      var _this = this;

      return this.store.findAll('student').catch(function (err) {
        return _this.get('flashService').error(err.message);
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('students', model.filterBy('isActive', true));
    }
  });
});