define('supr-web-ember-v2/services/registration-service', ['exports', 'supr-web-ember-v2/utils/booking-helper', 'supr-web-ember-v2/utils/promise-helper', 'moment', 'lodash', 'supr-web-ember-v2/config/constants', 'moment-recur'], function (exports, _bookingHelper, _promiseHelper, _moment, _lodash, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var allSettled = Ember.RSVP.allSettled;
  var all = Ember.RSVP.all;
  var reject = Ember.RSVP.reject;
  var resolve = Ember.RSVP.resolve;
  var debug = Ember.debug;
  var isEmpty = Ember.isEmpty;
  var isPresent = Ember.isPresent;
  var Service = Ember.Service;
  exports.default = Service.extend({
    flashService: service(),
    fileService: service(),
    academyService: service(),
    store: service(),

    createRegistration: function createRegistration(plan, startDate, notes, agreement, student, guide, guardians) {
      var registration = this.get('store').createRecord('registration', {
        plan: plan,
        notes: notes,
        agreement: agreement
      });
      registration.set('registrationDate', new Date());
      registration.set('startDate', startDate);

      // The renwal date will be null if no plan has been selected.
      var renewalDate = this._getRenewalDate(plan, registration.get('startDate'));
      registration.set('renewalDate', renewalDate);

      registration.set('student', student);
      registration.get('guardians').addObjects(guardians);

      student.set('registration', registration);
      student.set('primaryGuide', guide);

      guardians = guardians.map(function (guardian) {
        guardian.get('students').addObject(student);
        guardian.get('registrations').addObject(registration);
        return guardian;
      });

      return allSettled([student.save(), guide.save(), all(guardians.map(function (guardian) {
        return guardian.save();
      })), registration.save()]).then(function (results) {
        _promiseHelper.default.logSettledErrors(results);
        // Returns resolve or reject as necessary
        return _promiseHelper.default.combineSettledPromise(results);
      }).catch(function (err) {
        // Only called if allSettled fails, e.g. pass wrong argument type
        console.error(err);
        debug(err);
        // TODO: Roll back or delete records and return
        return reject('Could not create new registration record.');
      });
    },
    updateRegistration: function updateRegistration(registration, agreementFileToDelete, student, guide, guardians) {
      // The renwal date will be null if no plan has been selected.
      var renewalDate = this._getRenewalDate(registration.get('plan'), registration.get('startDate'));
      registration.set('renewalDate', renewalDate);

      // TODO: After a registration update the registrations list needs to be
      //       reloaded as it may be showing out of date data, e.g. guardians
      student.set('primaryGuide', guide);
      guide.get('students').addObject(student);

      var guardiansToRemove = registration.get('guardians').filter(function (guardian) {
        return !guardians.includes(guardian);
      });

      guardiansToRemove.forEach(function (guardian) {
        registration.get('guardians').removeObject(guardian);
        student.get('guardians').removeObject(guardian);
        guardian.get('students').removeObject(student);
        guardian.get('registrations').removeObject(registration);
      });

      guardians.forEach(function (guardian) {
        registration.get('guardians').addObject(guardian);
        student.get('guardians').addObject(guardian);
        guardian.get('students').addObject(student);
        guardian.get('registrations').addObject(registration);
      });

      var agreePromise = agreementFileToDelete ? this.get('fileService').delete(agreementFileToDelete) : resolve();

      return allSettled([agreePromise, student.save(), guide.save(), all(guardians.map(function (guardian) {
        return guardian.save();
      })), all(guardiansToRemove.map(function (guardian) {
        return guardian.save();
      })), registration.save()]).then(function (results) {
        _promiseHelper.default.logSettledErrors(results);
        // Returns resolve or reject as necessary
        return _promiseHelper.default.combineSettledPromise(results);
      }).catch(function (err) {
        // Only called if allSettled fails, e.g. pass wrong argument type
        console.error(err);
        debug(err);
        // TODO: Roll back or delete records and return
        return reject('Could not update registration record.');
      });
    },
    createSchedule: function createSchedule(registration, student, guide, guardians, newReservations, academy) {
      var self = this;
      return student.get('bookings').then(function (existingBookings) {
        var reservations = resolve();
        var bookings = resolve();
        var guides = resolve();
        var learningSessions = resolve();
        var bookingConflictPromises = resolve();
        var reservationConflictPromises = resolve();
        if (!isEmpty(newReservations)) {
          reservations = newReservations.map(function (pendingReservation) {
            return self.get('store').createRecord('reservation', {
              createdAt: new Date(),
              startTime: pendingReservation.get('startTime'),
              duration: pendingReservation.get('duration'),
              guide: pendingReservation.get('guide'),
              student: student
            });
          });

          bookings = self._createBookings(registration, reservations, student, academy, existingBookings);
          guides = reservations.map(function (reservation) {
            return reservation.get('guide').then(function (guide) {
              return guide.save();
            });
          });

          learningSessions = self._createLearningSessions(bookings).map(function (learningSession) {
            return learningSession.save();
          });

          bookingConflictPromises = self._getBookingConflicts(bookings);
          reservationConflictPromises = self._getReservationConflicts(bookings);

          bookings = bookings.map(function (booking) {
            return booking.save();
          });
          reservations = reservations.map(function (reservation) {
            return reservation.save();
          });
        }

        return allSettled([bookings, reservations, student.save(), guide.save(), guides, guardians.map(function (guardian) {
          return guardian.save();
        }), learningSessions, bookingConflictPromises, reservationConflictPromises]);
      }).then(function (results) {
        _promiseHelper.default.logSettledErrors(results);
        // Returns resolve or reject as necessary
        return _promiseHelper.default.combineSettledPromise(results);
      }).catch(function (err) {
        console.error(err);
        debug(err);
        // TODO: Roll back or delete records and return If there is bad data,
        //       references to records that don't exist, things start to fall
        //       apart. Therefore, whenever there are relationships that are set
        //       up in a function they should be undone if creation of any of
        //       the records in the relationship fails.
        return reject('Could not create new registration record.');
      });
    },
    updateSchedule: function updateSchedule(registration, student, guide, guardians, existingReservations, deletedReservations, newReservations, academy) {
      var self = this;

      return student.get('bookings').then(function (existingBookings) {
        // When we change the plan we'll have to add or remove (pending)
        // Bookings if the plan gets longer or shorter.
        //
        // If the switch was to no plan then all reservations have been removed.
        //
        // If the switch was to a plan of a different length we could have the
        // same reservation, the old reservation removed and a new one added, or
        // the same old reservation as well as new ones.
        //
        // Adding or removing existing reservations is already handled.
        //
        // If the plan changes we need to first check if there are any extra or
        // too few Bookings for existing reservations BEFORE. If any booking is
        // AFTER the renewal date it should be removed. There are too few if the
        // renwal date minus the period doesn't have enough for 1 per week.
        //
        // We want to keep all confirmed Bookings. We can show a modal when
        // switching plan lengths to indicate if there are existing Bookings
        // that will remain that are outside of the plan frequency, e.g. month
        // plan to week plan.
        //
        // NOTE When renewal date hits it has to NOT create duplicate Bookings.

        // Check the existing Reservations have the correct number of Pending
        // or Confirmed Bookings. Bookings with other statuses outside of the
        // plan renewal range will be deleted.
        var updatedReservations = existingReservations.map(function (reservation) {
          // The Bookings were preloaded
          var bookingsToDelete = self._bookingsOutsidePlan(registration, reservation);

          var deletedBookings = bookingsToDelete.then(function (theBookings) {
            return theBookings.map(function (booking) {
              return _bookingHelper.default.safeDestroyBooking(booking);
            });
          });

          var bookingsToAdd = self._bookingsMissingForPlan(registration, reservation, student, academy, reservation.get('bookings'));

          return all[(deletedBookings, bookingsToAdd)];
        });

        // Remove deleted Reservations
        var destroyedReservationPromises = isEmpty(deletedReservations) ? Promise.resolve() : deletedReservations.map(function (reservation) {
          var options = {};
          if (reservation.get('_deletePendingBookings')) {
            options.pending = true;
          }
          if (reservation.get('_deleteExistingBookings')) {
            options.confirmed = true;
          }
          return _bookingHelper.default.safeDestroyReservation(reservation, options);
        });

        var reservations = resolve();
        var bookings = resolve();
        var guides = resolve();
        var learningSessions = resolve();
        var bookingConflictPromises = resolve();
        var reservationConflictPromises = resolve();

        // Create new reservations and Bookings
        if (!isEmpty(newReservations)) {
          reservations = newReservations.map(function (pendingReservation) {
            return self.get('store').createRecord('reservation', {
              createdAt: new Date(),
              startTime: pendingReservation.get('startTime'),
              duration: pendingReservation.get('duration'),
              guide: pendingReservation.get('guide'),
              student: student
            });
          });

          bookings = self._createBookings(registration, reservations, student, academy, existingBookings);
          guides = reservations.map(function (reservation) {
            return reservation.get('guide').then(function (guide) {
              return guide.save();
            });
          });

          // TODO Why isn't this an automatic part of Booking handling? We may
          // have been forgetting to deal with Learning Sessions when dealing with
          // Bookings.
          learningSessions = self._createLearningSessions(bookings).map(function (learningSession) {
            return learningSession.save();
          });

          bookingConflictPromises = self._getBookingConflicts(bookings);

          reservationConflictPromises = self._getReservationConflicts(bookings);

          reservations = reservations.map(function (reservation) {
            return reservation.save();
          });
          bookings = bookings.map(function (booking) {
            return booking.save();
          });
        }

        return allSettled([updatedReservations, destroyedReservationPromises, bookings, reservations, student.save(), guide.save(), guides, guardians.map(function (guardian) {
          return guardian.save();
        }), learningSessions, bookingConflictPromises, reservationConflictPromises]).then(function (results) {
          _promiseHelper.default.logSettledErrors(results);
          // Returns resolve or reject as necessary
          return _promiseHelper.default.combineSettledPromise(results);
        }).catch(function (err) {
          console.error(err);
          debug(err);
          // TODO: Roll back or delete records and return If there is bad data,
          //       references to records that don't exist, things start to fall
          //       apart. Therefore, whenever there are relationships that are set
          //       up in a function they should be undone if creation of any of
          //       the records in the relationship fails.
          return reject('Could not update registration record.');
        });
      });
    },
    _createBookings: function _createBookings(registration, reservations, student, academy, existingBookings) {
      var self = this;
      var bookings = reservations.map(function (reservation) {
        // A reservation is for a day and time. To get a series we need to know
        // the next date corresponding to the day of the reservation (after the
        // plan start date) and that date + the duration of a registration plan,
        // which will either be a weekly, bi-weekly, monthly, semestered, or
        // yearly. TODO We'll need to allow these frequencies to be set by an
        // admin.
        //
        // If the start date is today the recurenses will start on the next
        // instance of this day of the week.
        //
        // recur discards time information, which includes timezone
        // watch out for daylight savings! had a reservation that was created
        // when the offset was different and then it threw off creation of
        // new times.
        //
        // A recurring cloud function should check the 'renewalDate' of an
        // active registration and create the next set of Bookings, notifying
        // those involved.
        //
        // If the schedule semesterEnd is already passed or not set then create
        // 1 weeks worth and throw up a warning message.
        // Otherwise, create up until the plan renewal date
        var plan = registration.get('plan');
        var startDate = (0, _moment.default)(registration.get('startDate'));
        var renewalDate = registration.get('renewalDate') ? (0, _moment.default)(registration.get('renewalDate')) : null;
        var semesterEnd = academy.get('schedule.semesterEnd');
        var semesterFinished = (0, _moment.default)(semesterEnd).isBefore((0, _moment.default)());
        var renewalAfterSemester = renewalDate ? renewalDate.isAfter((0, _moment.default)(semesterEnd)) : false;

        // moment-recur doesn't maintain the time of the date across DST. To
        // account for this we copy the time and then set it on each new moment in
        // the recurrence.
        var recurrence = void 0;
        var hour = (0, _moment.default)(reservation.get('startTime')).hour();
        var minutes = (0, _moment.default)(reservation.get('startTime')).minutes();
        var nextBookings = [];

        if (isEmpty(semesterEnd) || semesterFinished) {
          var msg = 'Your Academy Schedule semester end date, ' + (0, _moment.default)(semesterEnd).format('dddd, MMMM Do YYYY') + ', has passed. Please update it via the Admin Academy screen.';
          self.get('flashService').info(msg);
        }

        if (renewalAfterSemester) {
          var _msg = 'The registration renewal date is after your Academy Schedule semester end date, ' + (0, _moment.default)(semesterEnd).format('dddd, MMMM Do YYYY') + '. Bookings have been created up until the rewnwal date.';
          self.get('flashService').info(_msg);
        }

        if (plan == _constants.default.plan.SEMESTERED && semesterFinished) {
          recurrence = (0, _moment.default)(startDate).recur().every([reservation.get('day')]).daysOfWeek();
          nextBookings = recurrence.next(1);
          var _msg2 = 'This registration is on a semestered plan and the semester end date has passed. 1 week of Bookings has been created instead of a full semesters worth.';
          self.get('flashService').info(_msg2);
        } else {
          recurrence = (0, _moment.default)().recur(startDate, renewalDate).every([reservation.get('day')]).daysOfWeek();
          nextBookings = recurrence.all();
          // If the the start day and reservation day are the same we would end
          // up with an extra Booking. For example, a weekly plan would end up
          // with 2 Bookings instead of 1.
          if (startDate.format('dddd') == reservation.get('day')) {
            nextBookings = _lodash.default.dropRight(nextBookings);
          }
        }

        // Set the proper time as moment-recur doesn't preserve it across DST
        nextBookings = nextBookings.map(function (bookingMoment) {
          // Set the proper time as moment-recur doesn't preserve it across DST
          var reservationMoment = (0, _moment.default)(reservation.get('startTime'));
          bookingMoment.hour(reservationMoment.hour());
          bookingMoment.minutes(reservationMoment.minutes());
          bookingMoment.seconds(0);

          // Converting from Moment to Date causes the time to be adjusted
          // according to DST, which we don't want. If the Booking time is 10
          // then each Booking time in the future should also be 10
          var startTime = bookingMoment.toDate();
          startTime.setHours(hour);
          startTime.setMinutes(minutes);

          return startTime;
        });

        // Check for existing Bookings at the scheduled times. There could already
        // be Bookings if a reservation was added to a Registration, deleted with
        // the option to keep the Bookings selected, and then the same Reservation
        // time added back.
        //
        // One question is whether or not to associate existing Bookings with a
        // new Reservation or not. It may be confusing that Bookings are not
        // offered up for deletion when removing a Reservation that would have
        // created those same Bookings. Still, if the Bookings can be kept from a
        // Reservation then it makes sense to keep them separate.
        if (isPresent(existingBookings)) {
          nextBookings = nextBookings.filter(function (startTime) {
            var add = true;
            existingBookings.forEach(function (existing) {
              if ((0, _moment.default)(existing.get('startTime')).isSame((0, _moment.default)(startTime))) {
                add = false;
              }
            });
            return add;
          });
        }

        // Create the Bookings
        var bookings = nextBookings.map(function (startTime) {
          // - Booking unsaved
          // - Reservation guide unsaved
          // - Reservation unsaved
          return self.get('store').createRecord('booking', {
            status: _constants.default.booking.status.PENDING,
            student: student,
            guide: reservation.get('guide'),
            startTime: startTime,
            duration: reservation.get('duration'),
            location: academy.get('address'),
            reservation: reservation
          });
        });
        // NOTE: This is essentially hidden, that the student, reservation and
        //        reservation guide need to get saved for the following to work.
        //        The student and reservation were saved in create/update schedule
        //        based on other operations but the reservation guide had to be
        //        added explicitly. Maybe trying to create pure functions just
        //        doesn't really work with Ember as data is mutable by default.
        student.get('bookings').pushObjects(bookings);
        reservation.get('bookings').pushObjects(bookings);
        reservation.get('guide.bookings').pushObjects(bookings);

        return bookings;
      });
      return _lodash.default.flatten(bookings);
    },
    _createLearningSessions: function _createLearningSessions(bookings) {
      var self = this;
      return bookings.map(function (booking) {
        var learningSession = self.get('store').createRecord('learning-session');
        booking.set('learningSession', learningSession);
        learningSession.get('bookings').pushObject(booking);
        return learningSession;
      });
    },
    _getBookingConflicts: function _getBookingConflicts(bookings) {
      var self = this;
      return bookings.map(function (booking) {
        // NOTE: Having BookingHelper.getBookingConflicts return a promise doesn't
        //        fit with the notion of creating records and then saving /
        //        cleanup all in one batch. Would be better if we always pass
        //        resolved collections into the helpers.
        return _bookingHelper.default.getBookingConflicts(booking, self.get('store').findAll('booking')).then(function (conflicts) {
          var conflictPromises = conflicts.map(function (conflict) {
            debug('---- creating schedule conflicts');
            return self.get('store').createRecord('booking-over-booking-conflict', {
              booker: booking,
              bookedOver: conflict
            }).save();
          });
          return all(conflictPromises);
        });
      });
    },
    _getReservationConflicts: function _getReservationConflicts(bookings) {
      var self = this;
      return bookings.map(function (booking) {
        return _bookingHelper.default.getReservationConflict(booking, self.get('store').findAll('reservation')).then(function (conflicts) {
          // FIXME: V1:
          // Should only be one conflict. Should error in helper if more than one.
          var conflictPromises = conflicts.map(function (conflict) {
            // FIXME: V1:
            // WTF!! why does this fail when creating
            // booking-over-booking-conflict is fine?
            return self.get('store').createRecord('booking-over-reservation-conflict', {
              booker: booking,
              bookedOver: conflict
            }).save();
          });
          return all(conflictPromises);
        });
      });
    },
    _getRenewalDate: function _getRenewalDate(plan, startDate) {
      if (!startDate) {
        return null;
      }

      var renewal = (0, _moment.default)(startDate);
      switch (plan) {
        case _constants.default.plan.NONE:
          return null;
        case _constants.default.plan.WEEKLY:
          renewal.add(1, 'W');
          break;
        case _constants.default.plan.BIWEEKLY:
          renewal.add(2, 'W');
          break;
        case _constants.default.plan.MONTHLY:
          renewal.add(1, 'M');
          break;
        case _constants.default.plan.SEMESTERED:
          renewal = (0, _moment.default)(this.get('academyService.academy.schedule.semesterEnd'));
          break;
        case _constants.default.plan.YEARLY:
          renewal.add(1, 'Y');
          break;
      }

      return renewal.toDate();
    },
    // TODO
    // Think about what happens if the cron job hasn't run yet for the day AND
    // this is called. Cron jobs should have timezone set to be the same as the
    // academy. Still doesn't take care of what happens if an admin is out of
    // country and tries this. Suppose you could do something like run a Cron job
    // once a day/more to make sure schedules are up to date. If a Booking in the
    // future is missing or exists and shouldn't it will get taken care of the
    // next day.
    //
    // Returns the Bookings that are beyond the given plans renewal date and
    // before the start date.
    _bookingsOutsidePlan: function _bookingsOutsidePlan(registration, reservation) {
      if (!registration.get('renewalDate')) {
        return resolve([]);
      }
      var startDate = (0, _moment.default)(registration.get('startDate'));
      var renewalDate = (0, _moment.default)(registration.get('renewalDate'));
      var bookings = reservation.get('bookings');
      return bookings.then(function (theBookings) {
        return theBookings.filter(function (booking) {
          if ((0, _moment.default)(booking.get('startTime')).isSameOrAfter(renewalDate) || (0, _moment.default)(booking.get('startTime')).isBefore(startDate)) {
            return true;
          } else {
            return false;
          }
        });
      });
    },
    _bookingsMissingForPlan: function _bookingsMissingForPlan(registration, reservation, student, academy, existingBookings) {
      var self = this;

      if (!registration.get('renewalDate')) {
        return resolve([]);
      }

      var reservations = [reservation];
      var newBookings = this._createBookings(registration, reservations, student, academy, existingBookings);
      var guides = reservations.map(function (reservation) {
        return reservation.get('guide').then(function (guide) {
          return guide.save();
        });
      });

      // TODO Why isn't this an automatic part of Booking handling? We may
      // have been forgetting to deal with Learning Sessions when dealing with
      // Bookings.
      var learningSessions = self._createLearningSessions(newBookings).map(function (learningSession) {
        return learningSession.save();
      });

      var bookingConflictPromises = self._getBookingConflicts(newBookings);

      var reservationConflictPromises = self._getReservationConflicts(newBookings);

      reservations = reservations.map(function (reservation) {
        return reservation.save();
      });
      newBookings = newBookings.map(function (booking) {
        return booking.save();
      });

      return all([guides, learningSessions, bookingConflictPromises, reservationConflictPromises, reservations, newBookings]);
    }
  });
});