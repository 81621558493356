define('supr-web-ember-v2/components/mt-select-row-checkbox', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  exports.default = Component.extend({
    classNames: ['mt-select-row-checkbox'],

    actions: {
      clickOnRow: function clickOnRow(index, record, event) {
        get(this, 'clickOnRow')(index, record);
        event.stopPropagation();
      }
    }
  });
});