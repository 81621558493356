define('supr-web-ember-v2/routes/authenticated/admin', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  var all = Ember.RSVP.all;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    flashService: service(),
    can: service(),

    beforeModel: function beforeModel(transition) {
      if (this.get('can').cannot('administer in app')) {
        this.get('flashService').error("You don't have the necessary permissions to access " + transition.targetName);
        this.transitionTo('authenticated.unauthorized');
      }

      if (this.get('can').cannot('access in app')) {
        this.get('flashService').error('Your account has been archived.\nPlease contact an administrator for support.');
        this.transitionTo('authenticated.unauthorized');
      }
    },

    // Preload data that will be required in store across admin pages.
    // Make it a one time hit instead of needing to use store.findAll(model_type, {reload: true}),
    // which would reload all the models in quesiton.
    model: function model() {
      var _this = this;

      return all([this.store.findAll('student', { reload: true }), this.store.findAll('guide', { reload: true }), this.store.findAll('guardian', { reload: true }), this.store.findAll('booking', { reload: true }), this.store.findAll('reservation', { reload: true }), this.store.findAll('registration', { reload: true }), this.store.findAll('time-slot', { reload: true }), this.store.findAll('role', { reload: true })]).catch(function (err) {
        console.log(err);
        _this.get('flashService').error(err.message);
      });
    }
  });
});