define('supr-web-ember-v2/routes/authenticated/admin/bookings/edit', ['exports', 'supr-web-ember-v2/config/constants', 'lodash'], function (exports, _constants, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var all = Ember.RSVP.all;
  exports.default = Route.extend({
    model: function model(params) {
      return this.store.findRecord('booking', params.booking_id);
    },

    afterModel: function afterModel(model) {
      var self = this;
      var promises = [];

      // NOTE: For some reason it seems like we're having to use thing.get('content')
      //       in some cases even though they should all be resolved promises from
      //       preloading them here. Figure out what's happening. Ember Data issue?
      //       Emberfire issue?
      promises.push(model.get('student'));
      promises.push(model.get('guide'));

      promises.push(model.get('learningSession'));
      promises.push(model.get('reservation'));
      promises.push(model.get('location'));

      // Preload the conflicts
      promises.push(model.get('bookerBookingConflicts'));
      promises.push(model.get('bookedOverBookingConflicts'));
      promises.push(model.get('bookerReservationConflicts'));
      promises.push(model.get('bookedOverReservationConflicts'));

      // Preload some other data we need
      promises.push(self.store.findAll('time-slot').then(function (timeSlots) {
        self.set('_timeSlots', timeSlots);
      }));
      promises.push(self.store.findAll('guide').then(function (guides) {
        self.set('_guides', guides);
      }));
      promises.push(self.store.findAll('student'));

      return all(promises);
    },

    setupController: function setupController(controller, model) {
      controller.reset();
      controller.set('model', model);
      controller.set('timeSlots', this.get('_timeSlots'));
      controller.set('bookings', this.modelFor('authenticated.admin.bookings'));
      controller.set('guides', this.get('_guides').filterBy('isActive', true));
      controller.set('selectedGuide', model.get('guide'));
      controller.set('selectedStatus', model.get('status'));
      // controller.set('guide', model.get('guide')); // picked up as selection
      controller.set('student', model.get('student'));
      var statusTypes = _lodash.default.values(_constants.default.booking.status);
      _lodash.default.pull(statusTypes, 'cancelled'); // not allowed to cancel from edit screen
      controller.set('bookingStatusTypes', statusTypes);
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.reset();
      }
    }
  });
});