define('supr-web-ember-v2/routes/authenticated/admin/create-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  var debug = Ember.debug;
  exports.default = Route.extend({
    flashService: service(),
    userService: service(),
    studentService: service(),

    setupController: function setupController(controller) {
      controller.set('autoLogin', false);
      controller.set('fields', {}); // we can DRY this up by extending an Authenticated Route
      controller.set('addressFields', {});
    },

    actions: {
      createUser: function createUser() {
        var controller = this.controller;
        var self = this;
        var userType = controller.get('userType');

        this.send('showLoadingSpinner');

        return this.get('userService').createUser(userType, controller.get('fields'), controller.get('addressFields')).then(function (newUser) {
          // NOTE: We can't use `finally` after the catch here because userService isn't returning an
          //       Ember Promise, due to the promise generated by Firebase
          self.send('hideLoadingSpinner');

          self.get('flashService').success('New account created for ' + controller.get('fields.email'));
          self.transitionTo('authenticated.admin.' + userType.toLowerCase() + 's.' + userType.toLowerCase(), newUser);
        }).catch(function (err) {
          console.error(err);
          self.send('hideLoadingSpinner');

          // Do nothing for password and email errors
          if (err === 'INVALID_PASSWORD' || err === 'INVALID_EMAIL') {
            debug(err);
            return;
          } else {
            var msg = 'Create user record failed for ' + userType + ' record';
            debug(msg);
            self.get('flashService').error(msg);
          }
        });
      },

      createTestStudent: function createTestStudent() {
        var _this = this;

        this.get('studentService').createTestStudent().then(function () {
          return _this.get('flashService').success('Student created');
        }).catch(function (err) {
          return _this.get('flashService').error(err);
        });
      }
    }
  });
});