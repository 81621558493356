define('supr-web-ember-v2/mixins/progress', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var service = Ember.inject.service;
  var get = Ember.get;
  exports.default = Mixin.create({
    pageProgress: service(),

    actions: {
      loading: function loading(transition) {
        var pageProgress = get(this, 'pageProgress');

        pageProgress.start(transition.targetName);

        transition.finally(function () {
          pageProgress.done();
        });

        return true;
      }
    }
  });
});