define('supr-web-ember-v2/models/student', ['exports', 'ember-data', 'supr-web-ember-v2/models/user', 'supr-web-ember-v2/mixins/user-roles'], function (exports, _emberData, _user, _userRoles) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _user.default.extend(_userRoles.default, {
    mature: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    grade: _emberData.default.attr('number'),
    roomURL: _emberData.default.attr('string'),
    guardians: _emberData.default.hasMany('guardian', {
      async: true
    }),
    primaryGuide: _emberData.default.belongsTo('guide', {
      async: true
    }),
    school: _emberData.default.belongsTo('school', {
      async: true
    }),
    // This is giving us an error suggesting we need to sort out explicit inverses
    // http://emberjs.com/guides/models/defining-models/#toc_explicit-inverses
    // This will probably go away if we sort out the polymorphism
    learningSessions: _emberData.default.hasMany('learning-session', {
      async: true
    }),
    bookings: _emberData.default.hasMany('booking', {
      async: true
    }),
    reservations: _emberData.default.hasMany('reservation', {
      async: true
    }),
    learningProfile: _emberData.default.belongsTo('learning-profile', {
      async: true
    }),
    learningObjective: _emberData.default.belongsTo('learning-objective', {
      async: true
    }),
    registration: _emberData.default.belongsTo('registration', {
      async: true
    }),
    notifications: _emberData.default.hasMany('notification', {
      async: true
    })
  });
});