define('supr-web-ember-v2/components/mt-expand-toggle', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  exports.default = Component.extend({
    classNames: ['mt-expand-toggle'],

    actions: {
      collapseRow: function collapseRow(index, record) {
        get(this, 'collapseRow')(index, record);
      },
      expandRow: function expandRow(index, record) {
        get(this, 'expandRow')(index, record);
      }
    }
  });
});