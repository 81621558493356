define('supr-web-ember-v2/routes/authenticated/super/files', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  var all = Ember.RSVP.all;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    flashService: service(),
    can: service(),
    statisticsService: service(),

    beforeModel: function beforeModel(transition) {
      if (this.get('can').cannot('super in app')) {
        this.get('flashService').error("You don't have the necessary permissions to access " + transition.targetName);
        this.transitionTo('authenticated.unauthorized');
      }

      if (this.get('can').cannot('access in app')) {
        this.get('flashService').error('Your account has been archived. Please contact an administrator for support.');
        this.transitionTo('authenticated.unauthorized');
      }
    },

    // Load the entire db
    model: function model() {
      return all([this.store.findAll('student'), this.store.findAll('guide'), this.store.findAll('guardian'), this.store.findAll('file')]).catch(function (err) {
        return console.error(err);
      });
    },

    setupController: function setupController(controller, model) {
      controller.set('students', model[0]);
      controller.set('guides', model[1]);
      controller.set('guardians', model[2]);
      controller.set('files', model[3]);
    }
  });
});