define('supr-web-ember-v2/config/constants', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    plan: {
      NONE: 'none',
      WEEKLY: 'weekly',
      BIWEEKLY: 'bi-weekly',
      MONTHLY: 'monthly',
      SEMESTERED: 'semestered',
      YEARLY: 'yearly'
    },
    booking: {
      status: {
        PENDING: 'pending',
        CONFIRMED: 'confirmed',
        ATTENDED: 'attended',
        MISSED: 'missed',
        MISSED_WITH_24HRS: 'missed-with-24hrs',
        MISSED_ACADEMY: 'missed-academy',
        MISSED_GUIDE: 'missed-guide',
        CANCELLED: 'cancelled'
      },
      reason: {
        MISSED: 'Missed',
        MISSED_WITH_24HRS: 'Missed with 24hrs notice',
        MISSED_ACADEMY: 'Missed due to academy',
        MISSED_GUIDE: 'Missed due to guide',
        CANCELLED: 'Cancelled'
      }
    },
    scheduable: {
      type: {
        RESERVATION: 'reservation',
        BOOKING: 'booking'
      }
    },
    scheduleConflict: {
      status: {
        RESOLVED: 'resolved',
        UNRESOLVED: 'unresolved'
      }
    },
    todo: {
      status: {
        INCOMPLETE: 'Incomplete',
        COMPLETE: 'Complete',
        FAILED: 'Failed'
      },
      priority: {
        LOW: 'Low',
        MEDIUM: 'Medium',
        HIGHT: 'High'
      }
    },
    role: {
      ADMIN: 'Admin',
      GUIDE_MENTOR: 'Guide Mentor',
      SUPER: 'Super'
    }
  };
});