define('supr-web-ember-v2/components/booking-week-list', ['exports', 'moment', 'moment-range'], function (exports, _moment, _momentRange) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;


  var moment = (0, _momentRange.extendMoment)(_moment.default);

  exports.default = Component.extend({
    classNames: ['booking-week-list'],

    name: computed('month', function () {
      // If the month is within the current year use the full month name
      // If the month is earlier then use the short month name plus the year
      var month = this.get('month.month') < 10 ? '0' + this.get('month.month') : this.get('month.month');
      var date = moment(this.get('month.year') + '-' + month);
      var now = moment();

      if (date.isSame(now, 'year')) {
        return date.format('MMMM');
      } else {
        return date.format('MMM YYYY');
      }
    }),
    weeks: computed('month', function () {
      // TODO move the weeks calculating function into a function

      var year = this.get('month.year');
      var month = this.get('month.month') < 10 ? '0' + this.get('month.month') : this.get('month.month');
      var startDate = moment(year + '-' + month);

      // Get the first and last day of the month
      var firstDay = moment(startDate).startOf('month');
      var endDay = moment(startDate).endOf('month');

      // Create a range for the month we can iterate through
      var monthRange = moment.range(firstDay, endDay);

      // Get all the weeks during the current month
      var weeks = {};

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = monthRange.by('days')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var m = _step.value;

          if (!weeks[m.week()]) {
            // Make adjustments so we can show the date range for the week
            var weekStart = moment(m).startOf('week');
            if (weekStart.isBefore(firstDay)) {
              weekStart = moment(firstDay);
            }
            var weekEnd = moment(m).endOf('week');
            if (weekEnd.isAfter(endDay)) {
              weekEnd = moment(endDay);
            }

            var isThisWeek = false;
            var today = moment();
            if (today.isSameOrAfter(weekStart, 'day') && today.isSameOrBefore(weekEnd, 'day')) {
              isThisWeek = true;
            }

            weeks[m.week()] = {
              month: month,
              week: m.week(),
              date: m,
              firstDay: weekStart.format('D'),
              lastDay: weekEnd.format('D'),
              isThisWeek: isThisWeek
            };
          }
        }

        // Put each Booking into the correct week
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = this.get('month.bookings')[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var b = _step2.value;

          var week = moment(b.get('sortDate')).week();
          if (weeks[week].bookings) {
            weeks[week].bookings.push(b);
          } else {
            weeks[week].bookings = [b];
          }
        }

        // NOTE working with week of the year means that the last week of Dec
        // could fall into January, making it week 1 and throwing the weeks out
        // of order for Dec.
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      var sortedWeeks = Object.values(weeks).sort(function (a, b) {
        return parseInt(a.firstDay) < parseInt(b.firstDay) ? -1 : 1;
      });

      return sortedWeeks;
    }),

    actions: {
      confirm: function confirm(booking) {
        this.get('confirm')(booking);
      },
      cancel: function cancel(booking) {
        this.get('cancel')(booking);
      }
    }
  });
});