define('supr-web-ember-v2/models/learning-profile', ['exports', 'supr-web-ember-v2/utils/data-helper', 'ember-data', 'supr-web-ember-v2/models/base', 'moment'], function (exports, _dataHelper, _emberData, _base, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var isPresent = Ember.isPresent;
  exports.default = _base.default.extend({
    // IB, MASTERS, AP, Other
    specialProgram: _emberData.default.attr('string'),
    // Do you take notes in class?
    noteTaking: _emberData.default.attr('string'),
    // Do you get handouts, worksheets? Does teacher expect you to take notes?
    classroomWorkFormats: _emberData.default.attr('string'),
    // Textbook available?
    // [if yes, to above question -- drop down menu with "Nelson", "Ryerson" or "Other" (specify)
    hasTextBookAvailable: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    textBook: _emberData.default.attr('string'),
    // How often do you get homework, projects, assignments for class?
    classWorkFrequency: _emberData.default.attr('string'),
    // Homework provided from textbook? Worksheets?
    homeworkFromTextbook: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    homeworkFromWorksheets: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    // How often do you utilize technology in class?
    classroomTechnologyUse: _emberData.default.attr('string'),
    hasBlog: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    // URL
    classBlog: _emberData.default.attr('string'),
    // Course material online?
    hasOnlineCourseMaterial: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    // URL
    courseMaterial: _emberData.default.attr('string'),

    student: _emberData.default.belongsTo('student', {
      async: true
    }),
    courseSyllabus: _emberData.default.belongsTo('file', {
      async: true
    }),
    courseSchedule: _emberData.default.belongsTo('file', {
      async: true
    }),

    displayCreatedAtDate: computed('createdAt', function () {
      return (0, _moment.default)(this.get('createdAt')).format('ddd, MMM Do, h:mm a z');
    }),

    save: function save(options) {
      if (isPresent(this.get('classBlog'))) {
        this.set('classBlog', _dataHelper.default.addhttp(this.get('classBlog')));
      }
      if (isPresent(this.get('courseMaterial'))) {
        this.set('courseMaterial', _dataHelper.default.addhttp(this.get('courseMaterial')));
      }
      return this._super(options);
    }
  });
});