define('supr-web-ember-v2/services/grid-service', ['exports', 'supr-web-ember-v2/utils/time-helper', 'supr-web-ember-v2/utils/booking-helper', 'supr-web-ember-v2/utils/grid-slot', 'moment'], function (exports, _timeHelper, _bookingHelper, _gridSlot, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var isPresent = Ember.isPresent;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    academyService: service(),

    init: function init() {
      this._super.apply(this, arguments);
    },


    // Grid Slot available if it falls within the time of an existing Guide Time Slot
    gridSlotAvailable: function gridSlotAvailable(time, timeSlots) {
      for (var i = 0; i < timeSlots.length; i++) {
        var checkTime = timeSlots[i].get('startTime'); // this seems unecessary but was getting undefined from timeSlots[i].get('startTime')
        var slotDuration = timeSlots[i].get('duration');

        if (_timeHelper.default.isSameTime(time, checkTime) || _timeHelper.default.isBetweenTimes(time, checkTime, slotDuration)) {
          return true;
        }
      }
      return false;
    },

    // Passed a day and startTime of numeric format.
    createGridSlots: function createGridSlots(day, rowTime, bookings, timeSlots, selectedWeekStart, selectedGuide, currentBooking) {
      var gridSlots = [];
      var increment = this.get('academyService.academy.schedule.bookingIncrement');
      var startTimesPerHour = 60 / increment;

      // For each day check if the grid slot is available and if it is booked
      var slotHour = rowTime;
      var slotMinutes = 0;

      for (var i = 0; i < startTimesPerHour; i++) {
        var newTime = (0, _moment.default)(selectedWeekStart).day(day).hour(slotHour).minute(slotMinutes).toDate();
        var slot = _gridSlot.default.create({
          startTime: newTime,
          duration: increment
        });

        // Check if slot available
        if (this.gridSlotAvailable(slot.startTime, timeSlots)) {
          slot.set('available', true);
        }

        // Check if slot is part of Booking being edited.
        // First we check if they are they same week of the year, then same day and within range
        if (isPresent(currentBooking)) {
          if (currentBooking.get('guide.id') === selectedGuide.get('id') && (0, _moment.default)(currentBooking.get('startTime')).isSame(selectedWeekStart, 'week') && currentBooking.get('day') === day && _timeHelper.default.rangeIsWithinRange(slot.get('startTime'), slot.get('endTime'), currentBooking.get('startTime'), currentBooking.get('endTime'))) {
            slot.set('current', true);
          }
        }

        // Check if slot has a booking
        var slotBookings = _bookingHelper.default.slotBookings(selectedWeekStart, slot.get('startTime'), bookings);
        if (isPresent(slotBookings)) {
          slot.set('bookings', slotBookings);
          slot.set('booked', true);
        }

        // Check if slot has a reservation
        var reservation = _bookingHelper.default.getSlotReservation(slot, selectedGuide.get('reservations'));
        if (isPresent(reservation)) {
          slot.set('reserved', true);
          slot.set('reservation', reservation);
        }

        // // Check if slot has been selected in a previous viewing of this week.
        // // If it was selected then the new slot being created here should be
        // // selected and the old removed from the tracking array selectedSlots
        // // FIXME:
        // // This is a problem. A side effect of setting something that should just be on the controller.
        // // Think the fix is to make the check AFTER creating
        // // the grid slots. Same for the adding to allSlots
        // if (!isEmpty(self.get('selectedSlots'))) {
        //   self.set(
        //     'selectedSlots',
        //     this.updateSlotSelections(slot, self.get('selectedSlots'))
        //   );
        // }

        gridSlots.addObject(slot);

        // self.get('allSlots').addObject(slot);

        slotHour = (0, _moment.default)(newTime).add(increment, 'minutes').hour();
        slotMinutes = (0, _moment.default)(newTime).add(increment, 'minutes').minute();
      }
      return gridSlots;
    },

    // Check if slot has been selected in a previous viewing of this week.
    // If it was selected then the new slot being created here should be
    // selected and the old removed from the tracking array selectedSlots
    updateSlotSelections: function updateSlotSelections(newSlot, selectedSlots) {
      var oldSelected = [];
      var newSelected = [];
      var newSelectedSlots = [];
      selectedSlots.forEach(function (selectedSlot) {
        if ((0, _moment.default)(selectedSlot.get('startTime')).isSame((0, _moment.default)(newSlot.get('startTime')), 'minute')) {
          newSlot.set('selected', true);
          oldSelected.addObject(selectedSlot);
          newSelected.addObject(newSlot);
        }
      });
      newSelectedSlots.addObjects(selectedSlots);
      newSelectedSlots.removeObjects(oldSelected);
      newSelectedSlots.addObjects(newSelected);
      return newSelectedSlots;
    }
  });
});