define('supr-web-ember-v2/controllers/authenticated/admin/registrations/edit', ['exports', 'supr-web-ember-v2/utils/time-helper', 'moment', 'supr-web-ember-v2/mixins/file-handler', 'supr-web-ember-v2/config/constants'], function (exports, _timeHelper, _moment, _fileHandler, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var A = Ember.A;
  var EmberObject = Ember.Object;
  var computed = Ember.computed;
  var observer = Ember.observer;
  var union = Ember.computed.union;
  var isEmpty = Ember.isEmpty;
  var isPresent = Ember.isPresent;
  var service = Ember.inject.service;
  exports.default = Controller.extend(_fileHandler.default, {
    academyService: service(),
    flashService: service(),

    selectedDay: null,
    selectedTime: null,
    newReservations: A(),
    combinedReservations: union('allReservations', 'newReservations'),
    deletedReservations: A(),
    selectedGuardian: null,
    planChanged: false,
    showReservationCancelModal: false,

    planObserver: observer('model.plan', function () {
      var plan = this.get('model.plan');
      // planChanged is required to prevent the modal from opening on first load
      if (this.get('planChanged') && plan != this.get('existingPlan') && plan == _constants.default.plan.NONE) {
        this.set('model.startDate', null);
        this.set('model.renewalDate', null);
        this.set('newReservations', A()); // FIXME Do we still need this?
        this.set('showPlanChangeModal', true);
      }
      this.set('planChanged', true);
    }),

    hasActiveBookings: computed('existingReservations', 'existingReservations.@each', function () {
      var hasActive = false;
      this.get('existingReservations').forEach(function (reservation) {
        if (reservation.get('hasActiveBookings')) {
          hasActive = true;
        }
      });
      return hasActive;
    }),

    availableGuardians: computed('guardians', 'selectedGuardians', 'selectedGuardians.@each', function () {
      // Differs from new version of function because may already have some
      // Guardians associated with the registration.
      var selectedGuardians = this.get('selectedGuardians');
      var unavailableIds = selectedGuardians.map(function (guardian) {
        return guardian.id;
      });
      // FIXME: V1:
      // Something strange in that this.get('guardians') is returning only the
      // selected one here...
      return this.get('guardians').filter(function (guardian) {
        return !unavailableIds.includes(guardian.id);
      });
    }),

    noGuardianSelection: computed('selectedGuardian', function () {
      return isEmpty(this.get('selectedGuardian'));
    }),

    // Initially available reservation slots are the availabilityTimeSlots where
    // the guide doesn't have any reservations. Once a reservation is pending it
    // shouldn't show up in this list.
    availableReservationSlots: computed('availabilityTimeSlots', 'availabilityTimeSlots.@each', 'newReservations', 'newReservations.@each', function () {
      var availabilities = this.get('availabilityTimeSlots');
      var bookingDuration = this.get('academyService.academy.schedule.bookingDuration');
      var bookingIncrement = this.get('academyService.academy.schedule.bookingIncrement');
      var reservations = this.get('combinedReservations');
      var availableSlots = [];

      var ReservationSlot = EmberObject.extend({
        startTime: null,
        duration: bookingDuration,

        day: computed('startTime', function () {
          // Monday, Tuesday, etc.
          return _moment.default.weekdays()[(0, _moment.default)(this.get('startTime')).day()];
        }),

        displayTime: computed('startTime', function () {
          return (0, _moment.default)(this.get('startTime')).format('h:mm a');
        })
      });

      var isReserved = function isReserved(guide, startTime) {
        var existing = reservations.filterBy('guide.id', guide.get('id')).filter(function (reservation) {
          // Check if the startTime is within a reservation or if the
          // startTime + duration is within a reservation
          return (0, _moment.default)(reservation.get('startTime')).day() === (0, _moment.default)(startTime).day() && (_timeHelper.default.isSameTime(reservation.get('startTime'), startTime) || _timeHelper.default.isBetweenTimes(startTime, reservation.get('startTime'), reservation.get('duration')) || _timeHelper.default.isBetweenTimes((0, _moment.default)(startTime).add(bookingDuration, 'm'), reservation.get('startTime'), reservation.get('duration')));
        });
        if (isPresent(existing)) {
          return true;
        } else {
          return false;
        }
      };

      for (var i = 0; i < availabilities.get('length'); i++) {
        var availability = availabilities.objectAt(i);
        var numSlotsCovered = Math.floor(availability.get('duration') / bookingDuration);
        var numIncrementsCovered = availability.get('duration') / bookingIncrement;
        var startTime = (0, _moment.default)(availability.get('startTime'));
        var reservation = null;
        if (numSlotsCovered > 0) {
          for (var j = 0; j < numIncrementsCovered; j++) {
            // This should prevent showing a slot time as an option if you can't
            // make a Booking with it, i.e. if there isn't enough time in the
            // availability to add the booking duration to the start time.
            if (startTime.clone().add(bookingDuration, 'minutes').isBefore((0, _moment.default)(availability.get('endTime')))) {
              if (!isReserved(availability.get('guide'), startTime.toDate())) {
                reservation = ReservationSlot.create({
                  startTime: startTime.clone().toDate(),
                  duration: bookingDuration,
                  guide: availability.get('guide')
                });
                availableSlots.addObject(reservation);
              }
            }
            startTime.add(bookingIncrement, 'minutes');
          }
          // Because you can make a reservation starting anywhere in the
          // time-slot if the duration doesn't exceed the end of the time-slot
          // we check a final slot whose start time is the the time-slot end
          // minus the booking duration
          startTime = (0, _moment.default)(availability.get('endTime')).subtract(bookingDuration, 'minutes');
          if (!isReserved(availability.get('guide'), startTime.toDate())) {
            reservation = ReservationSlot.create({
              startTime: startTime.clone().toDate(),
              duration: bookingDuration,
              guide: availability.get('guide')
            });
            availableSlots.addObject(reservation);
          }
        }
      }

      return availableSlots;
    }),

    openReservationDays: computed('availableReservationSlots', 'availableReservationSlots.@each', function () {
      var slots = this.get('availableReservationSlots');
      var days = [];

      for (var i = 0; i < slots.get('length'); i++) {
        var date = slots.objectAt(i).get('startTime');
        days.addObject((0, _moment.default)(date).format('dddd'));
      }

      return days;
    }),

    daysAvailable: computed('openReservationDays', 'openReservationDays.@each', function () {
      return this.get('openReservationDays.length') > 0;
    }),

    openReservationTimes: computed('availableReservationSlots', 'availableReservationSlots.@each', 'selectedDay', function () {
      var slots = this.get('availableReservationSlots');
      var times = [];

      for (var i = 0; i < slots.get('length'); i++) {
        var date = slots.objectAt(i).get('startTime');
        if ((0, _moment.default)(date).format('dddd') === this.get('selectedDay')) {
          times.addObject((0, _moment.default)(date).format('h:mm a'));
        }
      }
      return times;
    }),

    timesAvailable: computed('openReservationTimes', function () {
      return this.get('openReservationTimes.length') > 0;
    }),

    openReservationGuides: computed('availableReservationSlots', 'availableReservationSlots.@each', 'selectedDay', 'selectedTime', function () {
      var slots = this.get('availableReservationSlots');
      var guides = [];

      for (var i = 0; i < slots.get('length'); i++) {
        var slot = slots.objectAt(i);
        var date = (0, _moment.default)(slot.get('startTime'));
        if (date.format('dddd') === this.get('selectedDay') && date.format('h:mm a') === this.get('selectedTime')) {
          guides.addObject(slot.get('guide'));
        }
      }
      return guides;
    }),

    guidesAvailable: computed('openReservationGuides', function () {
      return this.get('openReservationGuides.length') > 0;
    }),

    reset: function reset() {
      this.set('selectedDay', null);
      this.set('selectedTime', null);
      this.set('newReservations', A());
      this.set('deletedReservations', A());
      this.set('selectedGuardian', null);
      this.set('selectedPrimaryGuide', null);
      this.set('showPlanChangeModal', false);
      this.set('showReservactionCancelModal', false);
    },

    actions: {
      submit: function submit() {
        this.send('save');
      },

      agreementSelected: function agreementSelected(file) {
        // Sends to Route
        this.get('target').send('agreementSelected', file);
      },

      removeAgreement: function removeAgreement(file) {
        // Sends to Route
        this.get('target').send('removeAgreement', file);
      },

      addGuardian: function addGuardian(selectedGuardian) {
        this.get('selectedGuardians').addObject(selectedGuardian);
        this.set('selectedGuardian', null);
      },

      removeGuardian: function removeGuardian(guardian) {
        this.get('selectedGuardians').removeObject(guardian);
      },

      selectReservationDay: function selectReservationDay(day) {
        this.set('selectedDay', day);
      },

      selectReservationTime: function selectReservationTime(time) {
        this.set('selectedTime', time);
      },

      selectReservationGuide: function selectReservationGuide(selectedGuide) {
        var self = this;

        // Converting the temporary ReservationSlot objects to reservation records
        // will happen when the registration is saved
        this.get('availableReservationSlots').forEach(function (reservation) {
          var date = (0, _moment.default)(reservation.get('startTime'));
          var day = _moment.default.weekdays()[date.day()];
          var time = date.format('h:mm a');
          var guide = reservation.get('guide');

          if (day === self.get('selectedDay') && time === self.get('selectedTime') && guide.get('id') === selectedGuide.get('id')) {
            self.get('newReservations').addObject(reservation);
            self.set('selectedDay', null);
            self.set('selectedTime', null);
          }
        });
      },

      removeReservation: function removeReservation(reservedSlot) {
        this.get('newReservations').removeObject(reservedSlot);
        // reset so slot appears available again
        this.set('selectedDay', null);
        this.set('selectedTime', null);
      },

      deleteReservation: function deleteReservation(reservation) {
        this.get('existingReservations').removeObject(reservation);
        this.get('deletedReservations').addObject(reservation);
      },

      cancelReservation: function cancelReservation(reservation) {
        if (this.get('hasActiveBookings')) {
          this.set('showReservationCancelModal', true);
          this.set('selectedReservation', reservation);
        } else {
          this.send('deleteReservation', reservation);
        }
      },

      keepBookings: function keepBookings(reservation) {
        this.set('selectedReservation', null);
        this.set('showReservationCancelModal', false);
        reservation.set('_deletePendingBookings', false);
        this.send('deleteReservation', reservation);
        this.get('flashService').info('Reservation has been marked for deletion');
      },

      deleteBookings: function deleteBookings(reservation) {
        this.set('selectedReservation', null);
        this.set('showReservationCancelModal', false);
        reservation.set('_deletePendingBookings', true);
        this.send('deleteReservation', reservation);
        this.get('flashService').info('Reservation has been marked for deletion');
      },

      deleteReservationsAndKeepBookings: function deleteReservationsAndKeepBookings() {
        var self = this;
        this.set('showPlanChangeModal', false);
        var reservations = this.get('existingReservations');
        reservations.forEach(function (reservation) {
          reservation.set('_deletePendingBookings', false);
          self.send('deleteReservation', reservation);
        });
        this.get('flashService').info('Reservations have been marked for deletion');
      },

      deleteReservationsAndBookings: function deleteReservationsAndBookings() {
        var self = this;
        this.set('showPlanChangeModal', false);
        var reservations = this.get('existingReservations');
        reservations.forEach(function (reservation) {
          reservation.set('_deletePendingBookings', true);
          self.send('deleteReservation', reservation);
        });
        this.get('flashService').info('Reservations have been marked for deletion');
      }
    }
  });
});