define('supr-web-ember-v2/routes/authenticated/admin/academy/edit', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model(params) {
      return this.store.findRecord('academy', params.academy_id);
    },

    setupController: function setupController(controller, model) {
      // V2: TODO: This should all be in a component, an x-select that takes a date and
      //           increment and sets a selection of hours based on that with the current date
      //           selected.
      //           The component should also reset the firstBookingTimes and lastBookingTimes if
      //           the booking gap or duration is changed.
      var increment = model.get('schedule.bookingIncrement');
      var firstBookingTimes = [];
      var lastBookingTimes = [];
      // Times should be in intervals starting from 12am based on interval
      var slots = 24 * 60 / increment;
      var time = (0, _moment.default)().hours(0).minutes(0);

      // NOTE:
      // This will crash if increment is 0. Controller prevents setting a 0 increment.
      for (var i = 0; i < slots; i++) {
        firstBookingTimes.push((0, _moment.default)(time));
        lastBookingTimes.push((0, _moment.default)(time));
        time.add(increment, 'minutes');
      }

      var first = firstBookingTimes.filter(function (time) {
        var current = (0, _moment.default)(model.get('schedule.bookingFirstTime'));
        return time.hour() === current.hour() && time.minute() === current.minute();
      })[0];
      var last = lastBookingTimes.filter(function (time) {
        var current = (0, _moment.default)(model.get('schedule.bookingLastTime'));
        return time.hour() === current.hour() && time.minute() === current.minute();
      })[0];
      controller.set('newBookingFirstTime', first);
      controller.set('newBookingLastTime', last);
      controller.set('firstBookingTimes', firstBookingTimes);
      controller.set('lastBookingTimes', lastBookingTimes);

      controller.set('model', model);
    },

    deactivate: function deactivate() {
      this.currentModel.get('address').then(function (address) {
        address.rollbackAttributes();
      });
      this.currentModel.get('schedule').then(function (schedule) {
        schedule.rollbackAttributes();
      });
      this.currentModel.rollbackAttributes();
    },

    actions: {
      cancel: function cancel() {
        this.transitionTo('authenticated.admin.academy');
      }
    }
  });
});