define('supr-web-ember-v2/routes/authenticated/todos', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  var isPresent = Ember.isPresent;
  var EmberObject = Ember.Object;
  var all = Ember.RSVP.all;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    can: service(),
    flashService: service(),
    currentUserService: service(),

    beforeModel: function beforeModel() {
      if (this.get('can').cannot('access in app')) {
        this.get('flashService').error('Your account has been archived.\nPlease contact an administrator for support.');
        this.transitionTo('authenticated.unauthorized');
      }
    },

    model: function model() {
      var _this = this;

      return this.get('currentUserService.user.todos').catch(function (err) {
        return _this.get('flashService').error(err.message);
      });
    },

    afterModel: function afterModel(model) {
      var self = this;
      var promises = [];
      // FIXME: V1:
      // This is related to Issue #11
      // https://bitbucket.org/supracademy/supracademy-app-client/issues/11/unable-to-add-todo-to-user-without-setting
      model.forEach(function (todo) {
        if (isPresent(todo.get('assignedById'))) {
          promises.push(self.store.findRecord('guide', todo.get('assignedById')).then(function (guide) {
            todo.set('assignedBy', guide);
          }));
        }
      });
      return all(promises).catch(function (err) {
        return self.get('flashService').error(err.message);
      });
    },

    setupController: function setupController(controller, model) {
      var todoModel = EmberObject.create({
        user: this.get('currentUserService.user'),
        todos: model,
        fields: {}
      });
      controller.set('model', model);
      controller.set('newTodoModel', todoModel);
    }
  });
});