define('supr-web-ember-v2/controllers/authenticated/availability', ['exports', 'supr-web-ember-v2/utils/time-helper', 'supr-web-ember-v2/utils/booking-helper', 'moment'], function (exports, _timeHelper, _bookingHelper, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var EmberObject = Ember.Object;
  var A = Ember.A;
  var computed = Ember.computed;
  var observer = Ember.observer;
  var isPresent = Ember.isPresent;
  var isEmpty = Ember.isEmpty;
  var service = Ember.inject.service;
  var all = Ember.RSVP.all;
  exports.default = Controller.extend({
    flashService: service(),
    academyService: service(),

    rows: null,
    changedSlots: A(),
    isNotChanged: true,
    allSlots: A(),

    init: function init() {
      this._super.apply(this, arguments);
    },

    // NOTE: BookingGap needs to be considered when looking up times to offer
    //       to Student, not in creating the availability grid. Availability
    //       represents exactly the time a Guide is available, regardless of what
    //       Bookings could fit into that time.
    gridRows: computed('guide', 'model', 'model.@each', function () {
      var self = this;

      if (isPresent(self.get('rows'))) {
        // TODO: could put this into a helper to always send things like user and
        //       stack.
        //       Also makes it easier to change order of data if we're passing it
        //       into a helper. Just had to reorder all the push data to get
        //       it to appear in a different order in loggly
        //   _LTracker.push({
        //     user: self.get('session.currentUser.id'),
        //     stack: (new Error()).stack,
        //     location: 'controllers.availability.gridRows',
        //     msg: 'returning rows'
        //   });
        return self.get('rows'); // NOTE: is this causing an issue, rows present with no content?
      } else {
        //   _LTracker.push({
        //     user: self.get('session.currentUser.id'),
        //     msg: 'creating rows',
        //     location: 'controllers.availability.gridRows',
        //     stack: (new Error()).stack,
        //   });
        var GridSlot = EmberObject.extend({
          startTime: null,
          duration: null,
          available: false, // available for booking, not available, booked, or reserved are all the states
          reservation: null,
          day: computed('startTime', function () {
            return _moment.default.weekdays()[(0, _moment.default)(this.get('startTime')).day()];
          }),
          displayTime: computed('startTime', function () {
            return (0, _moment.default)(this.get('startTime')).format('h:mm a');
          }),
          selected: computed('available', function () {
            // NOTE: this is quite different than booking grid
            return this.get('available') ? 'available' : '';
          })
          // V1: TODO: the reservations student isn't loadeded at this point so we can't
          //       get the students name. Probably don't really need it. The
          //       Guide can always look at their schedule to see what is on that
          //       day.
          // studentNames: function() {
          //   if(Ember.isPresent(this.get('reservation.student'))) {
          //     return this.get('reservation.student.fullName');
          //   } else {
          //     return null;
          //   }
          // }.property('reservation.student')
        });

        var rows = [];
        var rowTimes = [];
        var firstTime = (0, _moment.default)(self.get('academyService.academy.schedule.bookingFirstTime'));
        var lastTime = (0, _moment.default)(self.get('academyService.academy.schedule.bookingLastTime'));
        var duration = self.get('academyService.academy.schedule.bookingDuration');
        var increment = self.get('academyService.academy.schedule.bookingIncrement');

        var startTimesPerHour = 60 / increment;

        var numExtraRows = Math.ceil((duration - 60) / 60); // TODO: this might not be right. calculate based on increment?
        lastTime.hour(lastTime.hour() + numExtraRows);

        //   _LTracker.push({
        //     user: self.get('session.currentUser.id'),
        //     msg: 'row data',
        //     location: 'controllers.availability.gridRows',
        //     data: {
        //       firstTime: firstTime.toDate(),
        //       lastTime: lastTime.toDate(),
        //       duration: duration,
        //       increment: increment
        //     },
        //     stack: (new Error()).stack
        //   });

        // Rows are by the hour
        for (var time = firstTime.hour(); time <= lastTime.hour(); time++) {
          rowTimes.push(time);
        }

        // Grid Slot available if it falls within the time of an existing Guide Time Slot
        var gridSlotAvailable = function gridSlotAvailable(time, timeSlots) {
          for (var i = 0; i < timeSlots.length; i++) {
            var checkTime = timeSlots[i].get('startTime'); // this seems unecessary but was getting undefined from timeSlots[i].get('startTime')
            var slotDuration = timeSlots[i].get('duration');

            if (_timeHelper.default.isSameTime(time, checkTime) || _timeHelper.default.isBetweenTimes(time, checkTime, slotDuration)) {
              return true;
            }
          }
          return false;
        };

        var createGridSlots = function createGridSlots(day, rowTime, timeSlots) {
          // _LTracker.push({
          //   user: self.get('session.currentUser.id'),
          //   msg: 'about to create grid slots',
          //   location: 'controllers.availability.gridRows.createGridSlots',
          //   data: {
          //     day: day,
          //     rowTime: rowTime
          //   },
          //   stack: (new Error()).stack,
          // });
          var gridSlots = [];

          // for each day check if the grid slot is available
          var slotHour = rowTime;
          var slotMinutes = 0;

          for (var i = 0; i < startTimesPerHour; i++) {
            var newTime = (0, _moment.default)().day(day).hour(slotHour).minute(slotMinutes).toDate();
            var slot = GridSlot.create({
              startTime: newTime,
              duration: increment
            });

            if (gridSlotAvailable(slot.startTime, timeSlots)) {
              slot.set('available', true);
            }

            var reservation = _bookingHelper.default.getSlotReservation(slot, self.get('guide.reservations'));
            if (isPresent(reservation)) {
              slot.set('reserved', true);
              slot.set('reservation', reservation);
            }

            gridSlots.addObject(slot);
            self.get('allSlots').addObject(slot);

            slotHour = (0, _moment.default)(newTime).add(increment, 'minutes').hour();
            slotMinutes = (0, _moment.default)(newTime).add(increment, 'minutes').minute();
          }
          // _LTracker.push({
          //   user: self.get('session.currentUser.id'),
          //   msg: 'created ' + gridSlots.length + ' grid slots',
          //   location: 'controllers.availability.gridRows.createGridSlots',
          //   stack: (new Error()).stack
          // });
          return gridSlots;
        };

        // The availability time slots for this guide
        var mondays = self.model.filterBy('day', 'Monday');
        var tuesdays = self.model.filterBy('day', 'Tuesday');
        var wednesdays = self.model.filterBy('day', 'Wednesday');
        var thursdays = self.model.filterBy('day', 'Thursday');
        var fridays = self.model.filterBy('day', 'Friday');
        var saturdays = self.model.filterBy('day', 'Saturday');
        var sundays = self.model.filterBy('day', 'Sunday');

        // create each row based on start time
        rowTimes.forEach(function (rowTime) {
          // FIXME: V1:
          // Could drop the day in createGridSlots and get it from the collection passed in
          var row = {
            monday: createGridSlots('Monday', rowTime, mondays),
            tuesday: createGridSlots('Tuesday', rowTime, tuesdays),
            wednesday: createGridSlots('Wednesday', rowTime, wednesdays),
            thursday: createGridSlots('Thursday', rowTime, thursdays),
            friday: createGridSlots('Friday', rowTime, fridays),
            saturday: createGridSlots('Saturday', rowTime, saturdays),
            sunday: createGridSlots('Sunday', rowTime, sundays)
          };
          rows.push(row);
        });

        self.set('rows', rows);

        return rows;
      }
    }),

    slotChangeObserver: observer('changedSlots.[]', function () {
      // _LTracker.push({
      //   user: this.get('session.currentUser.id'),
      //   msg: 'slotChangeObserver called',
      //   location: 'controllers.availability.slotChangeObserver',
      //   stack: (new Error()).stack
      // });
      if (isEmpty(this.get('changedSlots'))) {
        this.set('isNotChanged', true);
      } else {
        this.set('isNotChanged', false);
      }
    }),

    reset: function reset() {
      // _LTracker.push({
      //   user: this.get('session.currentUser.id'),
      //   stack: (new Error()).stack,
      //   location: 'controllers.availability.reset',
      //   msg: 'reset called'
      // });
      this.set('changedSlots', []);
      this.set('isNotChanged', true);
    },

    actions: {
      toggleAvailability: function toggleAvailability(slot) {
        //   _LTracker.push({
        //     user: this.get('session.currentUser.id'),
        //     msg: 'toggleAvailability called',
        //     location: 'controllers.availability.actions.toggleAvailability',
        //     stack: (new Error()).stack,
        //   });

        if (isEmpty(slot.get('modified')) || slot.get('modified') === false) {
          slot.set('modified', true);
          this.get('changedSlots').addObject(slot);
        } else {
          slot.set('modified', false);
          this.get('changedSlots').removeObject(slot);
        }

        if (slot.get('reserved')) {
          this.get('flashService').error('<p>This slot has been reserved.</p><p>You must ask an administrator to change this reservation before you can change its availability.</p>');
          return;
        }

        if (slot.get('available')) {
          slot.set('available', false);
        } else {
          slot.set('available', true);
        }
      },

      saveAvailability: function saveAvailability() {
        //   _LTracker.push({
        //     user: this.get('session.currentUser.id'),
        //     msg: 'saveAvailability called',
        //     location: 'controllers.availability.actions.saveAvailability',
        //     stack: (new Error()).stack
        //   });

        var self = this;
        var guide = self.get('guide');
        var days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

        // Delete the old records and then add the new ones
        guide.get('timeSlots').then(function (oldTimeSlots) {
          var promises = [];

          // This bit was weird. In the first version of this class, when we were
          // using numbers for time instead of dates, we simply iterated the oldTimeSlots
          // and deleted the records. Something changed on route to this solution such that
          // this didn't work, the old slots were still in the guide array.
          // Either one of the libraries got updated or something else odd happened, nothing
          // to do with the shift from numbers to dates.
          while (oldTimeSlots.length) {
            var slot = oldTimeSlots.popObject();
            guide.get('timeSlots').removeObject(slot);
            promises.addObject(slot.destroyRecord());
          }
          promises.addObject(guide.save());

          // Wait until all of the records have been deleted before continuing
          all(promises).then(function () {
            // Turn the grid slots into contiguous time slots
            days.forEach(function (day) {
              var slots = self.get('allSlots').filterBy('available', true).filterBy('day', day).sortBy('startTime');
              var currentTimeSlot = null;

              for (var i = 0; i < slots.length; i++) {
                var gridSlot = slots.objectAt(i);

                if (isPresent(currentTimeSlot) && _timeHelper.default.isSameTime(gridSlot.get('startTime'), currentTimeSlot.get('endTime'))) {
                  currentTimeSlot.set('duration', currentTimeSlot.get('duration') + gridSlot.get('duration'));
                  currentTimeSlot.save();
                } else {
                  currentTimeSlot = self.store.createRecord('time-slot', {
                    guide: guide,
                    startTime: gridSlot.get('startTime'),
                    duration: gridSlot.get('duration'),
                    available: true
                  });
                  currentTimeSlot.save();
                  guide.get('timeSlots').pushObject(currentTimeSlot);
                }
              }
              guide.save();
              self.reset();
            });
            self.get('flashService').success('Updated availabilities');
          });
        });
      }
    }
  });
});