define('supr-web-ember-v2/routes/authenticated/admin/guides/guide/edit', ['exports', 'supr-web-ember-v2/config/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  var isEmpty = Ember.isEmpty;
  var reject = Ember.RSVP.reject;
  exports.default = Route.extend({
    flashService: service(),

    afterModel: function afterModel(model) {
      var self = this;
      return this.store.findAll('role', { reload: true }).then(function (roles) {
        self.set('roles', roles);
        // V1:
        // Weird. Roles were supposedly loaded on login but perhaps passing the model
        // didn't pass it's relationships as expected?
        return model.get('roles');
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('hasAdminRole', model.get('isAdmin'));
      controller.set('hasGuideMentorRole', model.get('isGuideMentor'));
    },


    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.reset();
      }
    },

    deactivate: function deactivate() {
      this.currentModel.rollbackAttributes();
    },

    actions: {
      save: function save() {
        var self = this;

        self.currentModel.get('roles').then(function (userRoles) {
          if (self.get('controller.hasAdminRole') && !self.get('currentModel.isAdmin')) {
            var role = self.get('roles').findBy('type', _constants.default.role.ADMIN);
            // NOTE:
            // This will not be necessary when we implement roles properly as
            // an array on user or via Firebase auth
            if (isEmpty(role)) {
              return reject('Admin role is missing');
            }
            userRoles.addObject(role);
          }
          if (!self.get('controller.hasAdminRole') && self.get('currentModel.isAdmin')) {
            var _role = userRoles.findBy('type', _constants.default.role.ADMIN);
            // NOTE:
            // This will not be necessary when we implement roles properly as
            // an array on user or via Firebase auth
            if (isEmpty(_role)) {
              return reject('Admin role is missing');
            }
            userRoles.removeObject(_role);
          }
          if (self.get('controller.hasGuideMentorRole') && !self.get('currentModel.isGuideMentor')) {
            var _role2 = self.get('roles').findBy('type', _constants.default.role.GUIDE_MENTOR);
            // NOTE:
            // This will not be necessary when we implement roles properly as
            // an array on user or via Firebase auth
            if (isEmpty(_role2)) {
              return reject('Guide Mentor role is missing');
            }
            userRoles.addObject(_role2);
          }
          if (!self.get('controller.hasGuideMentorRole') && self.get('currentModel.isGuideMentor')) {
            var _role3 = userRoles.findBy('type', _constants.default.role.GUIDE_MENTOR);
            // NOTE:
            // This will not be necessary when we implement roles properly as
            // an array on user or via Firebase auth
            if (isEmpty(_role3)) {
              return reject('Guide Mentor role is missing');
            }
            userRoles.removeObject(_role3);
          }
          return self.currentModel.save().then(function () {
            self.transitionTo('authenticated.admin.guides.guide');
          });
        }).catch(function (err) {
          self.get('flashService').error('Failed to update Guide. ' + err);
        });
      },
      cancel: function cancel() {
        this.currentModel.rollbackAttributes();
        this.transitionTo('authenticated.admin.guides');
      }
    }
  });
});