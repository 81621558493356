define('supr-web-ember-v2/routes/authenticated/admin/registrations/edit', ['exports', 'supr-web-ember-v2/config/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var Route = Ember.Route;
  var service = Ember.inject.service;
  var all = Ember.RSVP.all;
  var resolve = Ember.RSVP.resolve;
  var A = Ember.A;
  var isEmpty = Ember.isEmpty;
  var isPresent = Ember.isPresent;
  var debug = Ember.debug;
  exports.default = Route.extend({
    fileService: service(),
    registrationService: service(),
    flashService: service(),
    academyService: service(),

    newAgreementFile: null, // ref in case needs to be deleted on a cancel
    agreementFileToDelete: null,

    model: function model(params) {
      // Return a fresh record
      return this.get('store').findRecord('registration', params.registration_id, {
        reload: true
      });
    },

    // FIXME: V1:
    // Change naming related to reservations so it's clearer
    // - the model for this route / registration has its reservations
    // - we also load ALL of the reservations so we can check for availabilities
    // - the property reservations below should probably be allReservations
    afterModel: function afterModel(model) {
      var self = this;
      var availabilities = this.get('store').findAll('time-slot');
      var allReservations = this.get('store').findAll('reservation');
      var existingReservations = model.get('student.reservations');
      var guides = this.get('store').findAll('guide');
      var guardians = this.get('store').findAll('guardian');
      var student = model.get('student');
      var students = this.get('store').findAll('student');
      var promises = [student, availabilities, allReservations, existingReservations, guides, guardians, students];
      return all(promises).then(function (result) {
        var _result = _slicedToArray(result, 6),
            student = _result[0],
            availabilities = _result[1],
            allReservations = _result[2],
            existingReservations = _result[3],
            guides = _result[4],
            guardians = _result[5];

        self.set('availabilities', availabilities);
        self.set('allReservations', allReservations);
        self.set('existingReservations', existingReservations);
        self.set('guides', guides);
        self.set('guardians', guardians);
        self.set('student', student);
        // Preload the bookings so we can decide when to show the modal on reservation cancel
        return existingReservations.map(function (reservation) {
          return reservation.get('bookings');
        });
      }).then(function () {
        // NOTE: After updating the primary guide on the registration, if we
        //       look at the Ember inspector we see the correct primary guide on
        //       the student but it we start at the registration and drill down
        //       to the student and then primary guide it's the old one... A
        //       page reload solves the problem.
        //
        //       Loading all of the students above also fixes the problem.
        //
        //       Attempting to load the primary guide at the same level as the
        //       student, via model.get('student.primaryGuide') was failing
        //       inexplicably
        return self.get('student.primaryGuide');
      }).then(function (guide) {
        self.set('primaryGuide', guide);
      });
    },

    // V1: TODO: DRY this up as we have the same stuff in registrations/new.js
    setupController: function setupController(controller, model) {
      // Set up file for populating by upload if they don't exist
      controller.set('agreementUpload', this.get('store').createRecord('file'));
      controller.set('availabilityTimeSlots', this.get('availabilities'));
      controller.set('allReservations', this.get('allReservations'));
      controller.set('existingReservations', this.get('existingReservations'));
      controller.set('guides', this.get('guides'));
      controller.set('guardians', this.get('guardians'));
      controller.set('selectedGuardians', A().addObjects(model.get('guardians')));

      var plans = Object.values(_constants.default.plan);
      controller.set('plans', plans);
      controller.set('existingPlan', model.get('plan'));

      this._super(controller, model); // need this to pass the model on
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.reset();
        controller.set('selectedGuardians', A().addObjects(controller.get('model.guardians')));
      }
    },

    reset: function reset() {
      this.set('primaryGuide', null);
      this.set('availabilities', null);
      this.set('allReservations', null);
      this.set('existingReservations', null);
      this.set('guides', null);
      this.set('guardians', null);
      this.set('student', null);
      this.resetController(this.controller, true);
    },

    willTransition: function willTransition() {
      this.reset();
    },

    actions: {
      save: function save() {
        var self = this;
        this.send('showLoadingSpinner');

        // Things that may be added:
        // - Agreement
        // - Reservations
        // - Guardians
        //
        // Things that may change:
        // - Plan
        // - Registration
        // - Guide
        // - Guardians
        // - Agreement
        //
        // Things that may be removed
        // - Reservations
        // - Guardians: need to track so reg and student can be removed from them

        // NOTE: Not sure what the right approach is with preloading relationships
        //       that are needed. If we ask for the relationship, even after
        //       loading it, we get back fulfilled promises, from which we can get
        //       the object at somePromise.content. The other option is store the
        //       resolved promise in some other variable, in which case we can use
        //       it normally, like below with student.

        var registration = this.currentModel;
        var existingReservations = this.get('controller.existingReservations');
        var deletedReservations = this.get('controller.deletedReservations');
        var academy = this.get('academyService.academy');
        var currentGuide = this.get('primaryGuide');
        var selectedGuide = this.get('controller.selectedPrimaryGuide');
        var student = this.get('student');
        var guide = selectedGuide ? selectedGuide : currentGuide;
        var newReservations = this.controller.get('newReservations');
        var guardians = this.controller.get('selectedGuardians');

        if (isEmpty(guardians)) {
          self.get('flashService').error('At least one Guardian is required');
          this.send('hideLoadingSpinner');
          return;
        }

        var plan = registration.get('plan');
        var startDate = registration.get('startDate');
        if (plan && plan != _constants.default.plan.NONE && !startDate) {
          self.get('flashService').error('A plan requires a start date');
          this.send('hideLoadingSpinner');
          return;
        }

        self.get('registrationService').updateRegistration(registration, this.get('agreementFileToDelete'), student, guide, guardians).then(function (results) {
          var student = results[1].value;
          var guide = results[2].value;
          var guardians = results[3].value;
          var registration = results[5].value;
          return self.get('registrationService').updateSchedule(registration, student, guide, guardians, existingReservations, deletedReservations, newReservations, academy);
        }).then(function () {
          return self.get('flashService').success('Registration updated');
        }).catch(function (err) {
          console.error(err);
          debug('updateRegistration: Ouch, updating one of the records failed' + err);
          self.get('flashService').error('Registration update failed: ' + err);
        }).finally(function () {
          self.send('hideLoadingSpinner');
          self.reset();
          self.transitionTo('authenticated.admin.registrations.registration', registration);
        });
      },

      saveAgreement: function saveAgreement(fileRecord) {
        var _this = this;

        this.send('showLoadingSpinner');
        fileRecord.save().then(function (file) {
          _this.set('newAgreementFile', file);
          _this.currentModel.set('agreement', file);
        }).catch(function (err) {
          console.log(err);
          debug('failed to save agreement file ' + err);
        }).finally(function () {
          _this.send('hideLoadingSpinner');
        });
      },

      // FIXME Probably the case that this should just be saveAgreement has
      // handleSelection was just saving things anyway. No need for 2 steps as
      // this and saveAgreement do basically the same thing
      agreementSelected: function agreementSelected(file) {
        // TODO: disable button and show spinner while saving
        var self = this;
        return this.get('fileService').save(file).then(function (theFile) {
          self.set('newAgreementFile', theFile);
          self.currentModel.set('agreement', theFile);
        }).catch(function (err) {
          console.log(err);
          debug('failed to save agreement file ' + err);
        });
      },
      removeAgreement: function removeAgreement(file) {
        // TODO: disable button and show spinner while saving
        this.get('controller').set('pickAgreement', false);
        this.set('agreementFileToDelete', file);
        this.currentModel.set('agreement', null);
      },

      cancel: function cancel() {
        var _this2 = this;

        var self = this;
        this.send('showLoadingSpinner');

        var promise = isPresent(this.get('newAgreementFile')) ? self.get('fileService').delete(resolve(this.get('newAgreementFile'))).then(function () {
          return _this2.set('newAgreementFile', null);
        }).catch(function (err) {
          console.log(err);
          debug('failed to delete agreement file ' + err);
        }) : resolve();

        this.currentModel.rollbackAttributes();
        this.controller.reset();
        this.get('controller.deletedReservations').forEach(function (reservation) {
          this.get('controller.existingReservations').addObject(reservation);
        });
        this.set('controller.deletedReservations', []);

        promise.finally(function () {
          self.send('hideLoadingSpinner');
          self.transitionTo('authenticated.admin.registrations');
        });
      }
    }
  });
});