define('supr-web-ember-v2/controllers/authenticated/admin/bookings/new', ['exports', 'moment', 'supr-web-ember-v2/utils/booking-helper', 'supr-web-ember-v2/config/constants'], function (exports, _moment, _bookingHelper, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var isPresent = Ember.isPresent;
  var isEmpty = Ember.isEmpty;
  var debug = Ember.debug;
  var htmlSafe = Ember.String.htmlSafe;
  var all = Ember.RSVP.all;
  var resolve = Ember.RSVP.resolve;
  var get = Ember.get;
  exports.default = Controller.extend({
    flashService: service(),
    gridService: service(),
    academyService: service(),
    notifyService: service(),
    modalsManager: service(),

    allSlots: null,
    selectedSlots: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('allSlots', []);
      this.set('selectedSlots', []);
      this.set('selectedWeekStart', (0, _moment.default)().day(1)); // Start week on Monday)
    },

    selectedWeekEnd: computed('selectedWeekStart', function () {
      return (0, _moment.default)(this.get('selectedWeekStart')).add(6, 'days');
    }),

    selectedWeek: computed('selectedWeekStart', function () {
      return this.get('selectedWeekStart').format('MMM D') + ' - ' + this.get('selectedWeekEnd').format('MMM D') + ', ' + this.get('selectedWeekEnd').format('YYYY'); // display. e.g. Mar 30 – Apr 5, 2015
    }),

    // FIXME:
    // This is WAY too big a funciton
    gridRows: computed('selectedGuide', 'selectedWeekStart', 'bookings', 'bookings.@each', 'timeSlots', 'timeSlots.@each', function () {
      var self = this;

      // FIXME
      // Think gridRows should come from the grid service as well

      // TODO
      // this should be refactored into something. It will never be persisted so
      // it's not clear if it should be a model or just an Ember object exported
      // (makes most sense), actually, these could probably be merged with the
      // availability-slot component as that is what they are used for.
      //
      // As well, the idea of the slot state (available, not available, booked,
      // reserved) should probably be handled with one property, 'status',
      // although you can't bind on that on less you do something like
      // status.available status.booked via status:
      // {
      // available: true,
      // booked: false,
      // reserved: true,
      // }

      var rows = [];
      var rowTimes = [];
      var firstTime = (0, _moment.default)(self.get('academyService.academy.schedule.bookingFirstTime'));
      var lastTime = (0, _moment.default)(self.get('academyService.academy.schedule.bookingLastTime'));
      var duration = self.get('academyService.academy.schedule.bookingDuration');

      // TODO this might not be right. calculate based on increment?
      var numExtraRows = Math.ceil((duration - 60) / 60);
      lastTime.hour(lastTime.hour() + numExtraRows);

      // Rows are by the hour
      for (var time = firstTime.hour(); time <= lastTime.hour(); time++) {
        rowTimes.push(time);
      }

      var guide = self.get('selectedGuide');

      if (isPresent(guide)) {
        // FIXME:
        // Check that this can be removed because should be set via init
        // Set week to current week if not selected
        if (isEmpty(self.get('selectedWeekStart'))) {
          // Start week on Monday
          self.set('selectedWeekStart', (0, _moment.default)().day(1));
        }

        var timeSlots = self.get('timeSlots').filterBy('guide.id', guide.get('id'));

        // The availability time slots for this guide
        var availableMondays = timeSlots.filterBy('day', 'Monday');
        var availableTuesdays = timeSlots.filterBy('day', 'Tuesday');
        var availableWednesdays = timeSlots.filterBy('day', 'Wednesday');
        var availableThursdays = timeSlots.filterBy('day', 'Thursday');
        var availableFridays = timeSlots.filterBy('day', 'Friday');
        var availableSaturdays = timeSlots.filterBy('day', 'Saturday');
        var availableSundays = timeSlots.filterBy('day', 'Sunday');

        // The Bookings for the guide
        var bookings = self.get('bookings').filterBy('guide.id', guide.get('id'));

        // Bookings by day of the selected week
        var mondayBookings = bookings.filterBy('day', 'Monday');
        var tuesdayBookings = bookings.filterBy('day', 'Tuesday');
        var wednesdayBookings = bookings.filterBy('day', 'Wednesday');
        var thursdayBookings = bookings.filterBy('day', 'Thursday');
        var fridayBookings = bookings.filterBy('day', 'Friday');
        var saturdayBookings = bookings.filterBy('day', 'Saturday');
        var sundayBookings = bookings.filterBy('day', 'Sunday');

        // create each row based on start time
        rowTimes.forEach(function (rowTime) {
          var row = {
            monday: self.get('gridService').createGridSlots('Monday', rowTime, mondayBookings, availableMondays, self.get('selectedWeekStart'), self.get('selectedGuide')),
            tuesday: self.get('gridService').createGridSlots('Tuesday', rowTime, tuesdayBookings, availableTuesdays, self.get('selectedWeekStart'), self.get('selectedGuide')),
            wednesday: self.get('gridService').createGridSlots('Wednesday', rowTime, wednesdayBookings, availableWednesdays, self.get('selectedWeekStart'), self.get('selectedGuide')),
            thursday: self.get('gridService').createGridSlots('Thursday', rowTime, thursdayBookings, availableThursdays, self.get('selectedWeekStart'), self.get('selectedGuide')),
            friday: self.get('gridService').createGridSlots('Friday', rowTime, fridayBookings, availableFridays, self.get('selectedWeekStart'), self.get('selectedGuide')),
            saturday: self.get('gridService').createGridSlots('Saturday', rowTime, saturdayBookings, availableSaturdays, self.get('selectedWeekStart'), self.get('selectedGuide')),
            sunday: self.get('gridService').createGridSlots('Sunday', rowTime, sundayBookings, availableSundays, self.get('selectedWeekStart'), self.get('selectedGuide'))
          };

          // Update slots and slot selections
          var newSlots = [].concat(_toConsumableArray(row.monday), _toConsumableArray(row.tuesday), _toConsumableArray(row.wednesday), _toConsumableArray(row.thursday), _toConsumableArray(row.friday), _toConsumableArray(row.saturday), _toConsumableArray(row.sunday));
          self.get('allSlots').addObjects(newSlots);

          // Check if slot has been selected in a previous viewing of this week.
          // If it was selected then the new slot being created here should be
          // selected and the old removed from the tracking array selectedSlots
          if (!isEmpty(self.get('selectedSlots'))) {
            newSlots.forEach(function (slot) {
              self.set('selectedSlots', self.get('gridService').updateSlotSelections(slot, self.get('selectedSlots')));
            });
          }

          rows.push(row);
        });

        return rows;
      }
    }),

    reset: function reset() {
      this.set('_joinedLearningSessionBookingId', null);
      this.set('allSlots', []);
      this.set('bookingCandidate', null);
      this.set('joinableBookings', []);
      this.set('lastSelectedDay', null);
      this.set('selectedGuide', null);
      this.set('selectedSlots', []);
      this.set('selectedStudent', null);
      this.set('showConflictedReservations', false);
      this.set('showJoinableBookings', false);
    },

    clearSlotSelections: function clearSlotSelections() {
      this.get('selectedSlots').forEach(function (slot) {
        slot.set('selected', false);
      });
      this.set('selectedSlots', []);
    },

    actions: {
      toggleBookingSelection: function toggleBookingSelection(slot) {
        // clear selection on first new selection
        if (this.get('lastSelectedDay') === null) {
          this.clearSlotSelections();
          // clear selection if we're clicking a different day
        } else if (this.get('lastSelectedDay') !== slot.get('day')) {
          this.clearSlotSelections();
        }
        this.set('lastSelectedDay', slot.get('day'));

        // There can only be one series of slots selected so if we've moved to a
        // new week view then clear the existing slot selections
        var lastSelection = this.get('selectedSlots.firstObject');
        if (isPresent(lastSelection) && !(0, _moment.default)(lastSelection.get('startTime')).isSame(this.get('selectedWeekStart'), 'week')) {
          this.set('selectedSlots', []);
        }

        if (slot.get('selected')) {
          slot.set('selected', false);
          this.get('selectedSlots').removeObject(slot);
        } else {
          slot.set('selected', true);
          this.get('selectedSlots').addObject(slot);
        }
      },

      nextWeek: function nextWeek() {
        this.set('selectedWeekStart', (0, _moment.default)(this.get('selectedWeekStart')).add(7, 'days'));
      },

      previousWeek: function previousWeek() {
        this.set('selectedWeekStart', (0, _moment.default)(this.get('selectedWeekStart')).subtract(7, 'days'));
      },

      acceptAllBookingOverReservations: function acceptAllBookingOverReservations() {
        var self = this;
        this.get('bookingCandidate._newBookerReservationConflicts').forEach(function (conflict) {
          self.send('acceptBookingOverReservation', conflict);
        });
      },

      // Step 1
      submitBooking: function submitBooking() {
        var self = this;

        var slots = self.get('selectedSlots').sortBy('startTime');

        // Ensure all slots from same day
        var day = null;
        var error = false;
        slots.forEach(function (slot) {
          if (isPresent(day)) {
            if (day !== slot.get('day')) {
              error = true;
            }
          } else {
            day = slot.get('day');
          }
        });
        if (error) {
          this.get('flashService').error('You can only make a Booking for a single day.');
          return;
        }

        // Ensure the slots are contiguous
        var lastSlot = null;
        slots.forEach(function (slot) {
          if (isPresent(lastSlot)) {
            if (!(0, _moment.default)(lastSlot.get('endTime')).isSame((0, _moment.default)(slot.get('startTime')), 'minute')) {
              error = true;
            }
          }
          lastSlot = slot;
        });
        if (error) {
          this.get('flashService').error('A Booking must be for a series of slots.');
          return;
        }

        // Create the Booking Candidate
        var bookingCandidate = {
          student: self.get('selectedStudent'),
          guide: self.get('selectedGuide'),
          startTime: slots.get('firstObject.startTime'),
          duration: 0,
          bookedOn: new Date(),
          status: _constants.default.booking.status.CONFIRMED
        };

        // Determine the Booking Candidate duration
        var duration = 0;
        slots.forEach(function (slot) {
          duration = duration + slot.get('duration');
        });
        bookingCandidate.duration = duration;

        self.set('bookingCandidate', bookingCandidate);
        self.send('confirmBookingDuration');
      },

      // Step 2
      confirmBookingDuration: function confirmBookingDuration() {
        var self = this;
        // Booking should be default duration unless override via modal
        var standardDuration = self.get('academyService.academy.schedule.bookingDuration');

        if (this.get('bookingCandidate.duration') !== standardDuration) {
          var message = htmlSafe('<p>The standard Booking duration is ' + standardDuration + ' minutes.</p><p>Would you like to proceed with your selection of ' + this.get('bookingCandidate.duration') + ' mintues?</p>');

          get(this, 'modalsManager').confirm({ title: 'Please confirm booking duration', body: message }).then(function () {
            // called after user clicks "Yes" in the modal
            debug('Non standard Booking duration accepted');
            self.send('confirmBookingAvailability'); // next step
          }).catch(function () {
            // NOTE
            // This is one thing I don't like about ember-modals-manager, that a
            // catch is used for an intended result rather than an error. I
            // think it would be preferable to return a (boolean) result that
            // indicates what the user clicked.

            // called after user clicks "No" in the modal
            debug('Booking submit cancelled on duration check step.');
            return;
          });
        } else {
          self.send('confirmBookingAvailability'); // next step
        }
      },

      // Step 3
      // All of the selected slots must be part of the Guides availability matrix
      // or the booking will have to be confirmed.
      confirmBookingAvailability: function confirmBookingAvailability() {
        var self = this;
        var message = htmlSafe('<p>At least one of the slots you have selected is not part of the Guides availabilities.</p><p>Are you sure you want to include these slots in your booking?</p>');

        var slots = self.get('selectedSlots');
        var allAvailable = true;
        for (var i = 0; i < slots.length; i++) {
          if (!slots[i].get('available')) {
            allAvailable = false;
          }
        }
        if (allAvailable) {
          self.send('confirmReservationOverride');
        } else {
          get(this, 'modalsManager').confirm({
            title: 'Please confirm outside availability selection',
            body: message
          }).then(function () {
            // called after user clicks "Yes" in the modal
            debug('Guide availability override accepted');
            // IMPROVE We have chains of modals, some via the modalsManager and
            // some via the template. We could create modalsManager custom
            // modals to make is such that they all open via the manager.

            // after this modal has closed
            self.send('confirmReservationOverride'); // next step
          }).catch(function () {
            // called after user clicks "No" in the modal
            debug('Booking submit cancelled on Guide availability check step.');
            return;
          });
        }
      },

      // Step 4
      // We show a list of the reservations (booker or booked) as part of
      // confirmation
      confirmReservationOverride: function confirmReservationOverride() {
        var self = this;

        // Find any reserved slots the updated Booking will cover
        var newlyConflictedReservations = [];
        var slots = self.get('selectedSlots');
        slots.forEach(function (slot) {
          if (slot.get('reserved') && slot.get('reservation.student.id') !== self.get('bookingCandidate.student.id')) {
            newlyConflictedReservations.addObject(slot.get('reservation'));
          }
        });

        if (isEmpty(newlyConflictedReservations)) {
          // Nothing else to do
          return self.send('handleMultipleBookingSelection');
        }

        // Create conflict records for any new conflicts. Will be booking over
        // reservations only.
        //
        // FIXME It looks like we create the booking-over-reservation-conflict
        // here but DON'T add them to the booking...
        // Perhaps it's because we have a belongsTo relationship. IF the Booking
        // already existed it would probably get added.
        // Looks like might be sending them along to get added later via
        // bookingCandidate._newBookerReservationConflicts
        var newBookerReservationConflicts = [];
        newlyConflictedReservations.forEach(function (reservation) {
          var conflictRecord = self.store.createRecord('booking-over-reservation-conflict', {
            status: _constants.default.scheduleConflict.status.UNRESOLVED,
            bookedOver: reservation
          });
          newBookerReservationConflicts.addObject(conflictRecord.save());
        });

        // Trigger the modal
        // - Show conflicts in bookingCandidate.newBookerReservationConflicts
        all(newBookerReservationConflicts).then(function (results) {
          self.set('bookingCandidate._newBookerReservationConflicts', results);
          self.set('showConflictedReservations', true);
        }).catch(function (error) {
          console.error(error);
          self.get('flashService').error(error);
        });
      },

      // Step 4.1
      acceptBookingOverReservation: function acceptBookingOverReservation(conflict) {
        conflict.set('status', _constants.default.scheduleConflict.status.RESOLVED);
        // If this was the last conflict to be resolved then move on to next Step
        var proceed = this.get('bookingCandidate._newBookerReservationConflicts').reduce(function (previousValue, item) {
          return previousValue === false ? previousValue : item.get('status') === _constants.default.scheduleConflict.status.RESOLVED;
        }, true);
        if (proceed) {
          this.send('handleMultipleBookingSelection');
          this.set('showConflictedReservations', false);
        }
      },

      // Step 5
      // TODO maybe a better interface would be that as you select slots the
      // options for what you can do show up on screen and then you just select
      // the options you want, instead of going through a series of modals. also
      // lets you see quickly the implications of choosing slots.
      //
      // Cases here are
      //   1. Don't want to join the Bookings / Learning Sessions and have
      //      multiple distinct Bookings for the slots
      //      - default if you don't join, cancel, or update existing
      //   2. Want to join one of many possible Bookings / Learning Sessions. If
      //      the updated span covers more than one sequential Booking we don't
      //      allow joining both (all) as joining means being part of the same
      //      learning session. One Booking can be joined. The same thing can be
      //      accomplished by creating x Bookings and joing the x Bookings
      //      individually.
      //      - yes / no -> booking options to join learning session for one of
      //        the Bookings
      //      - need a wormhole to display options
      //      - Booking time updated to match the Booking of the learning session
      //        being joined
      //   3. One of the Bookings already belongs to the Student and they want to
      //      change the duration. Duration amount has already been accepted by
      //      this point.
      //      - if this is the case then jump straight to save
      //      - if you were intending to join a Booking the duration would be the
      //        same as the Booking wanting to be joined.
      //      - option to join a Booking of same duration won't be provided
      //        because the Booking will appear to be unchanged and will trigger
      //        return in updateBooking call.
      //      - TODO a different UI for Booking edits or checking if there is a
      //        Booking to join in the updateBooking call is probably in order.
      //        The fallback is to delete and re-create the Booking, where joining
      //        will be an option as there is no pre-existing Booking.
      //   4. Want to cancel the Booking edit
      //
      //   - Find set of Bookings for selected slots
      //     - If one of them is own Booking then skip to Save as time has been
      //       adjusted
      //     - If none are own ask if want to join one of the Bookings
      //  - If no existing Bookings then skip to Save
      handleMultipleBookingSelection: function handleMultipleBookingSelection() {
        var self = this;

        var slotsWithBookings = this.get('selectedSlots').filter(function (slot) {
          return isPresent(slot.get('bookings'));
        });

        if (isPresent(slotsWithBookings)) {
          this.set('joinableBookings', []);
          slotsWithBookings.forEach(function (slot) {
            slot.get('bookings').forEach(function (booking) {
              if (booking.get('student.id') !== self.get('bookingCandidate.student.id')) {
                self.get('joinableBookings').addObject(booking);
              }
            });
          });

          if (isEmpty(self.get('joinableBookings'))) {
            return this.send('saveBooking'); // nothing else to do
          }

          // Trigger modal to appear
          this.set('showJoinableBookings', true);
        } else {
          this.send('saveBooking');
        }
      },

      // Step 5.1
      // Join an existing Bookings Learning Session
      acceptJoinExistingLearningSession: function acceptJoinExistingLearningSession(existingBooking) {
        debug('Joining an existing Learning Session');
        this.set('showJoinableBookings', false);
        this.set('_joinedLearningSessionBookingId', existingBooking.get('id'));

        this.set('bookingCandidate.learningSession', existingBooking.get('learningSession'));
        this.set('bookingCandidate.startTime', existingBooking.get('startTime'));
        this.set('bookingCandidate.duration', existingBooking.get('duration'));

        this.send('saveBooking'); // next step
      },

      // Step 6
      saveBooking: function saveBooking() {
        var self = this;
        var booking = void 0;

        self.send('showLoadingSpinner');

        var candidate = self.get('bookingCandidate');

        // Create the Booking
        var createBooking = function () {
          return self.store.createRecord('booking', self.get('bookingCandidate')).save().then(function (theBooking) {
            return booking = theBooking;
          });
        }.bind(this);

        var updateBookerReservationConflicts = function () {
          var self = this;

          // Destroy the set of bookerReservation conflicts that are not in the
          // newly created set.
          var newConflicts = self.get('bookingCandidate._newBookerReservationConflicts');
          if (isEmpty(newConflicts)) {
            return resolve();
          }
          var promises = newConflicts.map(function (conflict) {
            conflict.set('booker', booking);
            return conflict.save();
          });
          return all(promises).then(function (conflicts) {
            // Saving the Bookings / Reservations in the conflict adds the
            // hasMany side of the conflict to them
            var promises = [];
            conflicts.forEach(function (conflict) {
              promises.push(conflict.get('booker').then(function (booker) {
                return booker.save();
              }));
              promises.push(conflict.get('bookedOver').then(function (bookedOver) {
                return bookedOver.save();
              }));
            });
            return all(promises);
          });
        }.bind(this);

        // Handle booking over booking conflicts
        // - Create booking over booking conflicts for any new cases
        //   - This includes the case where we joined a learning sessions booking.
        //     A joined Bookings conflict should be set to resolved.
        var updateBookingConflicts = function () {
          var self = this;
          return _bookingHelper.default.getBookingConflicts(booking, this.store.findAll('booking')).then(function (conflictedBookings) {
            var preserveId = self.get('_joinedLearningSessionBookingId');
            var promises = [];

            // Create Booking conflicts
            // If a new one matches the preserveId then it should have it's status
            // set to resolved, otherwise to unresolved.
            conflictedBookings.forEach(function (conflictedBooking) {
              var status = _constants.default.scheduleConflict.status.UNRESOLVED;
              if (isPresent(preserveId) && conflictedBooking.get('id') === preserveId) {
                status = _constants.default.scheduleConflict.status.RESOLVED;
              }

              promises.push(self.store.createRecord('booking-over-booking-conflict', {
                booker: booking,
                bookedOver: conflictedBooking,
                status: status
              }).save());
            });
            return all(promises);
          });
        }.bind(this);

        // Case we've joined an existing learning session (only on an edit of an
        // existing Booking)
        var updateLearningSession = function () {
          if (isPresent(candidate.learningSession)) {
            return _bookingHelper.default.safeDestroyLearningSession(booking).then(function () {
              booking.set('learningSession', candidate.learningSession);

              return booking.get('learningSession').then(function (theLearningSession) {
                return theLearningSession.get('students').then(function (theStudents) {
                  if (isEmpty(theStudents)) {
                    self.get('flashService').error('Learning Session had no students.');
                    // This should never be the case. A learning session
                    // will always have students or be deleted.
                    return resolve();
                  }
                  return booking.get('student').then(function (theStudent) {
                    theStudents.addObject(theStudent);
                    return theLearningSession.get('bookings').then(function (theBookings) {
                      theBookings.addObject(booking);
                      return theLearningSession.save();
                    });
                  });
                });
              });
            });
          } else {
            return self.store.createRecord('learning-session', {
              guide: self.get('selectedGuide')
            }).save().then(function (theSession) {
              var promises = [];
              // FIXME
              // Historically, students have failed to be added to MOST of the
              // learning-sessions except the last few. I had left notes
              // suggesting that maybe the students and guide should be accessed
              // via the bookings anyway. theSession.get('students') is failing
              // (because selectedStudent is null)
              theSession.get('students').addObject(self.get('selectedStudent'));
              theSession.set('guide', self.get('selectedGuide'));
              theSession.get('bookings').addObject(booking);
              promises.push(theSession.save());
              booking.set('learningSession', theSession);
              promises.push(booking.save());
              return all(promises).then(function () {
                var promises = [];
                self.get('selectedStudent.bookings').addObject(booking);
                promises.push(self.get('selectedStudent').save());
                self.get('selectedGuide.bookings').addObject(booking);
                promises.push(self.get('selectedGuide').save());
                return all(promises);
              });
            });
          }
        }.bind(this);

        var sendNotifications = function () {
          // TODO might want to send guide directly to item they can edit and
          //       student/guardian to a view only page...
          var recipients = [{ model: 'student', id: booking.get('student.id') }, { model: 'guide', id: booking.get('guide.id') }];
          return all([booking.get('student.guardians'), booking.get('student'), booking.get('guide')]).then(function (results) {
            var guardians = results[0];
            var student = results[1];
            var guide = results[2];
            guardians.forEach(function (guardian) {
              recipients.addObject({ model: 'guardian', id: guardian.get('id') });
            });
            return self.get('notifyService').sendNotification(recipients, 'A new booking for ' + student.get('fullName') + ' with ' + guide.get('fullName') + ' on ' + booking.get('displayDate') + ' has been created.', { route: 'authenticated.bookings' });
          });
        }.bind(this);

        // Think this may be redundant at this point.
        var saveUpdatedBooking = function () {
          var promises = [];
          promises.push(booking.get('student').then(function (theStudent) {
            return theStudent.save();
          }));
          promises.push(booking.get('guide').then(function (theGuide) {
            return theGuide.save();
          }));
          promises.push(booking.save());
          return all(promises);
        }.bind(this);

        var cleanUp = function () {
          return self.reset();
        }.bind(this);

        // Error handler for the Promise chain. All errors trickle down to this
        // handler if there are no rejection handlers present before it
        // NOTE This doesn't appear to work well. The stack appears to come from
        //       here instead of where the error originates.
        var displayAndReportError = function (error) {
          console.error(error);
          if (error.message) {
            error = error.message;
          }
          this.get('flashService').error(error);
        }.bind(this);

        var returnToBookings = function () {
          self.send('hideLoadingSpinner');
          self.transitionToRoute('authenticated.admin.bookings');
        }.bind(this);

        // FIXME Most/all of these functions could be moved to the BookingHelper
        //
        // In relation to an earlier thought about notifications coming from
        // BookingHelper I now DO think it makes sense to show messages as they
        // originate as they are independent of the logic.
        //
        // We would need to make BookingHeleper a service to inject the
        // flashService as messages are generated in numerous of these functions.
        resolve().then(createBooking).then(updateBookerReservationConflicts).then(updateBookingConflicts).then(updateLearningSession).then(saveUpdatedBooking).then(sendNotifications).then(cleanUp).then(null, displayAndReportError).then(returnToBookings);
      },

      // The next submit clears bookingCandidate
      cancelJoinExistingBooking: function cancelJoinExistingBooking() {
        this.set('showJoinableBookings', false);
      },

      cancelBookingReservation: function cancelBookingReservation() {
        this.set('showConflictedReservations', false);
        this.get('bookingCandidate._newBookerReservationConflicts').forEach(function (conflict) {
          // Booker hasn't been added to conflict at this point
          var bookedOver = conflict.get('bookedOver');
          conflict.destroyRecord().then(function () {
            // These would only work if the PromiseObjects have been resolved.
            // Not guaranteed at this point.
            // bookedOver.get('content').save();
            bookedOver.then(function (theBookedOver) {
              theBookedOver.save();
            });
          });
        });
        this.get('bookingCandidate._newBookerReservationConflicts').clear();
      }
    }
  });
});