define('supr-web-ember-v2/services/statistics-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var computed = Ember.computed;
  exports.default = Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('records', {});
    },


    recordsAsArray: computed('records', function () {
      var self = this;
      var keys = Object.keys(this.get('records'));
      return keys.map(function (key) {
        var recordStats = self.get('records')[key];
        recordStats.recordType = key;
        return recordStats;
      });
    }),

    resetRecordStats: function resetRecordStats() {
      this.set('records', {});
    },

    incrementRecordStat: function incrementRecordStat(record, statKey) {
      var recordStats = this.get('records');
      var modelName = record.get('constructor.modelName');
      recordStats[modelName] = recordStats[modelName] ? recordStats[modelName] : {};
      var count = recordStats[modelName][statKey] ? recordStats[modelName][statKey] : 0;
      count = count + 1;
      // recordStats[modelName][statKey] = count;
      // recordStats[modelName][statKey] = count;
      this.set('records.' + modelName + '.' + statKey, count);
    }
  });
});