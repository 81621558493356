define('supr-web-ember-v2/controllers/authenticated/admin/bookings/edit', ['exports', 'moment', 'supr-web-ember-v2/utils/booking-helper', 'supr-web-ember-v2/config/constants'], function (exports, _moment, _bookingHelper, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var isPresent = Ember.isPresent;
  var isEmpty = Ember.isEmpty;
  var debug = Ember.debug;
  var htmlSafe = Ember.String.htmlSafe;
  var all = Ember.RSVP.all;
  var resolve = Ember.RSVP.resolve;
  var get = Ember.get;
  exports.default = Controller.extend({
    flashService: service(),
    gridService: service(),
    academyService: service(),
    notifyService: service(),
    modalsManager: service(),

    // FIXME: V1:
    // Not sure why we keep a collection of all the slots. Not explicitly used anywhere.
    allSlots: null,
    selectedSlots: null,
    selectedWeekStartValue: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('allSlots', []);
      this.set('selectedSlots', []);
      this.set('selectedWeekStart', (0, _moment.default)().day(1)); // Start week on Monday)
    },

    // FIXME:
    // Think unlikely we need other than what is in the init function, as in the new.js controller
    // selectedWeekStart: computed('selectedWeekStartValue', function(key, value) {
    //   // getter
    //   if (arguments.length === 1) {
    //     if (isEmpty(this.get('selectedWeekStartValue'))) {
    //       // Set week to current week if no week has been selected
    //       this.set(
    //         'selectedWeekStartValue',
    //         moment(this.get('model.startTime')).day(1)
    //       ); // Start week on Monday
    //     }
    //     return this.get('selectedWeekStartValue');
    //     // setter
    //   } else {
    //     this.set('selectedWeekStartValue', value);
    //     return value;
    //   }
    // }),

    // IMPROVE:
    // Could be part of grid service?
    selectedWeekEnd: computed('selectedWeekStart', function () {
      return (0, _moment.default)(this.get('selectedWeekStart')).add(6, 'days');
    }),

    // IMPROVE:
    // Could be part of grid service?
    selectedWeek: computed('selectedWeekStart', function () {
      return this.get('selectedWeekStart').format('MMM D') + ' - ' + this.get('selectedWeekEnd').format('MMM D') + ', ' + this.get('selectedWeekStart').format('YYYY'); // display. e.g. Mar 30 – Apr 5, 2015
    }),

    gridRows: computed('selectedGuide', 'selectedWeekStart', 'bookings', 'bookings.@each', 'timeSlots', 'timeSlots.@each', 'model.{startTime,duration}', function () {
      var self = this;

      var rows = [];
      var rowTimes = [];
      var firstTime = (0, _moment.default)(self.get('academyService.academy.schedule.bookingFirstTime'));
      var lastTime = (0, _moment.default)(self.get('academyService.academy.schedule.bookingLastTime'));
      var duration = self.get('academyService.academy.schedule.bookingDuration');

      var numExtraRows = Math.ceil((duration - 60) / 60); // TODO: this might not be right. calculate based on increment?
      lastTime.hour(lastTime.hour() + numExtraRows);

      // Rows are by the hour
      for (var time = firstTime.hour(); time <= lastTime.hour(); time++) {
        rowTimes.push(time);
      }

      var guide = self.get('selectedGuide');

      if (isPresent(guide)) {
        var timeSlots = self.get('timeSlots').filterBy('guide.id', self.get('selectedGuide.id'));

        // The availability time slots for this guide
        var availableMondays = timeSlots.filterBy('day', 'Monday');
        var availableTuesdays = timeSlots.filterBy('day', 'Tuesday');
        var availableWednesdays = timeSlots.filterBy('day', 'Wednesday');
        var availableThursdays = timeSlots.filterBy('day', 'Thursday');
        var availableFridays = timeSlots.filterBy('day', 'Friday');
        var availableSaturdays = timeSlots.filterBy('day', 'Saturday');
        var availableSundays = timeSlots.filterBy('day', 'Sunday');

        // The Bookings for the guide
        var bookings = self.get('bookings').filterBy('guide.id', guide.get('id'));

        // Bookings by day of the selected week
        var mondayBookings = bookings.filterBy('day', 'Monday');
        var tuesdayBookings = bookings.filterBy('day', 'Tuesday');
        var wednesdayBookings = bookings.filterBy('day', 'Wednesday');
        var thursdayBookings = bookings.filterBy('day', 'Thursday');
        var fridayBookings = bookings.filterBy('day', 'Friday');
        var saturdayBookings = bookings.filterBy('day', 'Saturday');
        var sundayBookings = bookings.filterBy('day', 'Sunday');

        // current Booking
        var currentBooking = this.get('model');

        // create each row based on start time
        rowTimes.forEach(function (rowTime) {
          var row = {
            monday: self.get('gridService').createGridSlots('Monday', rowTime, mondayBookings, availableMondays, self.get('selectedWeekStart'), self.get('selectedGuide'), currentBooking),
            tuesday: self.get('gridService').createGridSlots('Tuesday', rowTime, tuesdayBookings, availableTuesdays, self.get('selectedWeekStart'), self.get('selectedGuide'), currentBooking),
            wednesday: self.get('gridService').createGridSlots('Wednesday', rowTime, wednesdayBookings, availableWednesdays, self.get('selectedWeekStart'), self.get('selectedGuide'), currentBooking),
            thursday: self.get('gridService').createGridSlots('Thursday', rowTime, thursdayBookings, availableThursdays, self.get('selectedWeekStart'), self.get('selectedGuide'), currentBooking),
            friday: self.get('gridService').createGridSlots('Friday', rowTime, fridayBookings, availableFridays, self.get('selectedWeekStart'), self.get('selectedGuide'), currentBooking),
            saturday: self.get('gridService').createGridSlots('Saturday', rowTime, saturdayBookings, availableSaturdays, self.get('selectedWeekStart'), self.get('selectedGuide'), currentBooking),
            sunday: self.get('gridService').createGridSlots('Sunday', rowTime, sundayBookings, availableSundays, self.get('selectedWeekStart'), self.get('selectedGuide'), currentBooking)
          };

          // Update slots and slot selections
          var newSlots = [].concat(_toConsumableArray(row.monday), _toConsumableArray(row.tuesday), _toConsumableArray(row.wednesday), _toConsumableArray(row.thursday), _toConsumableArray(row.friday), _toConsumableArray(row.saturday), _toConsumableArray(row.sunday));
          self.get('allSlots').addObjects(newSlots);

          // Check if slot has been selected in a previous viewing of this week.
          // If it was selected then the new slot being created here should be
          // selected and the old removed from the tracking array selectedSlots
          if (!isEmpty(self.get('selectedSlots'))) {
            newSlots.forEach(function (slot) {
              self.set('selectedSlots', self.get('gridService').updateSlotSelections(slot, self.get('selectedSlots')));
            });
          }

          rows.push(row);
        });

        return rows;
      }
    }),

    reset: function reset() {
      this.clearSlotSelections();
      this.set('_joinedLearningSessionBookingId', null);
      this.set('allSlots', []);
      this.set('bookingCandidate', null);
      this.set('joinableBookings', []);
      this.set('lastSelectedDay', null);
      this.set('selectedStatus', null);
      this.set('selectedGuide', null);
      this.set('selectedWeekStartValue', null);
      this.set('showConflictedReservations', false);
      this.set('showJoinableBookings', false);
    },

    clearSlotSelections: function clearSlotSelections() {
      this.get('selectedSlots').forEach(function (slot) {
        slot.set('selected', false);
      });
      this.set('selectedSlots', []);
    },

    actions: {
      toggleBookingSelection: function toggleBookingSelection(slot) {
        // clear selection on first new selection
        if (this.get('lastSelectedDay') === null) {
          this.clearSlotSelections();
          // clear selection if we're clicking a different day
        } else if (this.get('lastSelectedDay') !== slot.get('day')) {
          this.clearSlotSelections();
        }
        this.set('lastSelectedDay', slot.get('day'));

        // There can only be one series of slots selected so if we've moved to a
        // new week view then clear the existing slot selections
        var lastSelection = this.get('selectedSlots.firstObject');
        if (isPresent(lastSelection) && !(0, _moment.default)(lastSelection.get('startTime')).isSame(this.get('selectedWeekStart'), 'week')) {
          this.set('selectedSlots', []);
        }

        if (slot.get('selected')) {
          slot.set('selected', false);
          this.get('selectedSlots').removeObject(slot);
        } else {
          slot.set('selected', true);
          this.get('selectedSlots').addObject(slot);
        }
      },

      nextWeek: function nextWeek() {
        this.set('selectedWeekStart', (0, _moment.default)(this.get('selectedWeekStart')).add(7, 'days'));
      },

      previousWeek: function previousWeek() {
        this.set('selectedWeekStart', (0, _moment.default)(this.get('selectedWeekStart')).subtract(7, 'days'));
      },

      // Step 1
      updateBooking: function updateBooking(booking) {
        var self = this;
        var slots = self.get('selectedSlots').sortBy('startTime');

        // If Booking hasn't been changed the Booking model wont' be dirty from changing status, the Guide will be the same,
        // and the selected slots will map fully onto the Booking time and duration
        var sameSlots = isPresent(slots) ? _bookingHelper.default.slotsMatchBooking(slots, booking) : true;
        var sameGuide = booking.get('guide.id') === self.get('selectedGuide.id');
        var sameStatus = booking.get('status') === self.get('selectedStatus');
        if (!booking.get('isDirty') && sameSlots && sameGuide && sameStatus) {
          self.get('flashService').error("You haven't modified this Booking. Nothing to update.");
          return;
        }
        var duration = booking.get('duration');
        var startTime = booking.get('startTime');

        if (isPresent(slots)) {
          // Ensure all slots from same day
          var day = null;
          var error = false;
          slots.forEach(function (slot) {
            if (isPresent(day)) {
              if (day !== slot.get('day')) {
                error = true;
              }
            } else {
              day = slot.get('day');
            }
          });
          if (error) {
            self.get('flashService').error('You can only make a Booking for a single day.');
            return;
          }

          // Ensure the slots are contiguous
          var lastSlot = null;
          slots.forEach(function (slot) {
            if (isPresent(lastSlot)) {
              if (!(0, _moment.default)(lastSlot.get('endTime')).isSame((0, _moment.default)(slot.get('startTime')), 'minute')) {
                error = true;
              }
            }
            lastSlot = slot;
          });
          if (error) {
            self.get('flashService').error('A Booking must be for a series of slots.');
            return;
          }

          // Determine the Booking Candidate duration
          duration = 0;
          slots.forEach(function (slot) {
            duration = duration + slot.get('duration');
          });

          // Get the startTime
          startTime = slots.get('firstObject.startTime');
        }

        // Create the Booking Candidate
        // - the candidate is used to store data we will apply to the existing
        //   booking when it is time to update it.
        // - the relations are all PromiseObjects
        //   - we COULD resolve them before setting self with 'bookingCandidate'
        //     and leaving this function. We don't have to at this point as
        //     everything before saveBooking simply relies on fetching the id of
        //     relations, which is accessible without resolving or going through model.get('content')

        var bookingCandidate = {
          student: self.get('student'),
          guide: self.get('selectedGuide'),
          startTime: startTime,
          duration: duration,
          status: self.get('selectedStatus')
        };

        self.set('bookingCandidate', bookingCandidate);
        self.send('confirmBookingDuration');
      },

      // Step 2
      // Booking should be default duration unless override via modal
      confirmBookingDuration: function confirmBookingDuration() {
        var self = this;

        var standardDuration = self.get('academyService.academy.schedule.bookingDuration');

        if (this.get('bookingCandidate.duration') !== standardDuration) {
          var message = htmlSafe('<p>The standard Booking duration is ' + standardDuration + ' minutes.</p><p>Would you like to proceed with your selection of ' + this.get('bookingCandidate.duration') + ' mintues?</p>');

          get(this, 'modalsManager').confirm({ title: 'Please confirm booking duration', body: message }).then(function () {
            debug('Non standard Booking duration accepted');
            self.send('confirmBookingAvailability'); // next step
          }).catch(function () {
            debug('Booking submit cancelled on duration check step.');
            return;
          });
        } else {
          self.send('confirmBookingAvailability'); // next step
        }
      },

      // Step 3
      // All of the selected slots must be part of the Guides availability matrix
      // or the booking will have to be confirmed.
      confirmBookingAvailability: function confirmBookingAvailability() {
        var self = this;
        var message = htmlSafe('<p>At least one of the slots you have selected is not part of the Guides availabilities.</p><p>Are you sure you want to include these slots in your booking?</p>');

        var slots = self.get('selectedSlots');
        var allAvailable = true;
        for (var i = 0; i < slots.length; i++) {
          if (!slots[i].get('available')) {
            allAvailable = false;
          }
        }
        if (allAvailable) {
          self.send('confirmReservationOverride');
        } else {
          get(this, 'modalsManager').confirm({
            title: 'Please confirm outside availability selection',
            body: message
          }).then(function () {
            debug('Guide availability override accepted');
            // IMPROVE:
            // We have chains of modals, some via the modalsManager and some via the template.
            // We could create modalsManager custom modals to make is such that they all open via the manager.

            // after this modal has closed
            self.send('confirmReservationOverride'); // next step
          }).catch(function () {
            debug('Booking submit cancelled on Guide availability check step.');
            return;
          });
        }
      },

      // Step 4
      // The existing Booking may already have had conflicts
      // - if this is just a duration change we need to preserve the conflicts
      // - if the old conflicts are no longer relevant they need to be deleted
      // We show a list of the reservations (booker or booked) as part of
      // confirmation.
      confirmReservationOverride: function confirmReservationOverride() {
        var self = this;

        // NOTE:
        // There is some logic difference here from new.js. One or the other
        // may be more correct / efficient.

        // Find any reserved slots the updated Booking will cover
        var slotReservations = [];
        var slots = self.get('selectedSlots');
        slots.forEach(function (slot) {
          if (slot.get('reserved') && slot.get('reservation.student.id') !== self.get('bookingCandidate.student.id')) {
            slotReservations.addObject(slot.get('reservation'));
          }
        });

        // We're deleting all of the old booker reservation conflicts later before adding the new ones
        var bookerReservationConflicts = self.get('model.bookerReservationConflicts');

        // FIXME: V1:
        // Verify this works. PromiseObject mayhem may be at work  and it's
        // always returning the reservations.
        var newlyConflictedReservations = slotReservations.filter(function (reservation) {
          var reservations = bookerReservationConflicts.map(function (conflict) {
            return conflict.get('bookedOver');
          });
          return !reservations.contains(reservation);
        });

        if (isEmpty(newlyConflictedReservations)) {
          return self.send('handleMultipleBookingSelection'); // nothing else to do
        }

        // Create conflict records for any new conflicts. Will be booking over reservations only.
        var newBookerReservationConflicts = [];
        newlyConflictedReservations.forEach(function (reservation) {
          var conflictRecord = self.store.createRecord('booking-over-reservation-conflict', {
            status: _constants.default.scheduleConflict.status.UNRESOLVED,
            bookedOver: reservation,
            booker: self.get('model')
          });
          newBookerReservationConflicts.addObject(conflictRecord.save());
        });

        // Trigger the modal
        // - Show conflicts in bookingCandidate.newBookerReservationConflicts
        all(newBookerReservationConflicts).then(function (results) {
          self.set('bookingCandidate._newBookerReservationConflicts', results);
          self.set('showConflictedReservations', true);
        }).catch(function (error) {
          console.error(error);
          self.get('flashService').error(error);
        });
      },

      // Step 4.1
      acceptBookingOverReservation: function acceptBookingOverReservation(conflict) {
        conflict.set('status', _constants.default.scheduleConflict.status.RESOLVED);
        // If this was the last conflict to be resolved then move on to next Step
        var proceed = this.get('bookingCandidate._newBookerReservationConflicts').reduce(function (previousValue, item) {
          return previousValue === false ? previousValue : item.get('status') === _constants.default.scheduleConflict.status.RESOLVED;
        }, true);
        if (proceed) {
          this.send('handleMultipleBookingSelection');
          this.set('showConflictedReservations', false);
        }
      },

      // Step 5
      // TODO: maybe a better interface would be that as you select slots the options
      //       for what you can do show up on screen and then you just select the
      //       options you want, instead of going through as series of modals.
      //       also lets you see quickly the implications of choosing slots.
      //
      // Cases here are
      //   1. Don't want to join the Bookings / Learning Sessions and have multiple distinct
      //      Bookings for the slots
      //      - default if you don't join, cancel, or update existing
      //   2. Want to join one of many possible Bookings / Learning Sessions.
      //      If the updated span covers more than one sequential Booking we don't allow
      //      joining both (all) as joining means being part of the same learning session.
      //      One Booking can be joined.
      //      The same thing can be accomplished by creating x Bookings and joing the x Bookings individually.
      //      - yes / no -> booking options to join learning session for one of the Bookings
      //      - need a wormhole to display options
      //      - Booking time updated to match the Booking of the learning session being joined
      //   3. One of the Bookings already belongs to the Student and they want to
      //      change the duration. Duration amount has already been accepted by this point.
      //      - if this is the case then jump straight to save
      //      - if you were intending to join a Booking the duration would be the
      //        same as the Booking wanting to be joined.
      //      - option to join a Booking of same duration won't be provided because
      //        the Booking will appear to be unchanged and will trigger return in
      //        updateBooking call.
      //      - TODO: a different UI for Booking edits or checking if there is a Booking to
      //        join in the updateBooking call is probably in order. The fallback is
      //        to delete and re-create the Booking, where joining will be an option
      //        as there is no pre-existing Booking.
      //   4. Want to cancel the Booking edit
      //
      //   - Find set of Bookings for selected slots
      //     - If one of them is own Booking then skip to Save as time has been adjusted
      //     - If none are own ask if want to join one of the Bookings
      //  - If no existing Bookings then skip to Save
      handleMultipleBookingSelection: function handleMultipleBookingSelection() {
        var self = this;

        var slotsWithBookings = this.get('selectedSlots').filter(function (slot) {
          return isPresent(slot.get('bookings'));
        });

        if (isPresent(slotsWithBookings)) {
          this.set('joinableBookings', []);
          slotsWithBookings.forEach(function (slot) {
            slot.get('bookings').forEach(function (booking) {
              if (booking.get('student.id') !== self.get('bookingCandidate.student.id')) {
                self.get('joinableBookings').addObject(booking);
              }
            });
          });

          if (isEmpty(self.get('joinableBookings'))) {
            return this.send('saveBooking'); // nothing else to do
          }

          // Trigger modal to appear
          this.set('showJoinableBookings', true);
        } else {
          this.send('saveBooking');
        }
      },

      // Step 5.1
      // Join an existing Bookings Learning Session
      acceptJoinExistingLearningSession: function acceptJoinExistingLearningSession(existingBooking) {
        debug('Joining an existing Learning Session');
        this.set('showJoinableBookings', false);
        this.set('_joinedLearningSessionBookingId', existingBooking.get('id'));

        this.set('bookingCandidate.learningSession', existingBooking.get('learningSession'));
        this.set('bookingCandidate.startTime', existingBooking.get('startTime'));
        this.set('bookingCandidate.duration', existingBooking.get('duration'));

        this.send('saveBooking'); // next step
      },

      // Step 6
      // We're updating a booking. Things that could be happening include:
      // 1. we change the guide
      //    - this is essentially creating a new booking so we delete the old and create a new one
      // 2. the start time and duration may have been updated
      // 3. we've joined an existing learning session which means
      //    - the bookingCandidate times will have been udpated with the times from the Booking whose
      //      Learning Session is being joined
      //    - we delete our old learning session if no other students are part of it
      //    - makes no difference if its a new booking (guide changed) or not
      saveBooking: function saveBooking() {
        var self = this;
        var booking = self.get('model');
        var candidate = self.get('bookingCandidate');

        self.send('showLoadingSpinner');

        // Update booking with bookingCandidate properties
        booking.set('startTime', candidate.startTime);
        booking.set('duration', candidate.duration);
        booking.set('updatedOn', new Date());
        booking.set('status', candidate.status);

        // NOTE: a perhaps better way to handle these functions would be to
        //       make them external to the controller as we can pass in whatever
        //       we need at this point (unlike when actions are called)
        var updateGuide = function () {
          // If the guide has changed we need to update the old guide as well
          if (booking.get('guide.id') !== candidate.guide.get('id')) {
            var promises = [];
            // Remove this Booking from the old Guide
            promises.push(booking.get('guide').then(function (guide) {
              return guide.get('bookings').then(function (bookings) {
                bookings.removeObject(booking);
                return guide.save();
              });
            }));
            // Set the new Guide
            promises.push(all([candidate.guide]).then(function (results) {
              var guide = results[0];
              booking.set('guide', guide);
              return booking.save().then(function () {
                return guide.save();
              });
            }));
            return all(promises);
          } else {
            return resolve();
          }
        }.bind(this);

        var updateBookerReservationConflicts = function () {
          var self = this;

          // Destroy the set of bookerReservation conflicts that are not in the newly created set.
          var newConflicts = self.get('bookingCandidate._newBookerReservationConflicts');
          if (isEmpty(newConflicts)) {
            return resolve();
          }

          var conflictsToDestroy = [];
          return booking.get('bookerReservationConflicts').then(function (oldConflicts) {
            conflictsToDestroy = oldConflicts.filter(function (conflict) {
              return !newConflicts.contains(conflict);
            });
            return _bookingHelper.default.destroyConflicts(conflictsToDestroy).then(function () {
              // Update the new reservation conflicts and add them to the Booking
              var conflictPromises = [];
              conflictPromises.addObjects(self.get('bookingCandidate._newBookerReservationConflicts').map(function (conflict) {
                return conflict.save();
              }));

              return all(conflictPromises).then(function (conflicts) {
                // Saving the Bookings / Reservations in the conflict adds the
                // hasMany side of the conflict to them
                var promises = [];
                conflicts.forEach(function (conflict) {
                  promises.push(conflict.get('booker').then(function (booker) {
                    return booker.save();
                  }));
                  promises.push(conflict.get('bookedOver').then(function (bookedOver) {
                    return bookedOver.save();
                  }));
                });
                return all(promises);
              });
            });
          });
        }.bind(this);

        // Determine which existing bookedOver (reservation booked over this booking)
        // conflicts need to be deleted because they no longer apply
        var updateBookedOverReservationConflicts = function () {
          return booking.get('bookedOverReservationConflicts').then(function (conflicts) {
            var conflictsToDestroy = [];
            var promises = [];
            conflicts.forEach(function (conflict) {
              promises.push(conflict.get('booker').then(function (booker) {
                // If the booker time doesn't fall within the booking then delete it
                if (!_bookingHelper.default.doOverlap(booker, booking)) {
                  conflictsToDestroy.addObject(conflict);
                }
              }));
            });
            return all(promises).then(function () {
              return _bookingHelper.default.destroyConflicts(conflictsToDestroy);
            });
          });
        }.bind(this);

        // Handle booking over booking conflicts
        // - Create booking over booking conflicts for any new cases
        //   - This includes the case where we joined a learning sessions booking.
        //     A joined Bookings conflict should be set to resolved.
        // - Delete any conflicts that are no longer relevant
        var updateBookingConflicts = function () {
          var self = this;
          return _bookingHelper.default.getBookingConflicts(booking, this.store.findAll('booking')).then(function (conflictedBookings) {
            var preserveId = self.get('_joinedLearningSessionBookingId');
            var existingConflicts = [];
            var promises = [];
            promises.push(booking.get('bookerBookingConflicts').then(function (conflicts) {
              existingConflicts.addObjects(conflicts);
            }));
            promises.push(booking.get('bookedOverBookingConflicts').then(function (conflicts) {
              existingConflicts.addObjects(conflicts);
            }));
            return all(promises).then(function () {
              var conflictsToDelete = [];
              var promises = [];

              // Existing booking over booking conflicts should be checked for validity
              // against the booking being edited.
              // If the conflict doesn't have a booker.id or bookedOver.id that matches
              // one of the returned conflictedBookings then it's not valid and should be
              // deleted.
              existingConflicts.forEach(function (conflict) {
                var hasMatch = false;
                conflictedBookings.forEach(function (conflictedBooking) {
                  if (conflictedBooking.get('id') === conflict.get('booker.id') || conflictedBooking.get('id') === conflict.get('bookedOver.id')) {
                    hasMatch = true;
                  }
                });
                if (!hasMatch) {
                  conflictsToDelete.addObject(conflict);
                }
              });

              // Any conflictingBookings that are not in the existing set are new
              // and should be created.
              // If a new one matches the preserveId then it should have it's status
              // set to resolved, otherwise to unresolved.
              conflictedBookings.forEach(function (conflictedBooking) {
                var hasMatch = false;
                existingConflicts.forEach(function (conflict) {
                  if (conflictedBooking.get('id') === conflict.get('booker.id') || conflictedBooking.get('id') === conflict.get('bookedOver.id')) {
                    hasMatch = true;
                  }
                });
                if (!hasMatch) {
                  // create a new one
                  var status = _constants.default.scheduleConflict.status.UNRESOLVED;
                  if (isPresent(preserveId) && conflictedBooking.get('id') === preserveId) {
                    status = _constants.default.scheduleConflict.status.RESOLVED;
                  }
                  promises.push(self.store.createRecord('booking-over-booking-conflict', {
                    booker: booking,
                    bookedOver: conflictedBooking,
                    status: status
                  }).save());
                }
              });
              promises.push(_bookingHelper.default.destroyConflicts(conflictsToDelete));
              return all(promises);
            });
          });
        }.bind(this);

        // Case we've joined an existing learning session
        var updateLearningSession = function () {
          if (isPresent(candidate.learningSession)) {
            return _bookingHelper.default.safeDestroyLearningSession(booking).then(function () {
              booking.set('learningSession', candidate.learningSession);

              return booking.get('learningSession').then(function (theLearningSession) {
                return theLearningSession.get('students').then(function (theStudents) {
                  if (isEmpty(theStudents)) {
                    self.get('flashService').error('Learning Session had no students.');
                    return resolve(); // This should never be the case. A learning session will always have students or be deleted.
                  }
                  return booking.get('student').then(function (theStudent) {
                    theStudents.addObject(theStudent);
                    return theLearningSession.get('bookings').then(function (theBookings) {
                      theBookings.addObject(booking);
                      return theLearningSession.save();
                    });
                  });
                });
              });
            });
          } else {
            return resolve();
          }
        }.bind(this);

        var sendNotifications = function () {
          // TODO: might want to send guide directly to item they can edit and
          //       student/guardian to a view only page...
          var recipients = [{ model: 'student', id: booking.get('student.id') }, { model: 'guide', id: booking.get('guide.id') }];
          return all([booking.get('student.guardians'), booking.get('student'), booking.get('guide')]).then(function (results) {
            var guardians = results[0];
            var student = results[1];
            var guide = results[2];
            guardians.forEach(function (guardian) {
              recipients.addObject({ model: 'guardian', id: guardian.get('id') });
            });
            return self.get('notifyService').sendNotification(recipients, 'The booking for ' + student.get('fullName') + ' with ' + guide.get('fullName') + ' on ' + booking.get('displayDate') + ' has been updated.', { route: 'authenticated.bookings' });
          });
        }.bind(this);

        // Think this may be redundant at this point.
        var saveUpdatedBooking = function () {
          var promises = [];
          promises.push(booking.get('student').then(function (theStudent) {
            return theStudent.save();
          }));
          promises.push(booking.get('guide').then(function (theGuide) {
            return theGuide.save();
          }));
          promises.push(booking.save());
          return all(promises);
        }.bind(this);

        var cleanUp = function () {
          return self.reset();
        }.bind(this);

        // Error handler for the Promise chain. All errors trickle down to this
        // handler if there are no rejection handlers present before it
        // NOTE: This doesn't appear to work well. The stack appears to come from
        //       here instead of where the error originates.
        var displayAndReportError = function (error) {
          console.error(error);
          if (error.message) {
            error = error.message;
          }
          this.get('flashService').error(error);
        }.bind(this);

        var returnToBookings = function () {
          self.send('hideLoadingSpinner');
          self.transitionToRoute('authenticated.admin.bookings');
        }.bind(this);

        resolve().then(updateGuide).then(updateBookerReservationConflicts).then(updateBookedOverReservationConflicts).then(updateBookingConflicts).then(updateLearningSession).then(saveUpdatedBooking).then(sendNotifications).then(cleanUp).then(null, displayAndReportError).then(returnToBookings);
      },

      // The next submit clears bookingCandidate
      cancelJoinExistingBooking: function cancelJoinExistingBooking() {
        this.set('showJoinableBookings', false);
      },

      cancelBookingReservation: function cancelBookingReservation() {
        this.set('showConflictedReservations', false);
        this.get('bookingCandidate._newBookerReservationConflicts').forEach(function (conflict) {
          var booker = conflict.get('booker');
          var bookedOver = conflict.get('bookedOver');
          conflict.destroyRecord().then(function () {
            // These would only work if the PromiseObjects have been resolved. Not guaranteed at this point.
            // booker.get('content').save();
            // bookedOver.get('content').save();
            booker.then(function (theBooker) {
              theBooker.save();
            });
            bookedOver.then(function (theBookedOver) {
              theBookedOver.save();
            });
          });
        });
        this.get('bookingCandidate._newBookerReservationConflicts').clear();
      }
    }
  });
});