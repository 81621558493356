define('supr-web-ember-v2/routes/authenticated/admin/reservations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var all = Ember.RSVP.all;
  exports.default = Route.extend({
    model: function model() {
      return this.store.findAll('reservation');
    },
    afterModel: function afterModel(model) {
      // Preload the bookings so we can decide when to show the modal on reservation cancel
      return all(model.map(function (reservation) {
        return reservation.get('bookings');
      }));
    }
  });
});