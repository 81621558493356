define('supr-web-ember-v2/utils/booking-helper', ['exports', 'supr-web-ember-v2/utils/time-helper', 'supr-web-ember-v2/config/constants', 'moment', 'lodash'], function (exports, _timeHelper, _constants, _moment, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var debug = Ember.debug;
  var isEmpty = Ember.isEmpty;
  var isPresent = Ember.isPresent;
  var all = Ember.RSVP.all;
  var resolve = Ember.RSVP.resolve;
  var reject = Ember.RSVP.reject;
  var Promise = Ember.RSVP.Promise;
  exports.default = {
    // Returns the set of Bookings that a Booking has conflicts with.
    // A Booking has a conflict with another Booking if
    // - it is not for the same Student AND
    // - it is with the same Guide AND
    // - it is on the same day AND
    // - it's start time or end time is within the other Booking
    getBookingConflicts: function getBookingConflicts(booking, bookings) {
      return bookings.then(function (theBookings) {
        var bookingStart = (0, _moment.default)(booking.get('startTime'));
        var bookingEnd = (0, _moment.default)(booking.get('endTime'));
        var conflicts = [];
        // only care about active (pending and confirmed) Bookings
        var filteredBookings = theBookings.filter(function (bk) {
          return booking.get('student.id') !== bk.get('student.id') && booking.get('guide.id') === bk.get('guide.id') && bk.get('isActive');
        });
        filteredBookings.forEach(function (bk) {
          var bkStart = (0, _moment.default)(bk.get('startTime'));
          var bkEnd = (0, _moment.default)(bk.get('endTime'));
          if (bookingStart.isBetween(bkStart, bkEnd, null, '[)')) {
            // Think it should be exclusive on end
            conflicts.addObject(bk);
          }
          if (bookingEnd.isBetween(bkStart, bkEnd, null, '(]')) {
            // Think it should be exclusive on start
            conflicts.addObject(bk);
          }
        });
        debug('-- booking has ' + conflicts.length + ' booking conflicts ');
        return conflicts;
      });
    },

    // Return the reservations a Booking has a conflict with.
    //
    // At present a reservation can only be held by one Student.
    // It may be desireable to let two students have a reservation for the same
    // slot, say for sibiling.
    // Make sure a reservation does not conflict with a Booking for the same student
    getReservationConflict: function getReservationConflict(booking, reservations) {
      return reservations.then(function (theReservations) {
        var conflicts = [];
        // Booking potentially conflicts with a reservation if it's the same guide and for a different student
        var filteredReservations = theReservations.filter(function (reservation) {
          return booking.get('student.id') !== reservation.get('student.id') && booking.get('guide.id') === reservation.get('guide.id');
        });
        // A conflict occurs if the Booking day is the same as the reservation and
        // the Booking time overlaps the Reservation time
        filteredReservations.forEach(function (reservation) {
          // Must be same day to be a conflict
          if (booking.get('day') !== _moment.default.weekdays()[(0, _moment.default)(reservation.get('startTime')).day()]) {
            return;
          }
          if (_timeHelper.default.isBetweenTimes(booking.get('startTime'), reservation.get('startTime'), reservation.get('duration'), '[)')) {
            conflicts.addObject(reservation);
          }
          if (_timeHelper.default.isBetweenTimes(booking.get('endTime'), reservation.get('startTime'), reservation.get('duration'), '(]')) {
            conflicts.addObject(reservation);
          }
        });
        if (isEmpty(conflicts)) {
          return conflicts;
        }
        if (conflicts.get('length') > 1) {
          return reject('Should only be 1 reservation that conflicts with a Booking but found ' + conflicts.get('length'));
        }
        debug('-- booking has ' + conflicts.length + ' reservation conflicts');
        return conflicts[0]; // should only be one conflict
      });
    },

    slotBookings: function slotBookings(weekStart, slotTime, allBookings) {
      var time = (0, _moment.default)(slotTime);
      var checkSlotTime = (0, _moment.default)(weekStart).day(time.day()).hour(time.hour()).minutes(time.minutes());
      var bookings = [];

      allBookings.forEach(function (booking) {
        if (!booking.get('isActive')) {
          return;
        }
        var start = (0, _moment.default)(booking.get('startTime'));
        var end = (0, _moment.default)(booking.get('endTime'));

        if (checkSlotTime.isSame(start, 'minute') || checkSlotTime.isBetween(start, end, 'minute')) {
          bookings.addObject(booking);
        }
      });
      return bookings;
    },

    getSlotReservation: function getSlotReservation(slot, reservations) {
      var slotDay = slot.get('day');
      if (isPresent(reservations)) {
        for (var i = 0; i < reservations.get('length'); i++) {
          var reservation = reservations.objectAt(i);
          if (slotDay === reservation.get('day')) {
            if (_timeHelper.default.isSameTime(slot.get('startTime'), reservation.get('startTime')) || _timeHelper.default.isBetweenTimes(slot.get('startTime'), reservation.get('startTime'), reservation.get('duration'))) {
              return reservation;
            }
          }
        }
      }
      return null;
    },

    // FIXME: V1:
    // This should call safeDestroyLearningSession
    /**
     * Destroy the Booking but only destroy its associated Learning Session if no
     * other Students are associated with it.
     */
    safeDestroyBooking: function safeDestroyBooking(theBooking) {
      var self = this;
      // If the associated Learning Session had other students then remove the
      // student from it but don't destroy it.
      var oldLearningSession = theBooking.get('learningSession');
      var students = theBooking.get('learningSession.students');
      var bookings = theBooking.get('learningSession.bookings');
      var guide = theBooking.get('guide');
      // FIXME:
      // Presumably we've missed something here that guideBookings are unused.
      // That is unless they're not necessary to handle/remove the relationship.
      // Probably don't need this as we don't remove the Booking from the Student
      // either. The destroyRecord call must do it for student, guide, and
      // reservation. TODO Check that its removed from all 3.
      var guideBookings = theBooking.get('guide.bookings');
      var student = theBooking.get('student');
      var promises = [oldLearningSession, students, bookings, guide, student];

      return all(promises).then(function (results) {
        oldLearningSession = results[0];
        students = results[1];
        bookings = results[2];
        guide = results[3];
        student = results[4];

        var studentIsOnlyStudentInLearningSession = false;
        if (!isEmpty(students)) {
          if (students.get('length') === 1 && students.get('firstObject.id') === student.get('id')) {
            studentIsOnlyStudentInLearningSession = true;
          }
        }

        var promises = [];
        if (!isEmpty(students) && !studentIsOnlyStudentInLearningSession) {
          students.removeObject(student);
          bookings.removeObject(theBooking);
          promises.push(oldLearningSession.save());
        } else {
          student.get('learningSessions').removeObject(oldLearningSession);
          guide.get('learningSessions').removeObject(oldLearningSession);
          promises.push(student.save());
          promises.push(guide.save());
          promises.push(oldLearningSession.destroyRecord()); // no students, we can destroy the record
        }
        promises.push(self.destroyConflicts(theBooking.get('conflicts')));

        return theBooking.save().then(function () {
          return all(promises);
        }).then(function () {
          return theBooking.destroyRecord();
        });
      });
    },

    safeDestroyLearningSession: function safeDestroyLearningSession(theBooking, reloadRelations) {
      return new Promise(function (resolve) {
        var oldLearningSession = theBooking.get('learningSession');
        var students = theBooking.get('learningSession.students');
        var guide = theBooking.get('guide');
        var student = theBooking.get('student');
        var promises = [oldLearningSession, students, guide, student];

        all(promises).then(function (results) {
          var oldLearningSession = results[0];
          var students = results[1];
          var guide = results[2]; // NOTE: necessary or removed when Booking deleted?
          var student = results[3]; // NOTE: necessary or removed when Booking deleted?

          // NOTE:
          // We Used to treat this condition as an error but the intent is to throw any
          // Booking in here and safely destroy the learning session, if one exists.
          // This is the wrong place to enforce/test that a Booking has a LearningSession
          if (isEmpty(oldLearningSession)) {
            console.log('Booking had no Learning Session');
            return resolve(theBooking);
          }

          var studentIsOnlyStudentInLearningSession = false;
          if (!isEmpty(students)) {
            if (students.get('length') === 1 && students.get('firstObject.id') === student.get('id')) {
              studentIsOnlyStudentInLearningSession = true;
            }
          }

          var promises = [];
          if (!isEmpty(students) && !studentIsOnlyStudentInLearningSession) {
            students.removeObject(student);
            oldLearningSession.get('bookings').removeObject(theBooking);
            promises.push(oldLearningSession.save());
            theBooking.set('learningSession', null);
            promises.push(theBooking.save());
            return all(promises).then(function () {
              if (reloadRelations) {
                theBooking.loadRelations().then(function (updatedBooking) {
                  return resolve(updatedBooking);
                });
              } else {
                return resolve(theBooking);
              }
            });
          } else {
            student.get('learningSessions').removeObject(oldLearningSession);
            promises.push(student.save()); // NOTE: necessary or removed when session deleted?
            guide.get('learningSessions').removeObject(oldLearningSession);
            promises.push(guide.save()); // NOTE: necessary or removed when session deleted?
            theBooking.set('learningSession', null);
            promises.push(theBooking.save());
            promises.push(oldLearningSession.destroyRecord()); // no students, we can destroy the record
            all(promises).then(function () {
              if (reloadRelations) {
                theBooking.loadRelations().then(function (updatedBooking) {
                  return resolve(updatedBooking);
                });
              } else {
                return resolve(theBooking);
              }
            });
          }
        });
      });
    },

    // Should also safe destroy Bookings matching the options
    // - options {pending: true, confirmed: true}
    safeDestroyReservation: function safeDestroyReservation(reservation, options) {
      var self = this;
      return reservation.get('bookings').then(function (theBookings) {
        var promises = [];
        var deletions = [];
        theBookings = self._filterDuplicateRecords(theBookings); // HACK:
        theBookings.forEach(function (booking) {
          if (options && booking.get('status') === _constants.default.booking.status.PENDING && options.pending || booking.get('status') === _constants.default.booking.status.CONFIRMED && options.confirmed) {
            deletions.pushObject(self.safeDestroyBooking(booking));
          } else {
            booking.set('reservation', null);
            promises.push(booking.save());
          }
        });
        promises.push.apply(promises, deletions);
        promises.push(self.destroyConflicts(reservation.get('conflicts')));

        // Ensures all bookings are deleted before the reservation
        return all(promises).then(function () {
          return reservation.destroyRecord(); // student and guide relationships automatically updated
        });
      });
    },

    slotsMatchBooking: function slotsMatchBooking(slots, booking) {
      // one slot must match the booking start time and every slot must be within the booking range
      var hasStartMatch = false;
      var hasRangeMatch = true;
      var hasDurationMatch = false;

      var durationToCheck = 0;
      slots.forEach(function (slot) {
        durationToCheck = durationToCheck + slot.get('duration');

        if ((0, _moment.default)(slot.get('startTime')).isSame((0, _moment.default)(booking.get('startTime')), 'minute')) {
          hasStartMatch = true;
        }

        if (!_timeHelper.default.isBetweenTimes(slot.get('startTime'), booking.get('startTime'), booking.get('duration')) && !_timeHelper.default.isBetweenTimes(slot.get('endTime'), booking.get('startTime'), booking.get('duration'))) {
          hasRangeMatch = false;
        }
      });

      if (durationToCheck === booking.get('duration')) {
        hasDurationMatch = true;
      }

      return hasStartMatch && hasRangeMatch && hasDurationMatch;
    },

    // Each conflict has a booker, and bookedOver
    // Conflicts are only attached to the Booking and Reservation models so we
    // don't need to update the students or guides at all.
    // FIXME: V1:
    // when there are more than 2 conflicts one of them will end up
    // undefined in the template by the time an attempt to destroy it is made.
    // issue has to do with childview in:
    //
    // arrayWillChange: function(content, start, removedCount) {
    //   // If the contents were empty before and this template collection has an
    //   // empty view remove it now.
    //   var emptyView = get(this, 'emptyView');
    //   if (emptyView && emptyView instanceof View) {
    //     emptyView.removeFromParent();
    //   }
    //
    //   // Loop through child views that correspond with the removed items.
    //   // Note that we loop from the end of the array to the beginning because
    //   // we are mutating it as we go.
    //   var childViews = this._childViews;
    //   var childView, idx;
    //
    //   for (idx = start + removedCount - 1; idx >= start; idx--) {
    //     childView = childViews[idx];
    //     childView.destroy();
    //   }
    // },
    destroyConflicts: function destroyConflicts(conflicts) {
      if (isEmpty(conflicts)) {
        return resolve();
      }
      // By calling RSVP.all it doesn't matter if what we pass in is a PromiseArray
      // or not, items that are not promises are passed through as is.
      var parents = [];
      return all(conflicts).then(function (theConflicts) {
        var deletions = theConflicts.map(function (conflict) {
          parents.addObject(conflict.get('booker'));
          parents.addObject(conflict.get('bookedOver'));
          return conflict.destroyRecord();
        });
        return all(deletions);
      }).then(function () {
        var updates = parents.map(function (parent) {
          return parent.then(function (theParent) {
            return theParent.save();
          });
        });
        return all(updates);
      });
    },

    doOverlap: function doOverlap(itemA, itemB) {
      if (!_lodash.default.has(itemA, 'startTime') || !_lodash.default.has(itemA, 'duration') || !_lodash.default.has(itemB, 'startTime') || !_lodash.default.has(itemB, 'duration')) {
        return reject('Both objects must have startTime and duration properties');
      }

      if (isEmpty(itemA.get('startTime')) || isEmpty(itemA.get('duration')) || isEmpty(itemB.get('startTime')) || isEmpty(itemB.get('duration'))) {
        return reject('Both objects must have none empty startTime and duration');
      }

      // Two schedulables overlap if the start or end time of one falls within the
      // span of the other.
      var startToTest = (0, _moment.default)(itemA.get('startTime'));
      var endToTest = (0, _moment.default)(startToTest).add(itemA.get('duration'), 'minutes');

      var regionStart = (0, _moment.default)(itemB.get('startTime'));
      var regionEnd = (0, _moment.default)(startToTest).add(itemB.get('duration'), 'minutes');

      // Ends are excluded as Bookings end at the same time another Booking starts
      if (startToTest.isBetween(regionStart, regionEnd, null, '[)') || endToTest.isBetween(regionStart, regionEnd, null, '(]')) {
        return true;
      } else {
        return false;
      }
    },

    // HACK: The store is ending up with doubled records in some cases for a reason that
    //       has not been determined yet.
    _filterDuplicateRecords: function _filterDuplicateRecords(records) {
      var found = [];
      return records.filter(function (rec) {
        if (found.includes(rec.id)) {
          return false;
        } else {
          found.push(rec.id);
          return true;
        }
      });
    }
  };
});