define('supr-web-ember-v2/routes/authenticated/admin/guardians/guardian/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    beforeModel: function beforeModel() {
      var self = this;
      return this.store.findAll('student').then(function (students) {
        self.set('allStudents', students);
      });
    },

    afterModel: function afterModel(model) {
      model.get('address'); // preload the address
    },

    setupController: function setupController(controller, model) {
      var linkedIds = model.get('students').map(function (student) {
        return student.get('id');
      });
      var linkableStudents = this.get('allStudents').filter(function (student) {
        return !linkedIds.includes(student.get('id'));
      });
      controller.set('linkableStudents', linkableStudents);
      this._super(controller, model); // need this to pass the model on
    },

    deactivate: function deactivate() {
      this.currentModel.rollbackAttributes();
    }
  });
});