define('supr-web-ember-v2/components/img-button-panel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['img-button-panel', 'panel', 'panel-default', 'panel-trans'],
    title: 'Title',
    icon: 'user',
    message: ''
  });
});