define('supr-web-ember-v2/controllers/authenticated/admin/academy/edit', ['exports', 'moment', 'supr-web-ember-v2/utils/time-helper'], function (exports, _moment, _timeHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    flashService: service(),

    bookingFirstTimeDisplay: computed('model.schedule.bookingFirstTime', function () {
      return (0, _moment.default)(this.get('model.schedule.bookingFirstTime')).format('h:mm A');
    }),

    bookingLastTimeDisplay: computed('model.schedule.bookingLastTime', function () {
      return (0, _moment.default)(this.get('model.schedule.bookingLastTime')).format('h:mm A');
    }),

    actions: {
      selectFirstBookingTime: function selectFirstBookingTime(value) {
        this.set('newBookingFirstTime', value);
      },
      selectLastBookingTime: function selectLastBookingTime(value) {
        this.set('newBookingLastTime', value);
      },

      save: function save() {
        var firstTime = this.get('newBookingFirstTime');
        var lastTime = this.get('newBookingLastTime');
        var minimumGap = this.get('model.schedule.bookingGap') + this.get('model.schedule.bookingDuration');
        var minimumLastTime = (0, _moment.default)(firstTime).add(minimumGap, 'minutes');

        // TODO:
        // We should probably be just saving the first and last Booking times as
        // hours and minutes as changing the schedule at different times could mean
        // that we have a first time with hours before the last time BUT the date
        // is AFTER the last time and then we have problems if we forget to only
        // test the hours and minutes.

        // Verify values within allowable ranges
        var rangeError = false;
        var errorMsg = '';
        if (_timeHelper.default.isBeforeTime(lastTime, minimumLastTime)) {
          rangeError = true;
          errorMsg = this.get('flashService').buildMessage(errorMsg, 'The last booking time must be after the first booking time by at least ' + minimumGap + ' minutes to account for the booking duration and booking gap times.');
        }
        if (this.get('model.schedule.bookingDuration') < 1) {
          rangeError = true;
          errorMsg = this.get('flashService').buildMessage(errorMsg, 'The booking duration must be longer than 0.');
        }
        if (this.get('model.schedule.bookingIncrement') < 1) {
          rangeError = true;
          errorMsg = this.get('flashService').buildMessage(errorMsg, 'The booking increment must be longer than 0.');
        }
        if (this.get('model.schedule.bookingIncrement') < 1) {
          rangeError = true;
          errorMsg = this.get('flashService').buildMessage(errorMsg, 'The booking increment must be longer than 0.');
        }
        var start = (0, _moment.default)(this.get('model.schedule.semesterStart'));
        var end = (0, _moment.default)(this.get('model.schedule.semesterEnd'));
        if (start.isAfter(end)) {
          rangeError = true;
          errorMsg = this.get('flashService').buildMessage(errorMsg, 'The semester end must come after the semester start.');
        }
        if (rangeError) {
          this.get('flashService').error(errorMsg);
          return;
        }

        this.set('model.schedule.bookingFirstTime', firstTime.toDate());
        this.set('model.schedule.bookingLastTime', lastTime.toDate());

        var self = this;
        return self.get('model.address').then(function (address) {
          return address.save();
        }).then(function () {
          return self.get('model.schedule');
        }).then(function (schedule) {
          return schedule.save();
        }).then(function () {
          return self.get('model').save().then(function () {
            self.get('flashService').success('Academy updated.');
            self.transitionToRoute('authenticated.admin.academy');
          });
        });
      }
    }
  });
});