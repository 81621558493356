define('supr-web-ember-v2/controllers/authenticated/super/files', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  var all = Ember.RSVP.all;
  exports.default = Controller.extend({
    flashService: service(),
    fileService: service(),
    store: service(),

    avatarCount: null,
    avatarsRemaining: 0,
    fileCount: null,
    filesRemaining: 0,

    actions: {
      testFetchFile: function testFetchFile() {
        var self = this;
        // const url = 'https://api.adorable.io/avatars/50/fred@example.com.png';
        var url = 'https://cdn.filepicker.io/api/file/sRHo2tLR6uEUVhCs3b6w';
        this.get('fileService').fetchFile(url).then(function (file) {
          console.log(file);
          var name = file.name ? file.name : 'unknown';
          return self.get('fileService').saveBlob(file.blob, name);
        }).then(function (result) {
          console.log(result);
        }).catch(function (err) {
          return console.error(err);
        });
      },
      migrateAvatars: function migrateAvatars() {
        var _this = this;

        var self = this;
        var toMigrate = this.get('students').toArray();
        toMigrate.addObjects(this.get('guides'));
        toMigrate.addObjects(this.get('guardians'));

        // Ignore URLs https://api.adorable.io
        toMigrate = toMigrate.filter(function (user) {
          if (user.get('avatarURL') && !user.get('avatarURL').startsWith('https://api.adorable.io') && !user.get('avatarURL').startsWith('https://firebasestorage')) {
            return true;
          }
          return false;
        });

        this.set('avatarCount', toMigrate);
        this.set('avatarsRemaining', toMigrate.length);

        var promises = toMigrate.map(function (user) {
          return _this.get('fileService').fetchFile(user.get('avatarURL')).then(function (file) {
            var name = file.name ? file.name : 'unknown';
            return self.get('fileService').saveBlob(file.blob, name);
          }).then(function (result) {
            self.set('avatarsRemaining', self.get('avatarsRemaining') - 1);
            user.set('avatarURL', result.url);
            user.set('avatarStoragePath', result.path);
            return user.save();
          });
        });
        return all(promises).then(function () {
          self.get('flashService').success('Migration complete');
        }).catch(function (err) {
          self.get('flashService').error(err);
          console.error(err);
        }).finally(function () {
          return self.set('avatarCount', null);
        });
      },
      migrateFiles: function migrateFiles() {
        var _this2 = this;

        var self = this;

        var toMigrate = this.get('files').filter(function (file) {
          if (file.get('url') && !file.get('url').startsWith('https://firebasestorage')) {
            return true;
          }
          return false;
        });

        this.set('fileCount', toMigrate.length);
        this.set('filesRemaining', toMigrate.length);

        var promises = toMigrate.map(function (file) {
          return _this2.get('fileService').fetchFile(file.get('url'), file.get('name')).then(function (file) {
            var name = file.name ? file.name : 'unknown';
            return self.get('fileService').saveBlob(file.blob, name);
          }).then(function (result) {
            self.set('filesRemaining', self.get('filesRemaining') - 1);
            file.set('url', result.url);
            file.set('storagePath', result.path);
            return file.save();
          });
        });

        return all(promises).then(function () {
          self.get('flashService').success('Migration complete');
        }).catch(function (err) {
          self.get('flashService').error(err);
          console.error(err);
        }).finally(function () {
          return self.set('fileCount', null);
        });
      }
    }
  });
});