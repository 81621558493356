define('supr-web-ember-v2/controllers/authenticated/admin/guides', ['exports', 'supr-web-ember-v2/utils/archive-helper'], function (exports, _archiveHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var observer = Ember.observer;
  var filter = Ember.computed.filter;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    flashService: service(),
    showAll: false,
    showModal: false,

    activeGuides: filter('model.@each.isActive', function (guide) {
      return guide.get('isActive');
    }),

    archiveToggle: observer('showAll', function () {
      if (this.get('showAll')) {
        this.set('guides', this.get('allGuides'));
      } else {
        this.set('guides', this.get('activeGuides'));
      }
    }),

    actions: {
      archive: function archive(guide) {
        this.set('showModal', true);
        this.set('selectedGuide', guide);
      },

      toggleArchivedChanged: function toggleArchivedChanged() {
        this.toggleProperty('showAll');
        this.notifyPropertyChange('model.@each.isActive');
      },

      archiveGuide: function archiveGuide() {
        var _this = this;

        var self = this;
        var guide = this.get('selectedGuide');
        _archiveHelper.default.archiveGuide(guide).then(function () {
          self.get('flashService').success('Archived');
          self.transitionToRoute('authenticated.admin.guides');
        }).catch(function (err) {
          self.get('flashService').error('Guide archival failed.\n' + err);
        }).finally(function () {
          self.set('showModal', false);
          self.set('selectedGuide', null);
          // Trigger update of the filter
          if (!self.get('showAll')) {
            _this.set('guides', _this.get('activeGuides'));
          }
        });
      },

      unArchiveGuide: function unArchiveGuide(guide) {
        var self = this;
        _archiveHelper.default.unarchiveGuide(guide).then(function (results) {
          results.forEach(function () {
            self.get('flashService').success('Un-archived');
            self.transitionToRoute('authenticated.admin.guides');
          });
        }).catch(function (err) {
          self.get('flashService').error('Guide un-archival failed.\n' + err);
        });
      }
    }
  });
});