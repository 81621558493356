define('supr-web-ember-v2/components/mt-select-all-rows-checkbox', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  exports.default = Component.extend({
    classNames: ['mt-select-all-rows-checkbox'],

    actions: {
      toggleAllSelection: function toggleAllSelection() {
        get(this, 'toggleAllSelection')();
      }
    }
  });
});