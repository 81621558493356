define('supr-web-ember-v2/components/booking-month-list', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var sort = Ember.computed.sort;
  exports.default = Component.extend({
    classNames: ['booking-month-list'],

    init: function init() {
      this._super.apply(this, arguments);
      this.set('_sort', ['sortDate']);
    },

    _sort: null,
    sortedBookings: sort('bookings', '_sort'),

    months: computed('sortedBookings', function () {
      var months = {};
      this.get('sortedBookings').forEach(function (booking) {
        var date = (0, _moment.default)(booking.get('startTime'));
        var month = date.month() + 1; // Add one due to zero indexing of months
        var year = date.year();

        var key = year + '-' + month;
        if (months[key]) {
          months[key].bookings.push(booking);
        } else {
          months[key] = { year: year, month: month, bookings: [booking] };
        }
      });

      return Object.values(months);
    }),

    actions: {
      confirm: function confirm(booking) {
        this.get('confirm')(booking);
      },
      cancel: function cancel(booking) {
        this.get('cancel')(booking);
      },
      scrollToToday: function scrollToToday() {
        var scrollTarget = document.querySelector('#booking-day-current');

        if (!scrollTarget) {
          scrollTarget = document.querySelector('#booking-week-current');
        }

        if (scrollTarget) {
          scrollTarget.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }
  });
});