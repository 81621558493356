define('supr-web-ember-v2/routes/authenticated/guardian', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    can: service(),
    flashService: service(),
    currentUserService: service(),

    beforeModel: function beforeModel() {
      if (!this.get('can').can('access in app')) {
        this.get('flashService').error('Your account has been archived.\nPlease contact an administrator for support.');
        this.transitionTo('authenticated.unauthorized');
      }
      if (!this.get('can').can('guard in app')) {
        this.get('flashService').error('Only guardians can access this page.\nPlease contact an administrator for support.');
        this.transitionTo('authenticated.unauthorized');
      }
    },
    model: function model() {
      var self = this;
      return this.get('currentUserService.user.students').catch(function (err) {
        return self.get('flashService').error(err);
      });
    }
  });
});