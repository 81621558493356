define('supr-web-ember-v2/controllers/authenticated/students/student/todos/todo', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var isEmpty = Ember.isEmpty;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    notifyService: service(),
    currentUserService: service(),
    fileService: service(),
    pick: false,

    actions: {
      attach: function attach() {
        this.set('pick', true);
      },
      download: function download(file) {
        this.get('fileService').download(file);
      },
      fileSelected: function fileSelected(file) {
        var self = this;

        var todo = self.get('model');
        var studentId = todo.get('ownerID');
        var uploaderID = self.get('currentUserService.user.id');

        return this.get('fileService').save(file, studentId, 'student', uploaderID).then(function (theFile) {
          self.get('model.files').addObject(theFile);
          todo.set('lastModifiedByID', uploaderID);
          return self.get('model').save();
        }).then(function () {
          // Send notifications
          var student = void 0,
              guide = void 0;
          return self.store.find('student', studentId).then(function (theStudent) {
            student = theStudent;
            var guideId = todo.get('assignedById');
            if (isEmpty(guideId)) {
              guideId = theStudent.get('primaryGuide.id');
            }
            return self.store.findRecord('guide', guideId);
          }).then(function (theGuide) {
            guide = theGuide;

            // Notifications
            self.get('notifyService').sendNotification([{
              model: 'student',
              id: student.get('id')
            }], guide.get('fullName') + ' attached a file to your todo: ' + todo.get('name'), {
              route: 'authenticated.todos.todo',
              type: 'todo',
              id: todo.get('id')
            });
          });
        }).catch(function (err) {
          self.get('flashService').error('Failed to attach file to Todo.\n' + err);
        });
      },
      onClose: function onClose() {
        this.set('pick', false);
      },
      onError: function onError(file, error) {
        this.get('flashService').error(error);
      }
    }
  });
});