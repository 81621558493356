define('supr-web-ember-v2/models/user', ['exports', 'ember-data', 'supr-web-ember-v2/models/base', 'supr-web-ember-v2/mixins/user-roles', 'moment'], function (exports, _emberData, _base, _userRoles, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var notEmpty = Ember.computed.notEmpty;
  exports.default = _base.default.extend(_userRoles.default, {
    // roles are loaded for current user when session started
    roles: _emberData.default.hasMany('role', {
      async: true
    }),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    fullName: computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    }),
    email: _emberData.default.attr('string'),
    workEmail: _emberData.default.attr('string'),
    cellPhoneNumber: _emberData.default.attr('string'),
    workPhoneNumber: _emberData.default.attr('string'),
    homePhoneNumber: _emberData.default.attr('string'),
    emergencyContactName: _emberData.default.attr('string'),
    emergencyContactNumber: _emberData.default.attr('string'),
    address: _emberData.default.belongsTo('address', {
      async: true
    }),
    avatarURL: _emberData.default.attr('string'),
    avatarStoragePath: _emberData.default.attr('string'),

    // set to false when student does not have bookings, when a guide is unavailable for an extended period, etc.
    isActive: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    files: _emberData.default.hasMany('file', {
      async: true
    }),
    todos: _emberData.default.hasMany('todo', {
      async: true
    }),
    calendar: _emberData.default.belongsTo('calendar', {
      async: true
    }),

    hasCalendar: notEmpty('calendar.id'),
    hasAddress: notEmpty('address.id'),
    hasFiles: notEmpty('files'),
    hasTodos: notEmpty('todos'),

    displayCreatedAtDate: computed('createdAt', function () {
      return (0, _moment.default)(this.get('createdAt')).format('ddd, MMM Do, h:mm a z');
    })
  });
});