define('supr-web-ember-v2/routes/authenticated/admin/bookings/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var all = Ember.RSVP.all;
  var resolve = Ember.RSVP.resolve;
  exports.default = Route.extend({
    // To make a new booking we need to be able to select the student, the guide,
    // and check against any other bookings.
    // A booking can be for a new Learning Session or join an existing one
    model: function model() {
      return resolve();
    },

    afterModel: function afterModel() {
      var self = this;
      var promises = [];

      // Preload some other data we need
      promises.push(self.store.findAll('time-slot').then(function (timeSlots) {
        self.set('_timeSlots', timeSlots);
      }));
      promises.push(self.store.findAll('guide').then(function (guides) {
        self.set('_guides', guides);
      }));
      promises.push(self.store.findAll('student').then(function (students) {
        self.set('_students', students);
      }));

      return all(promises);
    },

    setupController: function setupController(controller, model) {
      controller.reset();
      controller.set('model', model);
      controller.set('timeSlots', this.get('_timeSlots'));
      controller.set('bookings', this.modelFor('authenticated.admin.bookings'));
      controller.set('guides', this.get('_guides').filterBy('isActive', true));
      controller.set('students', this.get('_students').filterBy('isActive', true));
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.reset();
      }
    }
  });
});