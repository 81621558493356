define('supr-web-ember-v2/components/notification-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    store: service(),

    tagName: 'li',
    classNames: ['list-group-item', 'notification-item'],
    layoutName: 'components/notification-item',
    unlinked: false,

    linkAndTarget: computed('notification.{targetType,targetId}', 'unlinked', function () {
      if (!this.get('unlinked') && this.get('notification.targetType') && this.get('notification.targetId')) {
        return true;
      }
      return false;
    }),
    linkOnly: computed('notification.{targetRoute,targetType,targetId}', 'unlinked', function () {
      if (!this.get('unlinked') && this.get('notification.targetRoute') && !this.get('notification.targetType') && !this.get('notification.targetId')) {
        return true;
      }
      return false;
    }),
    messageOnly: computed('linkAndTarget', 'linkOnly', 'unlinked', function () {
      if (this.get('unlinked') || !this.get('linkAndTarget') && !this.get('linkOnly')) {
        return true;
      }
      return false;
    }),

    // NOTE:
    // Models must be pre-loaded at the route level and ready for link-to to build the URL here
    targetModel: computed('notification.{targetType,targetId}', function () {
      return this.get('store').peekRecord(this.get('notification.targetType'), this.get('notification.targetId'));
    })
  });
});