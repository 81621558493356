define('supr-web-ember-v2/models/time-slot', ['exports', 'ember-data', 'supr-web-ember-v2/models/base', 'moment'], function (exports, _emberData, _base, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _base.default.extend({
    guide: _emberData.default.belongsTo('guide', {
      async: true
    }),
    startTime: _emberData.default.attr('date'),
    duration: _emberData.default.attr('number'), // Time in minutes, e.g. 90

    day: computed('startTime', function () {
      return _moment.default.weekdays()[(0, _moment.default)(this.get('startTime')).day()]; // Monday, Tuesday, etc.
    }),

    endTime: computed('startTime', 'duration', function () {
      return (0, _moment.default)(this.get('startTime')).add(this.get('duration'), 'minutes').toDate();
    })
  });
});