define('supr-web-ember-v2/controllers/authenticated', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    session: service(),
    currentUserService: service(),
    collapsed: true,

    actions: {
      invalidateSession: function invalidateSession() {
        this.send('showLoadingSpinner');
        this.get('session').invalidate();
      }
    }
  });
});