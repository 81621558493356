define('supr-web-ember-v2/components/bs-panel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['panel'],
    classNameBindings: ['panelType'],
    type: 'default',
    panelType: computed('type', function () {
      return 'panel-' + this.get('type');
    })
  });
});