define('supr-web-ember-v2/routes/authenticated/profile', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    flashService: service(),
    can: service(),
    currentUserService: service(),

    // IMPROVE:
    // All authenticated routes are under the authenticated root (that has no path) which means
    // we should be able to create an index route for authenticated and have the AuthenticatedRouteMixin
    // only in that route, which would cover all of the routes below it.
    // The same goes for the basic can('access in app') check.
    beforeModel: function beforeModel() {
      if (!this.get('can').can('access in app')) {
        this.get('flashService').error('Your account has been archived.\nPlease contact an administrator for support.');
        this.transitionTo('authenticated.unauthorized');
      }
    },

    model: function model() {
      return this.get('currentUserService.user');
    },

    afterModel: function afterModel(model) {
      return model.get('address');
    }
  });
});