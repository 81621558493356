define('supr-web-ember-v2/services/notify-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var isPresent = Ember.isPresent;
  var resolve = Ember.RSVP.resolve;
  var all = Ember.RSVP.all;
  exports.default = Service.extend({
    flashService: service(),
    currentUserService: service(),
    store: service(),

    sendNotification: function sendNotification(recipients, message, target, glob) {
      var _this = this;

      var self = this;

      var promises = [];
      recipients.forEach(function (recipient) {
        if (recipient.id === self.get('currentUserService.user.id')) {
          return; // don't send current user a notification for their own actions
        }
        var data = {};
        var userPromise = self.get('store').find(recipient.model, recipient.id);
        data.toId = recipient.id;
        data.toUserType = recipient.model;
        if (isPresent(message)) {
          data.message = message;
        }
        if (isPresent(target)) {
          if (!target.route && target.type && target.id) {
            this.get('flashService').error('Target requires route, type, and id to be defined');
            return;
          }
          data.targetRoute = target.route;
          data.targetType = target.type;
          data.targetId = target.id;
        }
        if (isPresent(glob)) {
          data.glob = glob;
        }

        var user = void 0,
            notification = void 0;
        promises.push(resolve(userPromise).then(function (theUser) {
          user = theUser;
          // creating the record should make it show up in a users notification feed
          var notification = self.get('store').createRecord('notification', data);
          return notification.save();
        }).then(function (theNotification) {
          notification = theNotification;
          return user.get('notifications');
        }).then(function (theNotifications) {
          theNotifications.addObject(notification);
          return user.save();
        }));
      });
      return all(promises).catch(function (err) {
        return _this.get('flashService').error('Failed to send notifications: ' + err);
      });
    }
  });
});