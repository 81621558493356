define("supr-web-ember-v2/models/scheduable", ["exports", "ember-data", "supr-web-ember-v2/models/base", "moment"], function (exports, _emberData, _base, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var alias = Ember.computed.alias;
  exports.default = _base.default.extend({
    startTime: _emberData.default.attr("date"),
    duration: _emberData.default.attr("number"), // in minutes
    location: _emberData.default.belongsTo("address", {
      async: true
    }),
    sortDate: alias("startTime"),
    cancelledOn: _emberData.default.attr("date"),
    cancelledBy: _emberData.default.attr("string"), // can't do polymorphic so we set as a string with user type and name

    scheduableType: computed(function () {
      return this.constructor.modelName;
    }),

    displayCreatedAtDate: computed("createdAt", function () {
      return (0, _moment.default)(this.get("createdAt")).format("ddd, MMM Do, h:mm a z");
    }),

    displayDate: computed("sortDate", function () {
      return (0, _moment.default)(this.get("sortDate")).format("dddd, MMMM Do, h:mm a");
    }),

    endTime: computed("startTime", "duration", function () {
      return (0, _moment.default)(this.get("startTime")).add(this.get("duration"), "minutes").toDate();
    }),

    displayStartTime: computed("startTime", function () {
      return (0, _moment.default)(this.get("startTime")).format("dddd, MMM Do, h:mm a z");
    }),

    displayEndTime: computed("endTime", function () {
      return (0, _moment.default)(this.get("endTime")).format("h:mm a z");
    }),

    displayEndDate: computed("endTime", function () {
      return (0, _moment.default)(this.get("endTime")).format("dddd, MMM Do, h:mm a z");
    }),

    cancelledOnTime: computed("cancelledOn", function () {
      return (0, _moment.default)(this.get("cancelledOn")).format("ddd, MMM Do, h:mm a z");
    }),

    day: computed("startTime", function () {
      return _moment.default.weekdays()[(0, _moment.default)(this.get("startTime")).day()]; // Monday, Tuesday, etc.
    }),

    displayDuration: computed("duration", function () {
      // TODO: moment could probably simplify this
      var displayDuration;
      // duration is in min
      if (this.get("duration") < 60) {
        displayDuration = this.get("duration") + " minutes";
      } else {
        var hours = this.get("duration") / 60;
        if (hours > 1) {
          displayDuration = hours + " hours";
        } else {
          displayDuration = hours + " hour";
        }
      }
      return displayDuration;
    }),

    consoleOutput: computed(function () {
      return "day=" + this.get("day") + " time=" + (0, _moment.default)(this.get("startTime")).format("h:mm a") + " duration=" + this.get("duration") + " guide=" + this.get("guide.fullName");
    })
  });
});