define('supr-web-ember-v2/utils/promise-helper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var reject = Ember.RSVP.reject;
  var resolve = Ember.RSVP.resolve;
  var debug = Ember.debug;
  var isPresent = Ember.isPresent;
  exports.default = {
    // Ember.RSVP.Promise.allSettled returns an array of the following format
    // array == [
    //   { state: 'fulfilled', value: 1 },
    //   { state: 'rejected', reason: Error },
    //   { state: 'rejected', reason: Error }
    // ]
    logSettledErrors: function logSettledErrors(results) {
      if (isPresent(results)) {
        results.forEach(function (result) {
          if (result.state === 'rejected') {
            debug('-- promise rejected for reason: ' + result.reason);
          }
        });
      }
    },

    // Takes the results from a settled promise and combines the errors, if any,
    // into a single message and returns a new Promise.
    // This makes it simpler to handle the result of actions that involve many
    // Promise chains.
    combineSettledPromise: function combineSettledPromise(results) {
      var errorMessage = '';
      results.forEach(function (result) {
        if (result.state === 'rejected') {
          errorMessage = errorMessage + result.reason + '\n';
        }
      });
      if (errorMessage.length > 0) {
        return reject(errorMessage);
      } else {
        return resolve(results);
      }
    }
  };
});