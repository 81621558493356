define('supr-web-ember-v2/models/notification', ['exports', 'ember-data', 'supr-web-ember-v2/models/base', 'moment'], function (exports, _emberData, _base, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var isPresent = Ember.isPresent;
  exports.default = _base.default.extend({
    timestamp: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    toId: _emberData.default.attr('string'),
    // because we don't have polymorphism for users
    toUserType: _emberData.default.attr('string'),
    notificationType: _emberData.default.attr('string', {
      defaultValue: 'general'
    }),
    message: _emberData.default.attr('string'),
    targetRoute: _emberData.default.attr('string'), // route for notification target
    targetType: _emberData.default.attr('string'), // model type for the target
    targetId: _emberData.default.attr('string'), // model id for the target
    // treat this as JSON if we need it
    glob: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    displayCreatedAtDate: computed('createdAt', function () {
      return (0, _moment.default)(this.get('createdAt')).format('ddd, MMM Do, h:mm a z');
    }),
    displayTimestamp: computed('timestamp', function () {
      return (0, _moment.default)(this.get('timestamp')).format('ddd, MMM Do, h:mm a z');
    }),
    hasLink: computed('targetRoute', 'targetType', 'targetId', function () {
      if (isPresent(this.get('targetRoute'))) {
        return true;
      }
      return false;
    }),
    sentAt: computed('timestamp', function () {
      return (0, _moment.default)(this.get('timestamp')).format('dddd, MMMM Do YYYY, h:mm a');
    })
  });
});