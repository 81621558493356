define('supr-web-ember-v2/services/user-service', ['exports', 'isemail', 'supr-web-ember-v2/config/constants'], function (exports, _isemail, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var Service = Ember.Service;
  var service = Ember.inject.service;
  var isEmpty = Ember.isEmpty;
  var reject = Ember.RSVP.reject;
  var all = Ember.RSVP.all;
  var resolve = Ember.RSVP.resolve;
  var debug = Ember.debug;
  exports.default = Service.extend({
    session: service(),
    store: service(),
    flashService: service(),
    firebaseService: service(),

    getRole: function getRole(roleName) {
      return this.get('store').findAll('role').then(function (roles) {
        return roles.findBy('type', roleName);
      });
    },

    userForId: function userForId(id) {
      var self = this;
      var userLookup = null;

      if (isEmpty(id)) {
        this.get('flashService').error('id required');
        return null;
      }

      // User could be one of student, guide, or guardian so must try loading from each collection
      userLookup = all([this.get('store').query('student', {
        orderByKey: true,
        equalTo: id
      }), this.get('store').query('guide', {
        orderByKey: true,
        equalTo: id
      }), this.get('store').query('guardian', {
        orderByKey: true,
        equalTo: id
      })]).then(function (result) {
        var modelType = null;

        var _result = _slicedToArray(result, 3),
            student = _result[0],
            guide = _result[1],
            guardian = _result[2];

        if (!isEmpty(student)) {
          modelType = 'student';
        }
        if (!isEmpty(guide)) {
          modelType = 'guide';
        }
        if (!isEmpty(guardian)) {
          modelType = 'guardian';
        }
        // return err if id empty
        if (isEmpty(modelType)) return reject('No record found for user with id ' + id);

        return self.get('store').peekRecord(modelType, id);
      });

      // Look up the user
      return userLookup.then(function (user) {
        if (isEmpty(user)) {
          return reject('Couldn\'t find user for id ' + id);
        }
        return user;
      }).catch(function (err) {
        self.get('flashService').error('Failed to load user: ' + err);
        debug("Couldn't find user for id=" + id + ' ' + err);
        return reject("Couldn't find user for id=" + id + ' ' + err);
      });
    },

    // V2: TODO: Should make this a cloud function
    createUser: function createUser(userType, fields, addressFields) {
      userType = userType.toLowerCase();
      var self = this;
      var newUser = null;

      fields.timestamp = new Date();

      if (!(0, _isemail.validate)(fields.email)) {
        this.get('flashService').error('Please enter a valid email');
        return reject('INVALID_EMAIL');
      }

      if (fields.password !== fields.confirmPassword) {
        this.get('flashService').error("Passwords don't match");
        return reject('INVALID_PASSWORD');
      }

      return this.get('firebaseService').auth().createUserWithEmailAndPassword(fields.email, fields.password).then(function (result) {
        console.log(result);
        return resolve();
      }).then(function () {
        return self.get('store').createRecord(userType, fields).save();
      }).then(function (newUserInstance) {
        newUser = newUserInstance;
        return self.get('store').createRecord('calendar', {
          ownerID: newUser.get('id')
        }).save();
      }).then(function (cal) {
        newUser.set('calendar', cal);

        var address = addressFields ? addressFields : {};
        return self.get('store').createRecord('address', address).save();
      }).then(function (address) {
        newUser.set('address', address);
        var promises = [];

        // Add any Roles
        var role = void 0;
        if (fields.adminRole) {
          role = self.getRole(_constants.default.role.ADMIN);
          // NOTE:
          // This will not be necessary when we implement roles properly as
          // an array on user or via Firebase auth
          if (isEmpty(role)) {
            return reject('Admin role is missing');
          }
          promises.push(role);
        }
        if (fields.guideMentorRole) {
          role = self.getRole(_constants.default.role.GUIDE_MENTOR);
          // NOTE:
          // This will not be necessary when we implement roles properly as
          // an array on user or via Firebase auth
          if (isEmpty(role)) {
            return reject('Guide Mentor role is missing');
          }
          promises.push(role);
        }
        return all(promises);
      }).then(function (roles) {
        return newUser.set('roles', roles);
      }).then(function () {
        // Add empty timeSlot availability data for guides
        var promises = [];
        if (userType === 'guide') {
          var days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
          days.forEach(function (day) {
            for (var i = 6; i < 22; i++) {
              promises.push(self.get('store').createRecord('time-slot', {
                guide: newUser,
                startTime: i,
                day: day
              }).save());
            }
          });
          return all(promises);
        } else {
          return resolve();
        }
      }).then(function () {
        return newUser.save();
      }).catch(function (error) {
        self.get('flashService').error(error.message);
        debug(error);
        return reject(error);
      });
    },

    authenticate: function authenticate(email, password) {
      return this.get('session').authenticate('authenticator:torii', 'firebase', {
        provider: 'password',
        email: email,
        password: password
      });
    }
  });
});