define('supr-web-ember-v2/controllers/unauthenticated/new-account', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  exports.default = Controller.extend({
    queryParams: ['userType'],
    userType: 'student', // default
    isMature: computed('userType', function () {
      return this.get('userType').toLowerCase() === 'mature-student';
    }),
    displayType: computed('userType', function () {
      switch (this.get('userType').toLowerCase()) {
        case 'mature-student':
          return 'Mature Student';
        case 'guardian':
          return 'Parent or Guardian';
        default:
          return 'Student';
      }
    })
  });
});