define('supr-web-ember-v2/controllers/authenticated/todos/todo', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    currentUserService: service(),
    flashService: service(),
    fileService: service(),
    pick: false,

    actions: {
      attachFiles: function attachFiles() {
        this.set('pick', true);
      },
      fileSelected: function fileSelected(file) {
        var todo = this.get('model');

        this.set('pick', false);
        return this.get('fileService').save(file).then(function (theFile) {
          todo.get('files').addObject(theFile);
          return todo.save();
        });
      },
      onClose: function onClose() {
        this.set('pick', false);
      },
      onError: function onError(file, error) {
        this.set('pick', false);
        this.get('flashService').error(error);
      },

      download: function download(file) {
        this.get('fileService').download(file);
      }
    }
  });
});