define('supr-web-ember-v2/models/learning-objective', ['exports', 'ember-data', 'supr-web-ember-v2/models/base', 'moment'], function (exports, _emberData, _base, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _base.default.extend({
    student: _emberData.default.belongsTo('student', {
      async: true
    }),

    emphasisMathGeneral: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    emphasisMathAlgebra: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    emphasisMathAdvancedFunctions: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    emphasisMathCalculus: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    emphasisMathTrigonometry: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    emphasisMathFunctions: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    emphasisScienceGeneral: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    emphasisScienceChemistry: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    emphasisScienceBiology: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    emphasisSciencePhysics: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    emphasisOther: _emberData.default.attr('string'),

    homeworkHelp: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    conceptReview: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    lessonReview: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    generalReview: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    examPreperationAP: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    examPreperationIB: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    examPreperationSSAT: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    examPreperationEQAO: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    examPreperationFinal: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    examPreperationOtherStandardized: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    examPreperationOtherStandardizedDetail: _emberData.default.attr('string', {
      defaultValue: false
    }),
    otherHelp: _emberData.default.attr('string'),
    displayCreatedAtDate: computed('createdAt', function () {
      return (0, _moment.default)(this.get('createdAt')).format('ddd, MMM Do, h:mm a z');
    })
  });
});