define('supr-web-ember-v2/components/roles-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['roles-list'],

    roles: computed('record', function () {
      var list = '';
      this.get('record.roles').forEach(function (role) {
        list = list + role.get('type') + ' ';
      });
      return list;
    })
  });
});