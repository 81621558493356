define('supr-web-ember-v2/models/booking', ['exports', 'ember-data', 'supr-web-ember-v2/models/scheduable', 'supr-web-ember-v2/config/constants'], function (exports, _emberData, _scheduable, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var notEmpty = Ember.computed.notEmpty;
  var uniq = Ember.computed.uniq;
  var all = Ember.RSVP.all;
  var service = Ember.inject.service;
  exports.default = _scheduable.default.extend({
    // FIXME:
    // We shouldn't have services in models I don't think Instead, we could have a Booking service
    // with an isWith function as the result is dependent on who is logged in and looking at the Booking.
    currentUserService: service(),
    // pending, confirmed, attended, missed, missed-with-24hrs, missed-academy, cancelled
    status: _emberData.default.attr('string', {
      defaultValue: _constants.default.booking.status.PENDING
    }),
    learningSession: _emberData.default.belongsTo('learning-session', {
      async: true
    }),
    // may be associated with a reservation but not necessarily
    reservation: _emberData.default.belongsTo('reservation', {
      async: true,
      inverse: 'bookings'
    }),
    student: _emberData.default.belongsTo('student', {
      async: true
    }),
    guide: _emberData.default.belongsTo('guide', {
      async: true
    }),
    bookedOn: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    updatedOn: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    location: _emberData.default.belongsTo('address', {
      async: true
    }),

    // NOTE: IMPROVE:
    // It probably doesn't make sense for the booking records to keep track of state like
    // being booked over. It would make more sense if the code for generating a booking would
    // simply look these things up.

    // This Booking booked over another Booking
    bookerBookingConflicts: _emberData.default.hasMany('booking-over-booking-conflict', {
      async: true,
      inverse: 'booker'
    }),
    // This Booking booked over by another Booking
    bookedOverBookingConflicts: _emberData.default.hasMany('booking-over-booking-conflict', {
      async: true,
      inverse: 'bookedOver'
    }),
    // This Booking booked over a Reservation
    bookerReservationConflicts: _emberData.default.hasMany('booking-over-reservation-conflict', {
      async: true
    }),
    // This Booking booked over by a Reservation
    bookedOverReservationConflicts: _emberData.default.hasMany('reservation-over-booking-conflict', {
      async: true
    }),

    hasBookerBookingConflicts: notEmpty('bookerBookingConflicts'),
    hasBookedOverBookingConflicts: notEmpty('bookedOverBookingConflicts'),
    hasBookerReservationConflicts: notEmpty('bookerReservationConflicts'),
    hasBookedOverReservationConflicts: notEmpty('bookedOverReservationConflicts'),

    conflicts: uniq('bookerBookingConflicts', 'bookedOverBookingConflicts', 'bookerReservationConflicts', 'bookedOverReservationConflicts'),

    hasConflicts: computed('hasBookerBookingConflicts', 'hasBookedOverBookingConflicts', 'hasBookedOverReservationConflicts', 'bookerReservationConflicts', function () {
      return this.get('hasBookerBookingConflicts') || this.get('hasBookedOverBookingConflicts') || this.get('hasBookedOverReservationConflicts') || this.get('hasBookerReservationConflicts');
    }),

    isWith: computed('student.{fullName,isFulfilled}', 'guide{fullName,isFulfilled}', function () {
      if (this.get('currentUserService.user.id') === this.get('student.id')) {
        return this.get('guide.fullName');
      } else {
        return this.get('student.fullName');
      }
    }),

    isActive: computed('status', function () {
      var status = this.get('status');
      if (status === _constants.default.booking.status.PENDING || status === _constants.default.booking.status.CONFIRMED) {
        return true;
      } else {
        return false;
      }
    }),

    isPending: computed('status', function () {
      return this.get('status') === _constants.default.booking.status.PENDING;
    }),

    isConfirmed: computed('status', function () {
      return this.get('status') === _constants.default.booking.status.CONFIRMED;
    }),

    // TODO: This could be made more succient with something like
    //       record.eachRelationship(function(name, descriptor) {
    //         record[descriptor.kind](name).reload();
    //       });
    //       https://github.com/emberjs/data/issues/1913
    loadRelations: function loadRelations() {
      var self = this;
      var promises = [];
      promises.pushObject(this.get('learningSession'));
      promises.pushObject(this.get('reservation'));
      promises.pushObject(this.get('student'));
      promises.pushObject(this.get('guide'));
      promises.pushObject(this.get('location'));
      promises.pushObject(this.get('bookerBookingConflicts'));
      promises.pushObject(this.get('bookedOverBookingConflicts'));
      promises.pushObject(this.get('bookerReservationConflicts'));
      promises.pushObject(this.get('bookedOverReservationConflicts'));
      return all(promises).then(function () {
        return self;
      });
    }
  });
});