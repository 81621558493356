define('supr-web-ember-v2/routes/authenticated/admin/registrations/new', ['exports', 'supr-web-ember-v2/config/constants', 'moment'], function (exports, _constants, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var Route = Ember.Route;
  var all = Ember.RSVP.all;
  var A = Ember.A;
  var service = Ember.inject.service;
  var isEmpty = Ember.isEmpty;
  var debug = Ember.debug;
  exports.default = Route.extend({
    // TODO: Need to be able to look up existing Guardians and Students for the
    // case where they already exist. General case is Guardian has more than one
    // child.
    //
    // If we renew registrations (enrollments?) we presumably need the option to
    // update LearningObjectives. An admin should be able to do this at any time
    // directly for the Student.

    // Example of doing multi-step form:
    // http://stackoverflow.com/questions/21620782/multi-step-form-or-multiple-pages-in-one-route-using-ember-js
    // Better I think, with proper URLs for steps
    // http://stackoverflow.com/questions/15383834/ember-js-creating-a-multistep-wizard-how-to
    // Notion of using query params so you don't get stupid long URLs
    // http://discuss.emberjs.com/t/how-to-organize-a-multi-step-app/5076

    // TODO: if we cancel creation of registration we should delete any files that
    // have been uploaded.

    flashService: service(),
    fileService: service(),
    studentService: service(),
    registrationService: service(),
    academyService: service(),

    model: function model() {
      return all([this.store.findAll('time-slot'), this.store.findAll('reservation'), this.store.findAll('student'), this.store.findAll('guide'), this.store.findAll('guardian')]);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model); // need this to pass the model on

      var _model = _slicedToArray(model, 5),
          availabilities = _model[0],
          reservations = _model[1],
          students = _model[2],
          guides = _model[3],
          guardians = _model[4];

      controller.set('saving', false);
      controller.set('specialPrograms', ['IB', 'Masters', 'AP', 'Virtual High School', 'Home School', 'Other']);
      controller.set('publishers', ['Nelson', 'Ryerson', 'Other']);
      // add field hashes for our records to simplify creating them and clearing
      // fields between visits to this route
      controller.set('learningProfileFields', {});
      controller.set('learningObjectivesFields', {});
      controller.set('selectedGuardians', A());
      controller.set('selectedStudent', null);
      controller.set('fields', {});
      controller.set('availabilityTimeSlots', availabilities);
      controller.set('reservations', reservations);
      // NOTE: store.filter doesn't load the students on a page reload so we do it
      // here
      controller.set('students', students.filter(function (student) {
        return student.get('registration.id') == null;
      }));
      controller.set('guides', guides);
      controller.set('guardians', guardians);

      var plans = Object.values(_constants.default.plan);
      controller.set('selectedPlan', _constants.default.plan.NONE);
      controller.set('plans', plans);
    },

    resetController: function resetController(controller, isExiting) {
      this.controller.set('saving', false);
      if (isExiting) {
        controller.set('selectedPlan', _constants.default.plan.NONE);
        controller.set('selectedStartDate', (0, _moment.default)());
        controller.set('newReservations', A());
        controller.set('selectedDay', null);
        controller.set('selectedTime', null);
        controller.set('selectedGuardians', A());
        controller.set('selectedStudent', null);
        controller.set('selectedPrimaryGuide', null);
        controller.set('learningProfileFields', {});
        controller.set('learningObjectivesFields', {});
        controller.set('fields', {});
      }
    },

    actions: {
      showMessage: function showMessage() {
        this.get('flashService').success('Message testing');
      },

      createRegistration: function createRegistration() {
        var self = this;
        // There are essentially three steps to this process
        // 1. creating a registration with a student, primary guide, guardian(s),
        //    and an agreement
        // 2. creating a schedule (reservation + bookings)
        // 3. creating a learning profile and learning objective
        //
        // Each should be done in a step and all relevant records in the step
        // reverted or deleted if an error happens in that step. Rollbacks should
        // happen in the helper function so that the catch in a series of promises
        // here is only for user feedback, that the process failed, not for
        // recovery and clean up.

        this.controller.set('saving', true);

        var student = this.controller.selectedStudent;
        var guide = this.controller.selectedPrimaryGuide;
        var guardians = this.controller.selectedGuardians;
        var plan = this.controller.selectedPlan;
        var startDate = plan == _constants.default.plan.NONE ? null : this.controller.selectedStartDate.toDate();

        if (isEmpty(student) || isEmpty(guide) || isEmpty(guardians)) {
          this.get('flashService').error('A Student, Guide, and Guardian are required to create a registration');
          this.controller.set('saving', false);
          return;
        }

        var notes = this.controller.fields.notes;
        var agreement = this.controller.fields.agreement;
        var newReservations = this.controller.get('newReservations');
        var academy = this.get('academyService.academy');
        var learningProfileFields = this.controller.get('learningProfileFields');
        var learningOjbectivesFields = this.controller.get('learningObjectivesFields');

        if (plan != _constants.default.plan.NONE && isEmpty(newReservations)) {
          this.get('flashService').error('You selected a plan but didn\'t create a schedule');
          this.controller.set('saving', false);
          return;
        }

        var registration = void 0;
        self.get('registrationService').createRegistration(plan, startDate, notes, agreement, student, guide, guardians).then(function (results) {
          registration = results[3].value;
          return self.get('registrationService').createSchedule(registration, student, guide, guardians, newReservations, academy);
        }).then(function () {
          return self.get('studentService').createLearningProfile(learningProfileFields, student);
        }).then(function () {
          return self.get('studentService').createLearningObjective(learningOjbectivesFields, student);
        }).then(function () {
          return self.get('flashService').success('Registration created');
        }).catch(function (err) {
          console.error(err);
          debug('createRegistration: Ouch, creating one of the records failed' + err);
          self.get('flashService').error('Registration failed: ' + err);
        }).finally(function () {
          self.resetController(self.controller, true);

          if (registration) {
            self.transitionTo('authenticated.admin.registrations.registration', registration);
          } else {
            self.transitionTo('authenticated.admin.registrations');
          }

          // NOTE Current attempts to reload the registrations route are
          // resulting in a Transition error once the afterModel hook has been
          // triggered.
          //
          // self.send('loadNewData'); // sent to route
          //
          // registrations route implemented:
          // actions: {
          //   loadNewData: function() {
          //     this.refresh();
          //   },
          // },
          // May be related to: https://github.com/emberjs/ember.js/issues/10945
        });
      }
    }
  });
});