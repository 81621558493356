define('supr-web-ember-v2/services/mail-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    flashService: service(),
    currentUserService: service(),
    store: service(),
    firebaseService: service(),

    sendEmail: function sendEmail(email, name, subject, body) {
      var _this = this;

      var callable = this.get('firebaseService').functions().httpsCallable('genericEmail');

      callable({
        email: email,
        name: name,
        subject: subject,
        body: body
      }).then(function () {
        _this.get('flashService').success('Email sent');
      }).catch(function (err) {
        var msg = 'Failed to send email to ' + email;
        msg = _this.get('flashService').buildMessage(err);
        _this.get('flashService').error(msg);
      });
    }
  });
});