define('supr-web-ember-v2/models/todo', ['exports', 'ember-data', 'supr-web-ember-v2/models/base', 'moment'], function (exports, _emberData, _base, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var notEmpty = Ember.computed.notEmpty;
  var computed = Ember.computed;
  var observer = Ember.observer;
  exports.default = _base.default.extend({
    name: _emberData.default.attr('string'),
    note: _emberData.default.attr('string'),
    priority: _emberData.default.attr('string', {
      defaultValue: 'Medium'
    }), // high, medium, low
    status: _emberData.default.attr('string', {
      defaultValue: 'Incomplete'
    }), // complete, incomplete, failed
    tags: _emberData.default.hasMany('tag', {
      async: true
    }),
    category: _emberData.default.belongsTo('category', {
      async: true
    }),
    ownerID: _emberData.default.attr('string'), // temporary fix until we get polymorphism working
    dueDate: _emberData.default.attr('date'),
    completedDate: _emberData.default.attr('date'),
    reminder: _emberData.default.attr('date'),
    files: _emberData.default.hasMany('file', {
      async: true
    }),
    hasFiles: notEmpty('files'),
    // FIXME: V1:
    // When using a relationship, adding a todo to a guides todos is also
    // setting the assignedBy relationship to the guide.
    // This is related to Issue #11
    // https://bitbucket.org/supracademy/supr-web-ember-v2/issues/11/unable-to-add-todo-to-user-without-setting
    // assignedBy:    DS.belongsTo('guide', {async: true}),
    assignedById: _emberData.default.attr('string'),
    displayCreatedAtDate: computed('createdAt', function () {
      return (0, _moment.default)(this.get('createdAt')).format('ddd, MMM Do, h:mm a z');
    }),
    sortDate: computed('dueDate', 'completedDate', 'status', function () {
      if (this.get('completedDate')) {
        return this.get('completedDate');
      } else {
        return this.get('dueDate');
      }
    }),
    displayDate: computed('sortDate', 'completedDate', 'status', function () {
      return (0, _moment.default)(this.get('sortDate')).format('dddd, MMMM Do, h:mm a');
    }),
    displayDueDate: computed('dueDate', 'completedDate', function () {
      return (0, _moment.default)(this.get('dueDate')).format('dddd, MMMM Do, h:mm a');
    }),
    displayCompletedDate: computed('completedDate', 'dueDate', 'status', function () {
      if (this.get('completedDate')) {
        return (0, _moment.default)(this.get('completedDate')).format('dddd, MMMM Do, h:mm a');
      } else {
        return null;
      }
    }),
    statusObserver: observer('status', function () {
      this.get('sortDate');
      this.get('displayDate');
      this.get('displayDueDate');
      this.get('displayCompletedDate');
    })
  });
});