define('supr-web-ember-v2/components/pill-nav', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var EmberObject = Ember.Object;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    router: service(),
    tagName: 'ul',
    classNames: ['pill-nav nav nav-pills nav-justified'],

    init: function init() {
      this._super.apply(this, arguments);
      // this.set('navItems', []);
    },

    childWrappers: computed('items.@each', function () {
      return this.get('navItems').map(function (item) {
        return EmberObject.create({ // wrapper object
          item: item,
          active: false
        });
      });
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.get('childWrappers').forEach(function (wrapper) {
        if (_this.get('router').isActive(wrapper.get('item.route'))) {
          wrapper.set('active', true);
        } else {
          wrapper.set('active', false);
        }
      });
    },

    actions: {
      select: function select(selected) {
        this.get('childWrappers').forEach(function (wrapper) {
          if (wrapper.get('item.route') === selected.get('route')) {
            wrapper.set('active', true);
          } else {
            wrapper.set('active', false);
          }
        });
        this.get('router').transitionTo(selected.get('route'));
      }
    }
  });
});