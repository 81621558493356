define('supr-web-ember-v2/controllers/authenticated/admin/guardians', ['exports', 'supr-web-ember-v2/utils/archive-helper'], function (exports, _archiveHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var observer = Ember.observer;
  var filter = Ember.computed.filter;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    flashService: service(),
    showAll: false,
    showModal: false,

    activeGuardians: filter('model.@each.isActive', function (guardian) {
      return guardian.get('isActive');
    }),

    archiveToggle: observer('showAll', function () {
      if (this.get('showAll')) {
        this.set('guardians', this.get('allGuardians'));
      } else {
        this.set('guardians', this.get('activeGuardians'));
      }
    }),
    // If the first screen to load is Guardians then we need to load a Guardians
    // students in order to show what Students and Bookings will be archived
    // FIXME:
    // If the above is true then we need to preload the students in the route.
    // Not using isShowingModal anymore so need to do something different
    guardianObserver: observer('model.@each.isShowingModal', function () {
      this.get('model').forEach(function (guardian) {
        if (guardian.get('isShowingModal')) {
          guardian.get('students').then(function (students) {
            students.forEach(function (student) {
              student.set('hasActiveBookings', false);
              student.set('hasMultipleActiveGuardians', false);
              student.get('bookings').then(function (theBookings) {
                theBookings.forEach(function (booking) {
                  if (booking.get('isActive')) {
                    student.set('hasActiveBookings', true);
                  }
                });
              });
              student.get('guardians').then(function (theGuardians) {
                var activeCount = 0;
                theGuardians.forEach(function (guardian) {
                  if (guardian.get('isActive')) {
                    activeCount = ++activeCount;
                  }
                });
                if (activeCount > 1) {
                  student.set('hasMultipleActiveGuardians', true);
                }
              });
            });
          });
        }
      });
    }),

    actions: {
      archive: function archive(guardian) {
        this.set('showModal', true);
        this.set('selectedGuardian', guardian);
      },

      toggleArchivedChanged: function toggleArchivedChanged() {
        this.toggleProperty('showAll');
        this.notifyPropertyChange('model.@each.isActive');
      },

      archiveGuardian: function archiveGuardian() {
        var _this = this;

        var guardian = this.get('selectedGuardian');
        var self = this;
        _archiveHelper.default.archiveGuardian(guardian).then(function () {
          self.get('flashService').success('Archived');
          self.transitionToRoute('authenticated.admin.guardians');
        }).catch(function (err) {
          self.get('flashService').error('Guardian archival failed.\n' + err);
        }).finally(function () {
          self.set('showModal', false);
          self.set('selectedGuardian', null);
          // Trigger update of the filter
          if (!self.get('showAll')) {
            _this.set('guardians', _this.get('activeGuardians'));
          }
        });
      },

      unArchiveGuardian: function unArchiveGuardian(guardian) {
        var self = this;
        _archiveHelper.default.unarchiveGuardian(guardian).then(function (results) {
          results.forEach(function () {
            self.get('flashService').success('Un-archived');
            self.transitionToRoute('authenticated.admin.guardians');
          });
        }).catch(function (err) {
          self.get('flashService').error('Guardian un-archival failed.\n' + err);
        });
      }
    }
  });
});