define('supr-web-ember-v2/routes/authenticated/super/repair', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  var A = Ember.A;
  var all = Ember.RSVP.all;
  var isEmpty = Ember.isEmpty;
  var isPresent = Ember.isPresent;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    flashService: service(),
    can: service(),
    statisticsService: service(),

    beforeModel: function beforeModel(transition) {
      if (this.get('can').cannot('super in app')) {
        this.get('flashService').error("You don't have the necessary permissions to access " + transition.targetName);
        this.transitionTo('authenticated.unauthorized');
      }

      if (this.get('can').cannot('access in app')) {
        this.get('flashService').error('Your account has been archived. Please contact an administrator for support.');
        this.transitionTo('authenticated.unauthorized');
      }
    },

    // Load the entire db
    model: function model() {
      return all([this.store.findAll('academy'), this.store.findAll('academySchedule'), this.store.findAll('address'), this.store.findAll('booking'), this.store.findAll('bookingOverBookingConflict'), this.store.findAll('bookingOverReservationConflict'), this.store.findAll('calendar'), this.store.findAll('calendarEvent'), this.store.findAll('category'), this.store.findAll('file'), this.store.findAll('guardian'), this.store.findAll('guide'), this.store.findAll('learningObjective'), this.store.findAll('learningProfile'), this.store.findAll('learningSession'), this.store.findAll('notification'), this.store.findAll('registration'), this.store.findAll('reservation'), this.store.findAll('reservationOverBookingConflict'), this.store.findAll('role'), this.store.findAll('school'), this.store.findAll('student'), this.store.findAll('tag'), this.store.findAll('timeSlot'), this.store.findAll('todo')]).catch(function (err) {
        return console.error(err);
      });
    },

    afterModel: function afterModel(model) {
      var self = this;

      // Reset record stats
      this.get('statisticsService').resetRecordStats();

      // Reset repairable array
      this.set('repairables', []);

      // Grab the ids for all of the existing records to
      var recordIds = A();

      model.forEach(function (recordList) {
        var ids = recordList.map(function (record) {
          return record.get('id');
        });
        recordIds.addObjects(ids);

        // Add to stats
        recordList.forEach(function (record) {
          self.get('statisticsService').incrementRecordStat(record, 'count');
        });
      });
      this.set('recordIds', recordIds);

      // Turn the model into a single array of records to iterate and find relations to test
      var records = A();
      model.forEach(function (recordList) {
        records.addObjects(recordList);
      });
      this.set('records', records);

      // Find relations that have record references not in the set of found records
      // We'll want to work with ds-references to fetch ids without loading the records
      // https://blog.emberjs.com/2016/05/03/ember-data-2-5-released.html#toc_code-ds-references-code
      //
      // // get the id of the author without triggering a request
      // var authorId = post.belongsTo("author").id();
      //
      // // get all ids without triggering a request
      // var commentIds = post.hasMany('comments').ids();
      //
      // The records will have references to all of their relationship types BUT if the relationship was never
      // set for the record we get a null result when fetching an id(s).
      // This is a different issue, potentially a serious one if the record is always supposed to be created with
      // or to maintain a relationship.
      records.forEach(function (record) {
        record.eachRelationship(function (name, descriptor) {
          if (descriptor.kind === 'hasMany') {
            var ids = record.hasMany(name).ids();
            // Check ids exist in the set of known ids
            var verifiedIds = [];
            var missingIds = [];
            ids.forEach(function (id) {
              if (isEmpty(id)) {
                self.get('statisticsService').incrementRecordStat(record, 'emptyHasManyCount');
              } else if (recordIds.includes(id)) {
                verifiedIds.push(id);
                self.get('statisticsService').incrementRecordStat(record, 'verifiedHasManyCount');
              } else {
                missingIds.push(id);

                // Add to stats
                self.get('statisticsService').incrementRecordStat(record, 'missingHasManyRecordCount');
              }
            });

            if (isPresent(missingIds)) {
              // Add record to array of actionable items
              var repairable = {
                record: record,
                id: record.get('id'),
                recordType: record.get('constructor.modelName'),
                relationshipType: descriptor.kind,
                relationshipModel: descriptor.type,
                relationship: name,
                verifiedIds: verifiedIds,
                missingIds: missingIds
              };
              self.get('repairables').addObject(repairable);
            }
          } else if (descriptor.kind === 'belongsTo' && !descriptor.options.inverse) {
            var id = record.belongsTo(name).id();
            if (isEmpty(id)) {
              // Add to stats
              self.get('statisticsService').incrementRecordStat(record, 'emptyBelongsToCount');
            } else if (recordIds.includes(id)) {
              // Add to stats
              self.get('statisticsService').incrementRecordStat(record, 'verifiedBelongsToCount');
            } else {
              // TODO:
              // Create a Repairable object type
              //
              // Add record to array of actionable items
              var _repairable = {
                record: record,
                id: record.get('id'),
                recordType: record.get('constructor.modelName'),
                relationshipType: descriptor.kind,
                relationshipModel: descriptor.type,
                relationship: name,
                missingIds: [id]
              };
              self.get('repairables').addObject(_repairable);

              // Add to stats
              self.get('statisticsService').incrementRecordStat(record, 'missingBelongsToRecordCount');
            }
          }
        });
      });
    },

    setupController: function setupController(controller) {
      controller.set('stats', this.get('statisticsService.records'));
      controller.set('statsArray', this.get('statisticsService.recordsAsArray'));
      controller.set('repairables', this.get('repairables'));
    }
  });
});