define('supr-web-ember-v2/controllers/authenticated/files', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    currentUserService: service(),
    flashService: service(),
    fileService: service(),
    selectedFile: null,
    isEditing: false,
    pick: false,

    actions: {
      upload: function upload() {
        this.set('pick', true);
      },
      download: function download(file) {
        this.get('fileService').download(file);
      },
      delete: function _delete(file) {
        var self = this;
        this.get('fileService').delete(file).then(function () {
          self.set('isEditing', false);
          // Make sure we're not on a preview route after the file is gone
          self.transitionToRoute('authenticated.files');
        });
      },
      edit: function edit() {
        this.set('isEditing', true);
      },
      finishEdit: function finishEdit() {
        this.set('isEditing', false);
      },
      fileSelected: function fileSelected(file) {
        return this.get('fileService').save(file);
      },
      onClose: function onClose() {
        this.set('pick', false);
      },
      onError: function onError(file, error) {
        this.get('flashService').error(error);
      }
    }
  });
});