define('supr-web-ember-v2/components/modals-container/check-confirm', ['exports', 'ember-bootstrap-modals-manager/components/modals-container/check-confirm'], function (exports, _checkConfirm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _checkConfirm.default;
    }
  });
});