define('supr-web-ember-v2/controllers/unauthenticated/password-reset', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    flashService: service(),
    firebaseService: service(),
    email: '',

    actions: {
      passwordReset: function passwordReset(email) {
        var self = this;
        // const actionCodeSettings = {
        //   url: `https://${config.firebase.authDomain}/finishReset`,
        //   handleCodeInApp: true,
        // };

        return this.get('firebaseService').auth()
        // Firebase 3.9.0 doesn't support action code settings.
        // The user resets their password and is then simply directed to login
        // again with it.
        // Calling the function with an extra argument was resulting in an uncaught error
        // that looked the same as the one for having an incorrect import for config in other
        // places.
        //
        // .sendPasswordResetEmail(email, actionCodeSettings)
        .sendPasswordResetEmail(email).then(function () {
          self.get('flashService').success('Password reset email sent');
          self.transitionToRoute('unauthenticated.login');
        }).catch(function (error) {
          self.get('flashService').error(error.message);
          console.log(error);
        });
      }
    }
  });
});