define('supr-web-ember-v2/routes/authenticated/dashboard', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var Route = Ember.Route;
  var service = Ember.inject.service;
  var hash = Ember.RSVP.hash;
  var all = Ember.RSVP.all;
  var isPresent = Ember.isPresent;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    currentUserService: service(),
    can: service(),
    flashService: service(),

    beforeModel: function beforeModel() {
      if (!this.get('can').can('access in app')) {
        this.get('flashService').error('Your account has been archived.\nPlease contact an administrator for support.');
        this.transitionTo('authenticated.unauthorized');
      }
    },

    model: function model() {
      var user = this.get('currentUserService.user');

      return hash({
        calendar: user.get('calendar') // we need to resolve this before events
      }).then(function () {
        var todos = user.get('todos');
        var events = user.get('calendar.events');
        var bookings = user.get('bookings');
        var notifications = user.get('notifications');
        var registration = user.get('registration');
        var registrations = user.get('registrations');
        return hash({
          user: user,
          todos: todos,
          events: events,
          bookings: bookings,
          notifications: notifications,
          registration: registration,
          registrations: registrations
        });
      });
    },
    afterModel: function afterModel(model) {
      var _this = this;

      var bookingRelations = [];
      if (isPresent(model.bookings)) {
        bookingRelations = model.bookings.map(function (booking) {
          return booking.loadRelations();
        });
      }

      var notificationTargetModels = model.notifications.filter(function (notification) {
        return notification.get('targetType') && notification.get('targetId');
      }).map(function (notification) {
        return _this.get('store').findRecord(notification.get('targetType'), notification.get('targetId'));
      });
      return all([].concat(_toConsumableArray(bookingRelations), _toConsumableArray(notificationTargetModels)));
    }
  });
});