define('supr-web-ember-v2/components/mt-booking-status', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['mt-booking-status'],

    status: computed('record.status', function () {
      return this.get('record.status');
    })
  });
});