define('supr-web-ember-v2/routes/authenticated/availability', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    flashService: service(),
    can: service(),
    currentUserService: service(),

    beforeModel: function beforeModel(transition) {
      if (this.get('can').cannot('viewAvailabilities in app')) {
        this.get('flashService').error("You don't have the necessary permissions to access " + transition.targetName);
        this.transitionTo('authenticated.unauthorized');
      }

      if (this.get('can').cannot('access in app')) {
        this.get('flashService').error('Your account has been archived.\nPlease contact an administrator for support.');
        this.transitionTo('authenticated.unauthorized');
      }
    },

    model: function model() {
      return this.get('currentUserService.user.timeSlots'); // Contiguous blocks of time guide is available
    },

    afterModel: function afterModel() {
      return this.get('currentUserService.user').get('reservations'); // load the guides reservations
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('guide', this.get('currentUserService.user'));
    }
  });
});