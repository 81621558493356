define('supr-web-ember-v2/routes/unauthenticated', ['exports', 'supr-web-ember-v2/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend({
    currentUserService: service(),
    academyService: service(),
    flashService: service(),
    session: service(),

    setupController: function setupController(controller, model) {
      this._super(model, controller);
      controller.set('collapsed', true);
    },


    actions: {
      signUp: function signUp() {
        this.transitionTo('unauthenticated.signup');
      },

      login: function login() {
        if (_environment.default.LOCAL_DEV) {
          this.get('session').authenticate('authenticator:torii', 'firebase', {
            provider: 'password',
            email: 'dan@example.com',
            password: 'pass'
          }).catch(function (reason) {
            console.error(reason);
          });
        } else {
          this.transitionTo('unauthenticated.login');
        }
      }
    }
  });
});