define('supr-web-ember-v2/abilities/todo', ['exports', 'ember-can'], function (exports, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = _emberCan.Ability.extend({
    currentUserService: service(),

    canView: computed('currentUserService.user.id', 'model.ownerID', function () {
      return this.get('currentUserService.user.id') === this.get('model.ownerID');
    }),
    canEdit: computed('currentUserService.user.id', 'model.ownerID', function () {
      return this.get('currentUserService.user.id') === this.get('model.ownerID');
    })
  });
});