define('supr-web-ember-v2/routes/authenticated/students/student/todos', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  var isPresent = Ember.isPresent;
  var all = Ember.RSVP.all;
  var EmberObject = Ember.Object;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    currentUserService: service(),
    flashService: service(),
    can: service(),
    // V1: NOTE: the following would be related to requiring adding controller and model to the
    //       function arguments list: renderTemplate: function(controller, model)
    // This ends up calling the controllers/todos controller whether we
    // specify it or not.
    // renderTemplate: function() {
    //   this.render('todos');
    // },

    beforeModel: function beforeModel() {
      if (this.get('can').cannot('access in app')) {
        this.get('flashService').error('Your account has been archived.\nPlease contact an administrator for support.');
        this.transitionTo('authenticated.unauthorized');
      }
    },

    model: function model() {
      var _this = this;

      var student = this.modelFor('authenticated.students.student');
      return student.get('todos').catch(function (err) {
        return _this.get('flashService').error(err.message);
      });
    },

    afterModel: function afterModel(model) {
      var _this2 = this;

      var self = this;
      var promises = [];
      // FIXME: V1:
      // This is related to Issue #11 https://bitbucket.org/supracademy/supracademy-app-client/issues/11/unable-to-add-todo-to-user-without-setting
      model.forEach(function (todo) {
        if (isPresent(todo.get('assignedById'))) {
          promises.push(todo.set('assignedBy', self.store.findRecord('guide', todo.get('assignedById'))));
        }
      });
      return all(promises).catch(function (err) {
        return _this2.get('flashService').error(err.message);
      });
    },

    setupController: function setupController(controller, model) {
      var todoModel = EmberObject.create({
        user: this.modelFor('authenticated.students.student'),
        guide: this.get('currentUserService.user'),
        todos: model,
        fields: {}
      });
      controller.set('model', model);
      controller.set('newTodoModel', todoModel);
    }
  });
});