define('supr-web-ember-v2/utils/grid-slot', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  var computed = Ember.computed;
  var isPresent = Ember.isPresent;
  var isEmpty = Ember.isEmpty;
  exports.default = EmberObject.extend({
    startTime: null,
    duration: null,
    bookings: null, // attach booking so we can get learning-session from it for new booking if required
    available: false,
    selected: false,
    endTime: computed('startTime', 'duration', function () {
      return (0, _moment.default)(this.get('startTime')).add(this.get('duration'), 'm').toDate();
    }),
    day: computed('startTime', function () {
      return _moment.default.weekdays()[(0, _moment.default)(this.get('startTime')).day()];
    }),
    displayTime: computed('startTime', function () {
      return (0, _moment.default)(this.get('startTime')).format('h:mm a');
    }),
    booked: computed('booking', function () {
      return isPresent(this.get('booking'));
    }),
    studentNames: computed('bookings.@each', function () {
      var studentNames = '';
      if (isPresent(this.get('bookings'))) {
        var bookings = this.get('bookings').filter(function (booking) {
          return booking.get('isActive');
        });
        if (isEmpty(bookings)) {
          return null;
        }
        var length = bookings.get('length');
        bookings.forEach(function (booking, index) {
          studentNames = studentNames + booking.get('student.fullName');
          if (index + 1 < length) {
            studentNames = studentNames + ', ';
          }
        });
        return studentNames;
      } else {
        return null;
      }
    })
  });
});