define('supr-web-ember-v2/models/calendar', ['exports', 'ember-data', 'supr-web-ember-v2/models/base'], function (exports, _emberData, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    ownerID: _emberData.default.attr('string'), // temporary fix until we get polymorphism working
    name: _emberData.default.attr('string'),
    events: _emberData.default.hasMany('calendar-events', {
      async: true
    })
  });
});