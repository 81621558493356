define('supr-web-ember-v2/models/guardian', ['exports', 'ember-data', 'supr-web-ember-v2/models/user', 'supr-web-ember-v2/mixins/user-roles'], function (exports, _emberData, _user, _userRoles) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _user.default.extend(_userRoles.default, {
    students: _emberData.default.hasMany('student', {
      async: true
    }),
    registrations: _emberData.default.hasMany('registration', {
      async: true
    }),
    notifications: _emberData.default.hasMany('notification', {
      async: true
    }),

    hasMultipleActiveStudents: computed('students.@each.isActive', function () {
      var students = this.get('students');
      var activeStudentCount = students.reduce(function (accumulator, currentValue) {
        return currentValue.get('isActive') ? accumulator + 1 : accumulator;
      }, 0);

      return activeStudentCount > 1;
    })
  });
});