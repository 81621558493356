define('supr-web-ember-v2/routes/authenticated/admin/academy', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var all = Ember.RSVP.all;
  exports.default = Route.extend({
    model: function model() {
      return this.store.findAll('academy').then(function (academies) {
        return academies.get('firstObject');
      });
    },
    afterModel: function afterModel(model) {
      return all([model.get('schedule'), model.get('address'), model.get('calendar')]);
    }
  });
});