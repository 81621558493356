define('supr-web-ember-v2/models/learning-session', ['exports', 'ember-data', 'supr-web-ember-v2/models/base', 'moment'], function (exports, _emberData, _base, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Promise = Ember.RSVP.Promise;
  exports.default = _base.default.extend({
    // Chapter/Concept Review, Exam Prep, SSAT Prep, EQAO Prep, General Review
    sessionType: _emberData.default.attr('string', {
      defaultValue: 'General Review'
    }),
    sessionOnline: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    // FIXME V1:
    // Should come from bookings?
    students: _emberData.default.hasMany('student', {
      async: true
    }),
    // FIXME V1:
    // Should come from bookings?
    guide: _emberData.default.belongsTo('guide', {
      async: true
    }),
    bookings: _emberData.default.hasMany('booking', {
      async: true
    }),
    files: _emberData.default.hasMany('file', {
      async: true
    }),

    // FIXME V1:
    // New students function to replace property
    // This will reduce the number of objects we have to look up when handling Bookings.
    // Making this change means we'll have to update the seeds and registration code plus
    // anything else that references Learning Sessions.
    //
    // students: function() {
    //   return Ember.RSVP.Promise(function(resolve, reject){
    //     this.get('bookings').then(function(bookings){
    //       var students = [];
    //       for(var i=0; i<bookings.get('length'); i++){
    //         bookings.objectAt(i).get('student').then(function(theStudent){
    //           students.addObject(theStudent);
    //           if(i === bookings.get('length') ){
    //             resolve(students);
    //           }
    //         });
    //       }
    //     });
    //   });
    // }.property('bookings.@each.students'),
    //
    // FIXME V1:
    // Nnew students function to replace property
    // Can only be one guide for a Learning Session so we can safely grab the first one
    // guide: function() {
    //   return Ember.RSVP.Promise(function(resolve, reject){
    //     this.get('bookings').then(function(bookings){
    //       bookings.get('firstObject.guide').then(function(theGuide){
    //         resolve(theGuide);
    //       });
    //     });
    //   });
    // }.property('bookings.@each.guide'),

    displayCreatedAtDate: computed('createdAt', function () {
      return (0, _moment.default)(this.get('createdAt')).format('ddd, MMM Do, h:mm a z');
    }),

    // FIXME V1:
    // Add reject error handling
    startTime: computed('bookings.@each.startTime', function () {
      return Promise(function (resolve) {
        this.get('bookings').then(function (bookings) {
          resolve(bookings.get('firstObject.startTime'));
        });
      });
    }),

    // FIXME V1:
    // Aadd reject error handling
    duration: computed('bookings.@each.duration', function () {
      return Promise(function (resolve) {
        this.get('bookings').then(function (bookings) {
          resolve(bookings.get('firstObject.duration'));
        });
      });
    })
  });
});