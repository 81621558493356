define('supr-web-ember-v2/routes/authenticated/admin/students', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var all = Ember.RSVP.all;
  exports.default = Route.extend({
    model: function model() {
      return this.store.findAll('student');
    },

    afterModel: function afterModel(model) {
      var bookings = [];
      model.forEach(function (student) {
        bookings.pushObject(student.get('bookings'));
      });
      return all(bookings);
    },

    setupController: function setupController(controller, model) {
      model.forEach(function (student) {
        student.set('hasActiveBookings', false);
        student.get('bookings').then(function (theBookings) {
          theBookings.forEach(function (booking) {
            if (booking.get('isActive')) {
              student.set('hasActiveBookings', true);
            }
          });
        });
      });
      controller.set('model', model);
      controller.set('allStudents', model);
      controller.set('students', controller.get('activeStudents'));
    }
  });
});