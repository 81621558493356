define('supr-web-ember-v2/routes/authenticated/students/student', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var EmberObject = Ember.Object;
  exports.default = Route.extend({
    setupController: function setupController(controller) {
      var NavItem = EmberObject.extend({
        route: null,
        name: null
      });
      controller.set('nav', [NavItem.create({
        route: 'authenticated.students.student.dashboard',
        name: 'Dashboard'
      }), NavItem.create({
        route: 'authenticated.students.student.profile',
        name: 'Profile'
      }), NavItem.create({
        route: 'authenticated.students.student.todos',
        name: 'Todos'
      }), NavItem.create({
        route: 'authenticated.students.student.files',
        name: 'Files'
      })]);
    }
  });
});