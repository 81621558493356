define('supr-web-ember-v2/components/booking-day-list', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['booking-day-list'],

    days: computed('week', function () {
      // Break the weeks Bookings up into days
      var days = {};

      var bookings = this.get('week.bookings');

      if (bookings) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = bookings[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var b = _step.value;

            var day = b.get('startTime');
            var isToday = false;

            var start = (0, _moment.default)(day);
            if (start.isSame((0, _moment.default)(), 'day')) {
              isToday = true;
            }

            if (days[day]) {
              days[day].bookings.push(b);
            } else {
              days[day] = { date: day, isToday: isToday, bookings: [b] };
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }

      return Object.values(days);
    }),

    actions: {
      confirm: function confirm(booking) {
        this.get('confirm')(booking);
      },
      cancel: function cancel(booking) {
        this.get('cancel')(booking);
      }
    }
  });
});