define('supr-web-ember-v2/services/page-progress', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var get = Ember.get;
  var set = Ember.set;
  var setProperties = Ember.setProperties;
  var later = Ember.run.later;


  var SPEED = 200;
  var MINIMUM = 0.08;
  var BACKGROUND = 'red';
  var pendingEvents = [];
  var next = function next() {
    var pendingEvent = pendingEvents.shift();
    if (pendingEvent) {
      pendingEvent(next);
    }
  };

  var queue = function queue(fn) {
    pendingEvents.push(fn);
    next();
  };

  exports.default = Service.extend({
    router: service(),
    speed: undefined,
    minimum: undefined,
    barStyle: undefined,
    background: undefined,
    status: undefined,
    target: undefined,
    isLoaded: false,

    setConfig: function setConfig(config) {
      var configKeys = Object.keys(config);

      for (var i = 0; i < configKeys.length; i = i + 1) {
        var value = config[configKeys[i]];

        if (typeof value !== 'undefined') {
          set(this, configKeys[i], value);
        }
      }

      set(this, 'isLoaded', true);
    },
    start: function start(target) {
      if (!get(this, 'isLoaded')) {
        return;
      }

      if (!this.isStarted() || get(this, 'target') !== target) {
        this.setStatus(0);
        setProperties(this, {
          barStyle: 'transition: none; width: 0;',
          target: target
        });
      }

      this.update();
    },
    update: function update() {
      var _this = this;

      var speed = get(this, 'speed') || SPEED;

      later(function () {
        if (!_this.isStarted()) {
          return;
        }

        _this.trickle();
        _this.update();
      }, speed);
    },
    trickle: function trickle(amount) {
      var status = get(this, 'status');
      var currentAmount = amount;

      if (!status) {
        return this.start();
      } else if (status > 1) {
        return;
      } else {
        if (typeof currentAmount !== 'number') {
          if (status >= 0 && status < 0.2) {
            currentAmount = 0.1;
          } else if (status >= 0.2 && status < 0.5) {
            currentAmount = 0.04;
          } else if (status >= 0.5 && status < 0.8) {
            currentAmount = 0.02;
          } else if (status >= 0.8 && status < 0.99) {
            currentAmount = 0.005;
          } else {
            currentAmount = 0;
          }
        }

        status = this.clamp(status + currentAmount, 0, 0.998);

        return this.setStatus(status);
      }
    },
    isStarted: function isStarted() {
      return typeof get(this, 'status') === 'number';
    },
    setStatus: function setStatus(status) {
      var _this2 = this;

      var speed = get(this, 'speed') || SPEED;
      var minimum = get(this, 'minimum') || MINIMUM;
      var background = get(this, 'background') || BACKGROUND;
      var started = !this.isStarted();
      var currentStatus = this.clamp(status, minimum, 1);

      setProperties(this, {
        status: currentStatus === 1 ? undefined : currentStatus,
        barStyle: 'transition: width ' + speed + 'ms linear;\nwidth: ' + (started ? '0' : this.toBarPerc(get(this, 'status'))) + '%;\nbackground: ' + background
      });

      queue(function (next) {
        if (currentStatus === 1) {
          set(_this2, 'barStyle', 'transition: width ' + speed + 'ms linear; width: 100%; background: ' + background);

          later(function () {
            set(_this2, 'barStyle', 'transition: opacity ' + speed * 2 + 'ms linear; width: 100%; opacity: 0; background: ' + background);
            next();
          }, speed);
        } else {
          later(next, speed);
        }
      });
    },
    done: function done() {
      if (typeof get(this, 'status') === 'undefined') {
        return;
      }

      this.trickle(0.3 + 0.5 * Math.random());
      this.setStatus(1);
    },
    clamp: function clamp(status, min, max) {
      if (status < min) return min;
      if (status > max) return max;
      return status;
    },
    toBarPerc: function toBarPerc(status) {
      return status * 100;
    }
  });
});