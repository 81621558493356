define('supr-web-ember-v2/components/models-table/cell-edit-toggle', ['exports', 'ember-models-table/components/models-table/cell-edit-toggle'], function (exports, _cellEditToggle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _cellEditToggle.default;
    }
  });
});