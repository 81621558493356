define('supr-web-ember-v2/components/flash-message', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var later = Ember.run.later;
  var getOwner = Ember.getOwner;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: 'li',
    classNames: ['flash-message'],
    classNameBindings: ['type'],
    layoutName: 'components/flash-message',
    fadeout: true,
    flashService: computed(function () {
      return getOwner(this).lookup('service:flash-service');
    }),

    didInsertElement: function didInsertElement() {
      this.$().hide();
      this.$().fadeIn();

      if (this.get('fadeout') && this.get('type') !== 'error') {
        later(this, 'removeAndDestroy', 4000);
      }
    },

    removeAndDestroy: function removeAndDestroy() {
      var self = this;

      if (!(self.get('isDestroyed') || self.get('isDestroying'))) {
        try {
          this.$().fadeOut('fast', function () {
            if (!self.get('removeErrorAction')) {
              self.destroy(); // destroy component
              self.get('flashService').removeMessage(self.get('msgId')); // remove from service
            } else {
              self.sendAction('removeErrorAction');
            }
          });
        } catch (e) {
          console.error(e);
        }
      }
    },

    click: function click() {
      this.removeAndDestroy();
    }
  });
});