define('supr-web-ember-v2/controllers/authenticated/students/student/todos', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var alias = Ember.computed.alias;
  var filterBy = Ember.computed.filterBy;
  var filter = Ember.computed.filter;
  var sort = Ember.computed.sort;
  var isPresent = Ember.isPresent;
  var isEmpty = Ember.isEmpty;
  var service = Ember.inject.service;
  var debug = Ember.debug;
  exports.default = Controller.extend({
    store: service(),
    flashService: service(),
    notifyService: service(),
    currentUserService: service(),
    showModal: false,
    selectedTodo: null,
    priorities: null,
    selectedPriority: 'Medium', // Default
    // V1: Added this because I can't figure out how to get preparedTodos to update
    // just by having the model size change (delete todos)
    // tried using .property('model.length') but it doesn't work
    numTodo: alias('model.length'),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('priorities', ['High', 'Medium', 'Low']);
      this.set('_sort', ['sortDate']);
    },

    reset: function reset() {
      this.set('showModal', false);
      var todo = this.get('newTodoModel');
      if (isPresent(todo)) {
        todo.set('fields', {});
      }
      this.set('selectedPriority', 'Medium');
      this.set('selectedTodo', null);
    },

    preparedTodos: computed('numTodo', function () {
      return this.get('model').map(function (todo) {
        todo.set('displayDate', (0, _moment.default)(todo.get('sortDate')).format('dddd, MMMM Do YYYY, h:mm a'));
        todo.set('icon', 'fa fa-circle-o fa-2x fa-fw'); // TODO: moves into template
        return todo;
      });
    }),

    _sort: null,
    incompleteTodos: filterBy('preparedTodos', 'status', 'Incomplete'),
    sortedIncompleteTodos: sort('incompleteTodos', '_sort'),

    completeTodos: filter('preparedTodos.@each.status', function (todo) {
      return todo.get('status') !== 'Incomplete';
    }),
    sortedCompleteTodos: sort('completeTodos', '_sort'),

    actions: {
      new: function _new() {
        this.set('showModal', true);
      },
      setPriority: function setPriority(priority) {
        this.set('selectedPriority', priority);
      },
      error: function error(msg) {
        this.get('flashService').error(msg);
      },
      save: function save() {
        var newTodoModel = this.get('newTodoModel');
        var student = newTodoModel.user;
        var guide = newTodoModel.guide;
        var todos = newTodoModel.todos;
        var fields = newTodoModel.fields;
        var self = this;

        // TODO: Would be better if was handled by a form in the modal that could do
        //       validation
        if (isEmpty(fields.name)) {
          this.get('flashService').error('Name is required');
          return;
        }

        fields.ownerID = student.get('id');
        fields.priority = this.get('selectedPriority');
        fields.assignedById = guide.get('id');
        fields.lastModifiedByID = guide.get('id');

        // Just passing the Moment data to createRecord works fine for display but
        // the data didn't save to Firebase
        var newTodo = void 0;
        var todoRecord = this.get('store').createRecord('todo', fields);
        todos.addObject(todoRecord);
        todoRecord.save().then(function (theTodo) {
          newTodo = theTodo;
          return student.save();
        }).then(function () {
          self.reset();

          // Notifications
          self.get('notifyService').sendNotification([{
            model: 'student',
            id: student.get('id')
          }], guide.get('fullName') + ' created a new todo for you: ' + newTodo.get('name'), {
            route: 'authenticated.todos.todo',
            type: 'todo',
            id: newTodo.get('id')
          });
        }).catch(function (err) {
          debug('Create new Todo failed', +err);
          self.reset();
        });
      },
      cancel: function cancel() {
        this.reset();
      }
    }
  });
});