define('supr-web-ember-v2/controllers/authenticated/bookings', ['exports', 'supr-web-ember-v2/utils/booking-helper', 'supr-web-ember-v2/config/constants'], function (exports, _bookingHelper, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    currentUserService: service(),
    notifyService: service(),
    flashService: service(),

    actions: {
      // TODO move these functions into a Booking Service so they can be called
      // from anywhere.
      confirm: function confirm(booking) {
        var self = this;
        booking.set('status', _constants.default.booking.status.CONFIRMED);
        booking.save().catch(function (err) {
          return self.get('flashService').error('Failed to save Booking ' + err);
        });
      },

      cancel: function cancel(booking) {
        var self = this;
        var student = void 0;
        booking.set('status', _constants.default.booking.status.CANCELLED);
        booking.set('cancelledOn', new Date());
        booking.set('cancelledBy', self.get('currentUserService.user.userType') + ' ' + self.get('currentUserService.user.fullName'));
        booking.save();
        _bookingHelper.default.safeDestroyLearningSession(booking).then(function () {
          return booking.get('student');
        }).then(function (theStudent) {
          student = theStudent;
          return booking.get('guide');
        }).then(function (theGuide) {
          var recipients = [{ model: 'student', id: student.get('id') }, { model: 'guide', id: theGuide.get('id') }];
          var guardians = student.get('guardians');
          guardians.forEach(function (guardian) {
            recipients.addObject({ model: 'guardian', id: guardian.get('id') });
          });
          self.get('notifyService').sendNotification(recipients, 'The booking for ' + student.get('fullName') + ' with ' + theGuide.get('fullName') + ' on ' + booking.get('displayDate') + '\n               has been cancelled.', { route: 'bookings' });
        });
      }
    }
  });
});