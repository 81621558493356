define('supr-web-ember-v2/models/academy', ['exports', 'ember-data', 'supr-web-ember-v2/models/base'], function (exports, _emberData, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    name: _emberData.default.attr('string'),
    schools: _emberData.default.hasMany('school', {
      async: true
    }),
    calendar: _emberData.default.belongsTo('calendar', {
      async: true
    }),
    address: _emberData.default.belongsTo('address', {
      async: true
    }),
    schedule: _emberData.default.belongsTo('academy-schedule', {
      async: true
    })
  });
});