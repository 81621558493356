define('supr-web-ember-v2/components/modals-container/base', ['exports', 'ember-bootstrap-modals-manager/components/modals-container/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _base.default;
    }
  });
});