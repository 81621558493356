define('supr-web-ember-v2/controllers/authenticated/admin/create-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    currentUserService: service(),

    queryParams: ['userType'],
    userType: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('userType', 'Student');
      this.set('userTypes', ['Student', 'Guardian', 'Guide']);
    },

    isGuide: computed('userType', function () {
      return this.get('userType') === 'Guide';
    }),
    isGuardian: computed('userType', function () {
      return this.get('userType') === 'Guardian';
    }),
    isStudent: computed('userType', function () {
      return this.get('userType') === 'Student';
    })
  });
});