define('supr-web-ember-v2/controllers/authenticated/dashboard', ['exports', 'moment', 'supr-web-ember-v2/config/constants'], function (exports, _moment, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var A = Ember.A;
  var computed = Ember.computed;
  var sort = Ember.computed.sort;
  var isPresent = Ember.isPresent;
  var isEmpty = Ember.isEmpty;
  var service = Ember.inject.service;
  var all = Ember.RSVP.all;
  exports.default = Controller.extend({
    flashService: service(),
    notifyService: service(),
    currentUserService: service(),

    _nextSort: null, // ascending is default
    _pastSort: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('_nextSort', ['sortDate']);
      this.set('_pastSort', ['sortDate']);
    },


    unregistered: computed(function () {
      var user = this.model.user;
      if (user.get('isStudent')) {
        return isEmpty(this.model.registration) && user.get('requiresRegistration');
      }
      if (user.get('isGuardian')) {
        return isEmpty(this.model.registrations) && user.get('requiresRegistration');
      }
      return false;
    }),

    notifications: computed(function () {
      return this.model.notifications;
    }),

    sortedNotifications: sort('notifications', function (a, b) {
      if ((0, _moment.default)(a.get('timestamp')).isBefore((0, _moment.default)(b.get('timestamp')))) {
        return 1;
      } else if ((0, _moment.default)(a.get('timestamp')).isAfter((0, _moment.default)(b.get('timestamp')))) {
        return -1;
      }
      return 0;
    }),

    // model.bookings.[] is supposed to just watch for push and pop
    // model.bookings.@each is supposed to watch for changes in each booking as well
    // model.bookings.@each.guide should presumbably watch for changes on the guide as well
    // none are triggering proper update on dashboard, still ending up with undefined undefined even thougn
    // the record is in the store
    // So, the issue is that user.fullName is being re-computed while the record is inflight, being reloaded.
    // Fix was to add student.fullName and guide.fullName to the booking.isWith property observers
    aggregateData: computed('model.{todos.[],events.[],bookings.@each}', function () {
      var stream = A();
      var todos = this.model.todos;
      var events = this.model.events;
      var bookings = this.model.bookings;

      if (isPresent(todos)) {
        todos.forEach(function (todo) {
          todo.set('type', 'todo');
          todo.set('icon', 'fa fa-check-circle-o fa-2x fa-fw'); // TODO: put this in config
          todo.set('isTodo', true); // probably use category somehow here
          stream.pushObject(todo);
        });
      }

      if (isPresent(events)) {
        events.forEach(function (event) {
          event.set('type', 'event');
          event.set('icon', 'fa fa-calendar-o fa-2x fa-fw'); // TODO: put this in config
          event.set('isEvent', true); // probably use category somehow here
          stream.pushObject(event);
        });
      }

      if (isPresent(bookings)) {
        bookings.forEach(function (booking) {
          var status = booking.get('status');
          if (status === _constants.default.booking.status.CANCELLED || status.lastIndexOf('MISSED', 0) === 0) {
            booking.set('hasReason', true);
            var reason = status.toUpperCase().replace(/-/i, '_');
            booking.set('reason', _constants.default.booking.reason[reason]);
          }
          booking.set('type', 'booking');
          booking.set('icon', 'fa fa-pencil-square-o fa-2x fa-fw'); // TODO: put this in config
          booking.set('isBooking', true);
          stream.pushObject(booking);
        });
      }

      return stream;
    }),

    nextItems: computed('aggregateData', 'model.todos.@each.status', function () {
      var items = this.get('aggregateData');
      // filter
      function nextFilterFunction(item) {
        var included = false;
        included = item.get('sortDate') >= new Date();
        // If a todo is overdue it should still show up in the Next list unless
        // it is complete.
        if (item.constructor.modelName === 'todo') {
          if (item.get('status') && item.get('status') !== _constants.default.todo.status.COMPLETE) {
            included = false;
          }
          if (item.get('status') && item.get('status') === _constants.default.todo.status.INCOMPLETE) {
            included = true;
          }
        }
        if (item.constructor.modelName === 'booking') {
          if (item.get('status') && item.get('status') !== _constants.default.booking.status.CONFIRMED) {
            included = false;
          }
        }
        return included;
      }
      return items.filter(nextFilterFunction);
    }),
    sortedNextItems: sort('nextItems', '_nextSort'),

    pastItems: computed('aggregateData', 'model.todos.@each.status', function () {
      var items = this.get('aggregateData');

      // filter
      function pastFilterFunction(item) {
        var included = false;
        included = item.get('sortDate') < new Date();
        if (included && item.get('status') && item.get('status') === 'Incomplete') {
          included = false;
        }

        if (item.constructor.modelName === 'booking') {
          if (item.get('status') && item.get('status') === _constants.default.booking.status.PENDING) {
            included = false;
          }
        }

        return included;
      }
      return items.filter(pastFilterFunction);
    }),
    sortedPastItems: sort('pastItems', '_pastSort'),

    actions: {
      // IMPROVE: V1:
      // This is almost identical to controllers/todos complete action
      complete: function complete(todo) {
        var self = this;
        todo.set('status', 'Complete');
        todo.set('completedDate', new Date());
        todo.save().then(function (theTodo) {
          return theTodo.get('assignedById');
        }).then(function (assignedById) {
          if (isPresent(assignedById)) {
            // Send notifications
            self.get('notifyService').sendNotification([{ model: 'guide', id: assignedById }], self.get('currentUserService.user.fullName') + ' completed: ' + todo.get('name'), {
              route: 'students.student',
              type: 'student',
              id: todo.get('ownerID')
            });
            // Send email
            var promises = [self.store.findRecord('student', todo.get('ownerID')), self.store.findRecord('guide', assignedById)];
            all(promises).then(function (results) {
              var theStudent = results[0];
              var theGuide = results[1];
              var email = theGuide.get('email');
              if (isPresent(email)) {
                // const template =
                //   self.mailHelper.templates.STUDENT_COMPLETED_TODO;
                // const subject =
                //   theStudent.get('fullName') + ' completed a todo';
                // const origin =
                //   typeof window.location.origin !== 'undefined'
                //     ? window.location.origin
                //     : window.location.protocol + '//' + window.location.host;
                // const url =
                //   origin +
                //   '/#/students/' +
                //   theStudent.get('id') +
                //   '/todos/' +
                //   todo.get('id');
                // const context = {
                //   guide_full_name: theGuide.get('fullName'),
                //   student_full_name: theStudent.get('fullName'),
                //   todo_name: todo.get('name'),
                //   todo_url: url,
                // };
                // self.mailHelper.sendMail(email, subject, context, template);
              }
            });
          }
        });
      }
    }
  });
});