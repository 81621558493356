define('supr-web-ember-v2/event_dispatcher', ['exports', 'ember-gestures/event_dispatcher', 'supr-web-ember-v2/config/environment'], function (exports, _event_dispatcher, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var merge = Ember.merge;
  var _assign = Ember.assign;


  var assign = _assign || merge;

  var gestures = assign({}, {
    emberUseCapture: false,
    removeTracking: false,
    useFastPaths: false
  });
  gestures = assign(gestures, _environment.default.gestures);

  exports.default = _event_dispatcher.default.extend({
    useCapture: gestures.emberUseCapture,
    removeTracking: gestures.removeTracking,
    useFastPaths: gestures.useFastPaths
  });
});