define('supr-web-ember-v2/routes/authenticated/admin/guides', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  var all = Ember.RSVP.all;
  exports.default = Route.extend({
    flashService: service(),

    model: function model() {
      return this.store.findAll('guide');
    },

    afterModel: function afterModel(model) {
      return all(model.map(function (guide) {
        return guide.get('bookings');
      }));
    },

    setupController: function setupController(controller, model) {
      model.forEach(function (guide) {
        guide.set('hasActiveBookings', false);
        guide.get('bookings').then(function (theBookings) {
          theBookings.forEach(function (booking) {
            if (booking.get('isActive')) {
              guide.set('hasActiveBookings', true);
            }
          });
        });
      });
      controller.set('model', model);
      controller.set('allGuides', model);
      controller.set('guides', controller.get('activeGuides'));
    }
  });
});