define('supr-web-ember-v2/routes/unauthenticated/new-account', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  var debug = Ember.debug;
  exports.default = Route.extend(_unauthenticatedRouteMixin.default, {
    session: service(),
    currentUserService: service(),
    flashService: service(),
    userService: service(),

    beforeModel: function beforeModel(transition) {
      this.get('session').set('willSignUp', true);
      this._super(transition);
    },

    setupController: function setupController(controller) {
      controller.set('fields', {});
    },

    actions: {
      willTransition: function willTransition() {
        this.get('session').set('willSignUp', false);
      },

      createUser: function createUser(controller) {
        var self = this;

        this.send('showLoadingSpinner');

        var userType = controller.get('userType');
        if (controller.get('isMature')) {
          userType = 'student';
          controller.set('fields.mature', true);
        }
        return this.get('userService').createUser(userType, controller.get('fields')).then(function () {
          return self.get('userService').authenticate(controller.get('fields.email'), controller.get('fields.password'));
        }).then(function () {
          self.get('flashService').success('Welcome to SuprAcademy!');
        }).catch(function (err) {
          console.error(err);
          self.send('hideLoadingSpinner');

          // Do nothing for password and email errors
          if (err === 'INVALID_PASSWORD' || err === 'INVALID_EMAIL') {
            debug(err);
            return;
          } else {
            debug(err);
            self.get('flashService').error('Failed to create new account');
          }
        });
      }
    }
  });
});