define('supr-web-ember-v2/components/flash-messenger-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var getOwner = Ember.getOwner;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: 'ul',
    classNames: ['flash-messages'],

    flashService: computed(function () {
      return getOwner(this).lookup('service:flash-service');
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('messages', this.get('flashService.messages'));
    }
  });
});