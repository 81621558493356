define('supr-web-ember-v2/mixins/user-roles', ['exports', 'supr-web-ember-v2/config/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var isPresent = Ember.isPresent;
  exports.default = {
    userType: computed(function () {
      return this.constructor.modelName;
    }),

    // User Types
    isStudent: computed('userType', function () {
      return this.get('userType') === 'student';
    }),
    isGuide: computed('userType', function () {
      return this.get('userType') === 'guide';
    }),
    isGuardian: computed('userType', function () {
      return this.get('userType') === 'guardian';
    }),

    // User Roles
    isGuideMentor: computed('roles.@each', function () {
      var result = false;
      var roles = this.get('roles');
      if (isPresent(roles)) {
        result = isPresent(roles.findBy('type', _constants.default.role.GUIDE_MENTOR));
      }
      return result ? result : false;
    }),
    isAdmin: computed('roles.@each', function () {
      var result = false;
      var roles = this.get('roles');
      if (isPresent(roles)) {
        result = isPresent(roles.findBy('type', _constants.default.role.ADMIN));
      }
      return result ? result : false;
    }),
    isSuperAdmin: computed('roles.@each', function () {
      var result = false;
      var roles = this.get('roles');
      if (isPresent(roles)) {
        result = isPresent(roles.findBy('type', _constants.default.role.SUPER));
      }
      return result ? result : false;
    }),

    // Roll Properties
    requiresRegistration: computed('isStudent', 'isGuardian', function () {
      return this.get('isStudent') || this.get('isGuardian');
    })
  };
});