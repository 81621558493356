define('supr-web-ember-v2/models/file', ['exports', 'ember-data', 'supr-web-ember-v2/models/base', 'moment'], function (exports, _emberData, _base, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _base.default.extend({
    fileType: _emberData.default.attr('string'), // ['image', 'text']
    // The list of users allowed to access this File
    // [USER_ID_1, USER_ID_2, ...]
    accessList: _emberData.default.attr('string'),
    isImage: computed('fileType', function () {
      return this.get('fileType') === 'image';
    }),
    isText: computed('fileType', function () {
      return this.get('fileType') === 'text';
    }),
    url: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    storagePath: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    ownerID: _emberData.default.attr('string'),
    // Set if Guide uploads for a Student
    uploaderID: _emberData.default.attr('string'),
    category: _emberData.default.belongsTo('category', {
      async: true
    }),
    tags: _emberData.default.hasMany('tag', {
      async: true
    }),
    displayCreatedAtDate: computed('createdAt', function () {
      return (0, _moment.default)(this.get('createdAt')).format('ddd, MMM Do, h:mm a z');
    })
  });
});