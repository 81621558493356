define('supr-web-ember-v2/utils/archive-helper', ['exports', 'supr-web-ember-v2/utils/promise-helper', 'supr-web-ember-v2/utils/booking-helper'], function (exports, _promiseHelper, _bookingHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var allSettled = Ember.RSVP.allSettled;
  var all = Ember.RSVP.all;
  var debug = Ember.debug;
  var isPresent = Ember.isPresent;
  exports.default = {
    // V1: TODO: Is it enough to set the Booking to cancelled for the Students or
    //       do we need a type of notification that is sent to the Student to
    //       let them know that their Bookings have been cancelled?
    _cancelBookings: function _cancelBookings(bookingsPromise) {
      var promises = [];
      return bookingsPromise.then(function (theBookings) {
        if (isPresent(theBookings)) {
          theBookings.forEach(function (booking) {
            if (booking.get('status') === 'confirmed' || booking.get('status') === 'pending') {
              booking.set('status', 'cancelled');
              promises.push(booking.save());
            }
          });
        }
        return all(promises);
      });
    },

    // Call and complete before _cancelBookings where necessary as BookingHelper.safeDestroyReservation
    // will delete some Bookings.
    _cancelReservations: function _cancelReservations(reservationsPromise) {
      var promises = [];
      return reservationsPromise.then(function (theReservations) {
        if (isPresent(theReservations)) {
          theReservations.forEach(function (reservation) {
            promises.push(_bookingHelper.default.safeDestroyReservation(reservation, {
              pending: true,
              confirmed: true
            }));
          });
        }
        return all(promises);
      });
    },

    _markRegistrationInactive: function _markRegistrationInactive(registrationPromise) {
      var promises = [];
      return registrationPromise.then(function (registration) {
        // A student can be created without a Registration so check
        if (isPresent(registration)) {
          registration.set('isActive', false);
          promises.push(registration.save());
        }
        return all(promises);
      });
    },

    _markRegistrationActive: function _markRegistrationActive(registrationPromise) {
      var promises = [];
      return registrationPromise.then(function (registration) {
        // A student can be created without a Registration so check
        if (isPresent(registration)) {
          registration.set('isActive', true);
          promises.push(registration.save());
        }
        return promises;
      });
    },

    _deleteTimeSlots: function _deleteTimeSlots(timeSlotsPromise) {
      var promises = [];
      var slotsToDelete = [];
      return timeSlotsPromise.then(function (timeSlots) {
        if (isPresent(timeSlots)) {
          timeSlots.forEach(function (timeSlot) {
            slotsToDelete.pushObject(timeSlot);
          });
          slotsToDelete.forEach(function (slot) {
            promises.push(slot.destroyRecord());
          });
        }
        return all(promises);
      });
    },

    /**
     * - archiving a student cancels (deletes) bookings, marks reservations as inactive,
     *   archives the registration
     * - the guardian must be specifically archived separately if desired
     *
     * - set isActive property to false
     * - cancel existing Bookings (which may have a Reservation)
     * - delete (V1: TODO: mark inactive) reservations (which may have associated Bookings)
     */
    archiveStudent: function archiveStudent(student) {
      var self = this;
      var promises = [];
      // Cancel the Reservations first as they also remove Bookings
      return this._cancelReservations(student.get('reservations')).then(function () {
        promises.addObject(self._cancelBookings(student.get('bookings')));
        promises.addObject(self._markRegistrationInactive(student.get('registration')));
        student.set('isActive', false);
        promises.addObject(student.save());
        return allSettled(promises);
      }).then(function (results) {
        _promiseHelper.default.logSettledErrors(results);
        return _promiseHelper.default.combineSettledPromise(results);
      }).catch(function (err) {
        // NOTE: Not sure if we get a better error from Bugsnag if let it catch the failed promise instead
        //       from _cancelReservations
        console.error(err);
        debug(err);
      });
    },

    unarchiveStudent: function unarchiveStudent(student) {
      var self = this;
      var promises = [];

      promises.addObject(self._markRegistrationActive(student.get('registration')));
      student.set('isActive', true);
      promises.addObject(student.save());

      return allSettled(promises).then(function (results) {
        _promiseHelper.default.logSettledErrors(results);
        return _promiseHelper.default.combineSettledPromise(results);
      });
    },

    /**
     * - archiving a guardian archives the registration and students unless
     *   there is more than 1 active guardian for a student in a registraion
     * - A Student can exist without any Guardian as a self-managed Student
     * - we can only archive the registration if the student is archived
     *
     * - set isActive to false
     * - cancel existing Student Bookings (which may have a Reservation)
     * - delete (V1: TODO: mark inactive) Student reservations (which may have associated Bookings)
     * - set Student isActive to false
     * - archive registrations
     */
    archiveGuardian: function archiveGuardian(guardian) {
      var self = this;
      var studentList = [];
      var firstPromises = [];
      var promises = [];

      return guardian.get('students').then(function (students) {
        if (isPresent(students)) {
          students.forEach(function (student) {
            // TODO: hasMultipleActiveGuardians is set in controller but should
            //       probably be a property on the student model
            if (!student.get('hasMultipleActiveGuardians')) {
              studentList.addObject(student);
              firstPromises.addObject(self._cancelReservations(student.get('reservations')));
            }
          });
        }
        return all(firstPromises);
      }).then(function () {
        studentList.forEach(function (student) {
          promises.addObject(self._cancelBookings(student.get('bookings')));

          promises.addObject(self._markRegistrationInactive(student.get('registration')));
          student.set('isActive', false);
          promises.addObject(student.save());
        });
        guardian.set('isActive', false);
        promises.addObject(guardian.save());
        return allSettled(promises);
      }).then(function (results) {
        _promiseHelper.default.logSettledErrors(results);
        return _promiseHelper.default.combineSettledPromise(results);
      });
    },

    /**
     * - Only unarchive the Guardian
     * - Students and Registrations need to be unarchived separately
     *   - If a Students registration is made active it will appear as active for
     *     the Guardian as well
     */
    unarchiveGuardian: function unarchiveGuardian(guardian) {
      var promises = [];
      guardian.set('isActive', true);
      promises.addObject(guardian.save());

      return allSettled(promises).then(function (results) {
        _promiseHelper.default.logSettledErrors(results);
        return _promiseHelper.default.combineSettledPromise(results);
      });
    },

    /**
     * - remove the guide from their students
     * - cancel existing Bookings (which may have a Reservation)
     * - delete (V1: TODO: mark inactive) reservations
     * - set inActive true
     * V1: TODO: archiving a Guide could be a multi-step process as one option would
     *       be to re-assign Bookings to another Guide
     */
    archiveGuide: function archiveGuide(guide) {
      var self = this;
      var promises = [];

      return this._cancelReservations(guide.get('reservations')).then(function () {
        // Remove Guide from Students
        var guideUpdate = guide.get('students').then(function (theStudents) {
          // V1: TODO: try guide.set('students', null);
          guide.get('students').removeObjects(theStudents);
          guide.set('isActive', false);
          return guide.save();
        });
        promises.addObject(guideUpdate);
        promises.addObject(self._cancelBookings(guide.get('bookings')));
        promises.addObject(self._deleteTimeSlots(guide.get('timeSlots')));
        return allSettled(promises);
      }).then(function (results) {
        _promiseHelper.default.logSettledErrors(results);
        return _promiseHelper.default.combineSettledPromise(results);
      });
    },

    unarchiveGuide: function unarchiveGuide(guide) {
      var promises = [];

      guide.set('isActive', true);
      promises.addObject(guide.save());

      return allSettled(promises).then(function (results) {
        _promiseHelper.default.logSettledErrors(results);
        return _promiseHelper.default.combineSettledPromise(results);
      });
    },

    /**
     * FIXME: V1:
     * ArchiveStudent is handling _cancelReservations properly but it's not happening here.
     *
     * - arvchiving a registration also archives the guardians and students
     *   - if the guardians have more than 1 active student/registration then they
     *     are not archived
     */
    archiveRegistration: function archiveRegistration(registration) {
      var self = this;
      var student = void 0;
      var promises = [];
      registration.set('isActive', false);
      promises.addObject(registration.save());

      promises.addObject(registration.get('student').then(function (s) {
        student = s;
        return self._cancelReservations(student.get('reservations'));
      }).then(function () {
        var studentPromises = [];
        studentPromises.addObject(self._cancelBookings(student.get('bookings')));
        student.set('isActive', false);
        studentPromises.addObject(student.save());
        return allSettled(studentPromises);
      }).then(function (results) {
        return _promiseHelper.default.combineSettledPromise(results);
      }));

      // Handle Guardians
      promises.addObject(registration.get('guardians').then(function (guardians) {
        var guardianPromises = [];
        if (isPresent(guardians)) {
          guardians.forEach(function (guardian) {
            guardianPromises.addObject(registration.get('student').then(function (student) {
              var regStudent = student;
              return guardian.get('students').then(function (students) {
                var hasAnotherActiveStudent = false;
                if (students.get('length') > 1) {
                  students.forEach(function (student) {
                    if (student.get('id') !== regStudent.get('id') && student.get('isActive')) {
                      hasAnotherActiveStudent = true;
                    }
                  });
                }
                guardian.set('isActive', hasAnotherActiveStudent);
                return guardian.save();
              });
            }));
          });
        }
        return allSettled(guardianPromises).then(function (results) {
          return _promiseHelper.default.combineSettledPromise(results);
        });
      }));

      return allSettled(promises).then(function (results) {
        _promiseHelper.default.logSettledErrors(results);
        return _promiseHelper.default.combineSettledPromise(results);
      });
    },

    /**
     * - unarchiving a registration unarchives student and guardian(s)
     */
    unarchiveRegistration: function unarchiveRegistration(registration) {
      var promises = [];
      registration.set('isActive', true);
      promises.addObject(registration.save());

      return registration.get('student').then(function (student) {
        student.set('isActive', true);
        promises.addObject(student.save());
        return registration.get('guardians');
      }).then(function (guardians) {
        if (isPresent(guardians)) {
          guardians.forEach(function (guardian) {
            guardian.set('isActive', true);
            promises.addObject(guardian.save());
          });
        }
        return allSettled(promises);
      }).then(function (results) {
        _promiseHelper.default.logSettledErrors(results);
        return _promiseHelper.default.combineSettledPromise(results);
      });
    }
  };
});