define('supr-web-ember-v2/controllers/authenticated/guardian/student', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var A = Ember.A;
  var computed = Ember.computed;
  var sort = Ember.computed.sort;
  var isPresent = Ember.isPresent;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    currentUserService: service(),

    _nextSort: null, // ascending is default
    _pastSort: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('_nextSort', ['sortDate']);
      this.set('_pastSort', ['sortDate']);
    },


    notifications: computed(function () {
      return this.model.notifications;
    }),

    sortedNotifications: computed.sort('notifications', function (a, b) {
      if ((0, _moment.default)(a.get('timestamp')).isBefore((0, _moment.default)(b.get('timestamp')))) {
        return 1;
      } else if ((0, _moment.default)(a.get('timestamp')).isAfter((0, _moment.default)(b.get('timestamp')))) {
        return -1;
      }
      return 0;
    }),

    aggregateData: computed('model.{todos.[],events.[],bookings.[]}', function () {
      var stream = A();
      var todos = this.model.todos;
      var events = this.model.events;
      var bookings = this.model.bookings;

      if (isPresent(todos)) {
        todos.forEach(function (todo) {
          todo.set('type', 'todo');
          todo.set('icon', 'fa fa-check-circle-o fa-2x fa-fw'); // TODO: put this in config
          todo.set('isTodo', true); // probably use category somehow here
          stream.pushObject(todo);
        });
      }

      if (isPresent(events)) {
        events.forEach(function (event) {
          event.set('type', 'event');
          event.set('icon', 'fa fa-calendar-o fa-2x fa-fw'); // TODO: put this in config
          event.set('isEvent', true); // probably use category somehow here
          stream.pushObject(event);
        });
      }

      if (isPresent(bookings)) {
        bookings.forEach(function (booking) {
          booking.set('type', 'booking');
          booking.set('icon', 'fa fa-pencil-square-o fa-2x fa-fw'); // TODO: put this in config
          booking.set('isBooking', true);
          stream.pushObject(booking);
        });
      }

      return stream;
    }),

    nextItems: computed('aggregateData', 'model.todos.@each.status', function () {
      var items = this.get('aggregateData');

      function nextFilterFunction(item) {
        var included = false;
        included = item.get('sortDate') >= new Date();
        // If a todo is overdue it should still show up in the Next list unless
        // it is complete.
        if (item.get('status') && item.get('status') === 'Complete') {
          included = false;
        }
        if (item.get('status') && item.get('status') === 'Incomplete') {
          included = true;
        }
        return included;
      }
      return items.filter(nextFilterFunction);
    }),
    sortedNextItems: sort('nextItems', '_nextSort'),

    pastItems: computed('aggregateData', 'model.todos.@each.status', function () {
      var items = this.get('aggregateData');

      function pastFilterFunction(item) {
        var included = false;
        included = item.get('sortDate') < new Date();
        if (included && item.get('status') && item.get('status') === 'Incomplete') {
          included = false;
        }
        return included;
      }
      return items.filter(pastFilterFunction);
    }),
    sortedPastItems: sort('pastItems', '_pastSort')
  });
});