define('supr-web-ember-v2/components/availability-grid', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['availability-grid'],

    actions: {
      toggleSlotSelection: function toggleSlotSelection(slot) {
        this.onSelection(slot);
      }
    }
  });
});