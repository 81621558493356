define('supr-web-ember-v2/components/page-progress', ['exports', 'supr-web-ember-v2/templates/components/page-progress'], function (exports, _pageProgress) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var _get = Ember.get;
  var getProperties = Ember.getProperties;
  var htmlSafe = Ember.String.htmlSafe;
  exports.default = Component.extend({
    layout: _pageProgress.default,
    tagName: '',
    pageProgress: service(),

    init: function init() {
      this._super.apply(this, arguments);

      var configs = getProperties(this, 'speed', 'minimum', 'background');
      _get(this, 'pageProgress').setConfig(configs);
    },


    barStyle: computed('pageProgress.barStyle', {
      get: function get() {
        return htmlSafe(_get(this, 'pageProgress.barStyle'));
      }
    }).readOnly()
  });
});