define('supr-web-ember-v2/services/file-service', ['exports', 'firebase', 'uid-safe', 'file-saver'], function (exports, _firebase, _uidSafe, _fileSaver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var resolve = Ember.RSVP.resolve;
  var Promise = Ember.RSVP.Promise;
  var isEmpty = Ember.isEmpty;
  var isPresent = Ember.isPresent;
  exports.default = Service.extend({
    session: service(),
    currentUserService: service(),
    userService: service(),
    store: service(),
    flashService: service(),
    firebaseService: service(),

    // Passing in null for ownerID indicates the case explicitly where a file does
    // not have an owner, like one attached to a Registration
    save: function save(fileRecord, ownerID, ownerType, uploaderID) {
      var self = this;

      // Load owner as necessary to add files to files relation
      var owner = void 0,
          ownerPromise = void 0;
      if (ownerID && ownerType) {
        ownerPromise = this.get('store').findRecord(ownerType, ownerID);
      } else if (ownerID === null && ownerID !== undefined) {
        ownerPromise = resolve();
      } else {
        ownerPromise = resolve(this.get('currentUserService.user'));
      }

      return ownerPromise.then(function (theOwner) {
        owner = theOwner;
        // File must exist in db before it can be saved on the owner
        if (isPresent(owner)) {
          fileRecord.set('ownerID', owner.get('id'));
          if (isEmpty(uploaderID)) {
            fileRecord.set('uploaderID', owner.get('id'));
          } else {
            fileRecord.set('uploaderID', uploaderID);
          }
        }
        // Add the file records to the owner
        return fileRecord.save();
      }).then(function (file) {
        if (isPresent(owner)) {
          owner.get('files').addObject(file);
          return owner.save();
        }
        return resolve();
      }).then(function () {
        self.get('flashService').success('File ' + fileRecord.get('name') + ' uploaded');
        return fileRecord;
      }).catch(function (err) {
        self.get('flashService').error(err.message);
      });
    },

    // Returns url, file name, and storage path
    saveBlob: function saveBlob(blob, fileName) {
      var storageRef = this.get('firebaseService').storage().ref();
      var newName = fileName ? _uidSafe.default.sync(18) + ('-' + fileName) : _uidSafe.default.sync(18);
      var fileRef = storageRef.child(newName);
      var metadata = {
        contentType: blob.type
      };

      // Upload file
      var uploadTask = fileRef.put(blob, metadata);

      return new Promise(function (resolve, reject) {
        uploadTask.on('state_changed', function (snapshot) {
          var progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case _firebase.default.storage.TaskState.PAUSED:
              // or 'paused'
              console.log('Upload is paused');
              break;
            case _firebase.default.storage.TaskState.RUNNING:
              // or 'running'
              console.log('Upload is running');
              break;
          }
        }, function (err) {
          reject(err);
        }, function () {
          // Handle successful uploads on complete
          resolve(uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            console.log('File available at', downloadURL);
            return { url: downloadURL, name: fileName, path: newName };
          }));
        });
      });
    },

    // Although delete may need to remove a file from a user other than the
    // current user saving a file will always be against the current user unless
    // an ownerID and uploaderID are passed in.
    delete: function _delete(fileToDelete) {
      var self = this;
      var ownerID = fileToDelete.get('ownerID');
      var name = fileToDelete.get('name');

      var storageRef = this.get('firebaseService').storage().ref();
      var path = fileToDelete.get('storagePath');
      var fileRef = storageRef.child(path);

      var user = void 0;
      var file = void 0;

      // The file to delete may or may not be a resolved promise so treat it as
      // one.
      return resolve(fileToDelete).then(function (theFile) {
        file = theFile;

        if (ownerID) {
          return self.get('userService').userForId(ownerID).then(function (theUser) {
            user = theUser;
            return user.get('files');
          }).then(function (theFiles) {
            // NOTE What happens if we were deleting a file off a
            // Registration, which wouldn't have an owner?
            theFiles.removeObject(file);
            return user.save();
          });
        } else {
          return resolve();
        }
      }).then(function () {
        return file.destroyRecord();
      }).then(function () {
        return fileRef.delete();
      }).then(function () {
        self.get('flashService').success('File ' + name + ' deleted');
      }).catch(function (err) {
        self.get('flashService').error(err.message, err);
      });
    },

    download: function download(file) {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', file.get('url'), true);
      xhr.responseType = 'blob';
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          (0, _fileSaver.default)(xhr.response, file.get('name'));
        }
      };
      xhr.send(null);
    },

    // A file name is passed in because we have cases where we migrated files from
    // a service where the file name was not part of the url and therefore not
    // extractable. We used our existing File records to get the name.
    fetchFile: function fetchFile(fileURL, fileName) {
      var request = new XMLHttpRequest();

      // Return it as a Promise
      return new Promise(function (resolve, reject) {
        // Setup our listener to process compeleted requests
        request.onreadystatechange = function () {
          // Only run if the request is complete
          if (request.readyState !== 4) return;

          // Process the response
          if (request.status >= 200 && request.status < 300) {
            // If successful
            resolve({ blob: request.response, name: fileName });
          } else {
            // If failed
            reject({
              status: request.status,
              statusText: request.statusText
            });
          }
        };

        // Setup our HTTP request
        request.open('GET', fileURL, true);
        request.responseType = 'blob';

        // Send the request
        request.send(null);
      });
    }
  });
});