define('supr-web-ember-v2/controllers/authenticated/super/repair', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    flashService: service(),
    store: service(),
    modalsManager: service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('repairColumns', [{ propertyName: 'id' }, { propertyName: 'recordType' }, { propertyName: 'relationshipType', title: 'Relationship type' }, { propertyName: 'relationship' }, { propertyName: 'relationshipModel' }, { propertyName: 'missingIds' }, { component: 'repair-record', title: 'Repair' }]);
      this.set('repairGroupedHeaders', [[{ title: 'Broken Relationships', colspan: 7 }]]);
      this.set('statColumns', [{ propertyName: 'recordType' }, { propertyName: 'count', title: 'Count', disableFiltering: true }, { propertyName: 'verifiedBelongsToCount', title: 'Verified belongsTo', disableFiltering: true }, { propertyName: 'verifiedHasManyCount', title: 'Verified hasMany', disableFiltering: true }, { propertyName: 'missingBelongsToRecordCount', title: 'Missing belongsTo', disableFiltering: true }, {
        propertyName: 'missingHasManyRecordCount', title: 'Missing hasMany',
        disableFiltering: true
      }]);
      this.set('statGroupedHeaders', [[{ title: 'Record Statistics', colspan: 6 }]]);
    },


    actions: {
      repairRecord: function repairRecord(repairable) {
        var self = this;
        var body = 'You are about to delete references to the following ids, which are expected to not exist. If they exist you will probably be sorry. Even if they don\'t exist removing the references may cause other unexpected problems. Think this through. Ids being removed: ' + repairable.missingIds.toString();

        this.get('modalsManager').confirm({ title: 'Repair Relationship', body: body }).then(function () {
          // called after user clicks "Yes" in the modal
          self.send('_repairRecord', repairable);
        }).catch(function () {
          // called after user clicks "No" in the modal
          return;
        });
      },
      _repairRecord: function _repairRecord(repairable) {
        var _this = this;

        var repairables = this.get('repairables');
        var record = repairable.record;

        if (repairable.relationshipType === 'belongsTo') {
          record.set(repairable.relationship, null);
          record.save().then(function () {
            return repairables.removeObject(repairable);
          }).catch(function (err) {
            _this.get('flashService').error(err);
          });
        } else {
          var remove = [];
          record.get(repairable.relationship).forEach(function (relation) {
            if (repairable.missingIds.includes(relation.id)) {
              remove.push(relation);
            }
          });
          record.get(repairable.relationship).removeObjects(remove);

          // NOTE:
          // This process works for removing the id of a non-existant record from a hasMany relationship
          // although it throws an error that we can't catch.
          // The error likely has to do with trying to update the belongsTo side of the relationship,
          // which doesn't exist.
          record.save().then(function () {
            return repairables.removeObject(repairable);
          }).catch(function (err) {
            // TODO:
            // Figure out why the Firebase error for trying to delete a non-existant record
            // is showing an error in the console but not triggering this.
            self.get('flashService').error(err);
          });
        }
      }
    }
  });
});