define('supr-web-ember-v2/routes/authenticated/super/users', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var Route = Ember.Route;
  var service = Ember.inject.service;
  var A = Ember.A;
  var all = Ember.RSVP.all;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    flashService: service(),
    can: service(),

    beforeModel: function beforeModel(transition) {
      if (this.get('can').cannot('super in app')) {
        this.get('flashService').error("You don't have the necessary permissions to access " + transition.targetName);
        this.transitionTo('authenticated.unauthorized');
      }

      if (this.get('can').cannot('access in app')) {
        this.get('flashService').error('Your account has been archived. Please contact an administrator for support.');
        this.transitionTo('authenticated.unauthorized');
      }
    },

    model: function model() {
      var students = this.store.findAll('student');
      var guides = this.store.findAll('guide');
      var guardians = this.store.findAll('guardian');
      return all([students, guides, guardians]);
    },
    // FIXME:
    // There is something wierd happening with the minimal data set in that the
    // Guide Mentor role is being returned with no properties, i.e. no 'type',
    // for the user Buck Rogers.
    // Adding the role 'Admin' to that user works as expected.
    // Weird. Leaving the route and coming back to it works, which makes me
    // think the role isn't loaded in time.
    afterModel: function afterModel(model) {
      var roleRelationships = A();
      model.forEach(function (list) {
        list.forEach(function (user) {
          roleRelationships.addObjects(user.get('roles'));
        });
      });
      return all(roleRelationships);
    },
    setupController: function setupController(controller, model) {
      var _model = _slicedToArray(model, 3),
          students = _model[0],
          guides = _model[1],
          guardians = _model[2];
      // NOTE: Using spread syntax to build the array fails to create an Ember friendly array
      // const users = [...students, ...guides, ...guardians];


      var users = A();
      users.addObjects(students);
      users.addObjects(guides);
      users.addObjects(guardians);
      controller.set('model', users);
    }
  });
});