define('supr-web-ember-v2/controllers/authenticated/super', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  var A = Ember.A;
  var resolve = Ember.RSVP.resolve;
  exports.default = Controller.extend({
    flashService: service(),

    verifiedData: A(),
    showUnique: false,
    showDuplicate: true,
    showStudents: false,
    showGuides: false,
    showGuardians: false,

    actions: {
      throwError: function throwError() {
        throw new Error('Test error via super user');
      },
      unhandledPromiseError: function unhandledPromiseError() {
        return resolve().then(function () {
          throw new Error('Unhandled Promise error test via super user');
        });
      }
      // toggleUnique: function() {
      //   this.set('showUnique', !this.get('showUnique'));
      // },
      // toggleDuplicate: function() {
      //   this.set('showDuplicate', !this.get('showDuplicate'));
      // },
      // toggleStudents: function() {
      //   this.set('showStudents', !this.get('showStudents'));
      // },
      // toggleGuides: function() {
      //   this.set('showGuides', !this.get('showGuides'));
      // },
      // toggleGuardians: function() {
      //   this.set('showGuardians', !this.get('showGuardians'));
      // },
      // verifyData: function(model) {
      //   let self = this;
      //   DataIntegrityHelper.checkDataIntegrity(this.store, model)
      //     .then(invalidData => {
      //       self.set('verifiedData', invalidData);
      //     })
      //     .catch(err => {
      //       FlashMessenger.showMessage(
      //         'There was an error checking the data.\n' + err.toString(),
      //         'error'
      //       );
      //     });
      // },
      // fixData: function(model) {
      //   let self = this;
      //   DataIntegrityHelper.fixDataIntegrity(this.store, model)
      //     .then(validData => {
      //       self.set('verifiedData', validData);
      //     })
      //     .catch(err => {
      //       FlashMessenger.showMessage(
      //         'There was an error fixing the data.\n' + err.toString(),
      //         'error'
      //       );
      //     });
      // },
      // viewDuplicateData: function(duplicate) {
      //   this.transitionToRoute('authenticated.duplicate-account', duplicate);
      // },
    }
  });
});