define('supr-web-ember-v2/routes/authenticated/files', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    currentUserService: service(),
    can: service(),
    flashService: service(),

    beforeModel: function beforeModel() {
      if (this.get('can').cannot('access in app')) {
        this.get('flashService').error('Your account has been archived.\nPlease contact an administrator for support.');
        this.transitionTo('authenticated.unauthorized');
      }
    },

    model: function model() {
      var _this = this;

      return this.get('currentUserService.user.files').catch(function (err) {
        return _this.get('flashService').error(err.message);
      });
    },

    afterModel: function afterModel() {
      var _this2 = this;

      var self = this;
      return this.get('currentUserService.user.todos').then(function (todos) {
        self.set('_todos', todos);
      }).catch(function (err) {
        return _this2.get('flashService').error(err.message);
      });
    },

    // TODO: feels pretty heavy handed to create a hash just for the user but
    // we're currently following the pattern started with todos where the
    // hash included a number of items
    setupController: function setupController(controller, model) {
      var todosWithFiles = this.get('_todos').filter(function (todo) {
        return todo.get('hasFiles');
      });

      controller.set('model', model);
      controller.set('todosWithFiles', todosWithFiles);
      controller.set('pick', false);
    }
  });
});