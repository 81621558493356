define('supr-web-ember-v2/routes/unauthenticated/password-reset-finish', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend(_unauthenticatedRouteMixin.default, {
    session: service()
  });
});