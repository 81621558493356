define('supr-web-ember-v2/controllers/authenticated/admin/guides/guide/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    hasAdminRole: false,
    hasGuideMentorRole: false,

    reset: function reset() {
      this.set('hasAdminRole', false);
      this.set('hasGuideMentorRole', false);
    }
  });
});