define('supr-web-ember-v2/models/calendar-event', ['exports', 'ember-data', 'supr-web-ember-v2/models/base', 'moment'], function (exports, _emberData, _base, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var alias = Ember.computed.alias;
  exports.default = _base.default.extend({
    name: _emberData.default.attr('string'),
    startDate: _emberData.default.attr('date'),
    endDate: _emberData.default.attr('date'),
    sortDate: alias('startDate'),
    note: _emberData.default.attr('string'),
    category: _emberData.default.belongsTo('category', {
      async: true
    }),
    tags: _emberData.default.hasMany('tag', {
      async: true
    }),
    displayDate: computed('sortDate', function () {
      return (0, _moment.default)(this.get('sortDate')).format("dddd, MMMM Do, h:mm a");
    })
  });
});