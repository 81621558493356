define('supr-web-ember-v2/routes/authenticated/guardian/student', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  var hash = Ember.RSVP.hash;
  var all = Ember.RSVP.all;
  var isPresent = Ember.isPresent;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    can: service(),
    flashService: service(),
    currentUserService: service(),

    beforeModel: function beforeModel() {
      if (!this.get('can').can('access in app')) {
        this.get('flashService').error('Your account has been archived.\nPlease contact an administrator for support.');
        this.transitionTo('authenticated.unauthorized');
      }
      if (!this.get('can').can('guard in app')) {
        this.get('flashService').error('Only guardians can access this page.\nPlease contact an administrator for support.');
        this.transitionTo('authenticated.unauthorized');
      }
    },

    model: function model(params) {
      var student = void 0;
      return this.store.findRecord('student', params.student_id).then(function (theStudent) {
        student = theStudent;
        return student.get('calendar'); // we need to resolve this before events
      }).then(function () {
        var todos = student.get('todos');
        var events = student.get('calendar.events');
        var bookings = student.get('bookings');
        var notifications = student.get('notifications');
        return hash({
          user: student,
          todos: todos,
          events: events,
          bookings: bookings,
          notifications: notifications
        });
      });
    },
    afterModel: function afterModel(model) {
      if (isPresent(model.bookigns)) {
        var promises = model.bookings.map(function (booking) {
          return booking.loadRelations();
        });
        return all(promises);
      }
    }
  });
});