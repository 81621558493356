define('supr-web-ember-v2/controllers/authenticated/super/users', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  var readOnly = Ember.computed.readOnly;
  exports.default = Controller.extend({
    flashService: service(),

    columns: null,
    users: readOnly('model'),

    // FIXME:
    // Filtering and sorting don't seem to work with a computed property.
    // There may be a way to work with the roles relationship directly but it will
    // take some more investigation.
    init: function init() {
      this._super.apply(this, arguments);
      this.set('columns', [{ propertyName: 'id' }, { propertyName: 'firstName' }, { propertyName: 'lastName' }, { propertyName: 'email' }, {
        component: 'roles-list',
        title: 'Roles'
        // sortedBy: 'roles',
        // filteredBy: 'roles',
      }]);
    }
  });
});