define('supr-web-ember-v2/components/models-table/themes/ember-bootstrap-v3/row-filtering-cell', ['exports', 'ember-models-table/components/models-table/themes/ember-bootstrap-v3/row-filtering-cell'], function (exports, _rowFilteringCell) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _rowFilteringCell.default;
    }
  });
});