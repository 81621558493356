define("supr-web-ember-v2/models/role", ["exports", "ember-data", "supr-web-ember-v2/models/base", "moment"], function (exports, _emberData, _base, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _base.default.extend({
    type: _emberData.default.attr("string"), // Types include Admin and Guide Mentor
    displayCreatedAtDate: computed("createdAt", function () {
      return (0, _moment.default)(this.get("createdAt")).format("ddd, MMM Do, h:mm a z");
    })
  });
});