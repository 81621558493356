define('supr-web-ember-v2/services/firebase-service', ['exports', 'supr-web-ember-v2/config/environment', 'firebase'], function (exports, _environment, _firebase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var debug = Ember.debug;
  exports.default = Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      if (_firebase.default.apps.length > 0) {
        return _firebase.default.app();
      }

      try {
        _firebase.default.initializeApp(_environment.default.firebase);
      } catch (e) {
        console.error(e);
        debug(e);
      }
    },

    auth: function auth() {
      return _firebase.default.auth();
    },

    storage: function storage() {
      return _firebase.default.storage();
    }

    // TODO:
    // Firebse 4.12.0 introduces functions sdk and callables. We're stuck on 3.9.0
    // with Emberfire until 3.x.
    // Trying to add/update Firebase results in conflicting firebase requirements:
    // https://github.com/firebase/emberfire/issues/489
    // functions: function() {
    //   return firebase.functions();
    // },
  });
});