define('supr-web-ember-v2/routes/authenticated/super', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  var hash = Ember.RSVP.hash;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    flashService: service(),
    can: service(),

    beforeModel: function beforeModel(transition) {
      if (this.get('can').cannot('super in app')) {
        this.get('flashService').error("You don't have the necessary permissions to access " + transition.targetName);
        this.transitionTo('authenticated.unauthorized');
      }

      if (this.get('can').cannot('access in app')) {
        this.get('flashService').error('Your account has been archived. Please contact an administrator for support.');
        this.transitionTo('authenticated.unauthorized');
      }
    },

    model: function model() {
      var self = this;
      return hash({
        students: self.store.findAll('student'),
        guides: self.store.findAll('guide'),
        guardians: self.store.findAll('guardian')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      // let {duplicate, unique, noEmail} = DataIntegrityHelper.checkAccountsIntegrity(this.store, model);
      // controller.set('uniqueAccounts', unique);
      // controller.set('duplicateAccounts', duplicate);
      // controller.set('noEmail', noEmail);
    },
    actions: {
      // addCalendar: function(user) {
      //   this.store.createRecord('calendar', {ownerID: user.get('id')}).save()
      //   .then(function(calendar) {
      //     user.set('calendar', calendar);
      //     return user.save();
      //   })
      //   .then(function(){
      //     FlashMessenger.showMessage('Calendar added to ' + user.get('fullName'), 'success');
      //   })
      //   .catch(function(error) {
      //     FlashMessenger.showMessage('Error creating calendar for ' + user.get('fullName') + '. ' + error, 'error');
      //   });
      // },
      // addAddress: function(user) {
      //   this.store.createRecord('address').save()
      //   .then(function(address) {
      //     user.set('address', address);
      //     return user.save();
      //   })
      //   .then(function(){
      //     FlashMessenger.showMessage('Address added to ' + user.get('fullName'), 'success');
      //   })
      //   .catch(function(error) {
      //     FlashMessenger.showMessage('Error creating address for ' + user.get('fullName') + '. ' + error, 'error');
      //   });
      // },
      // sendTestMail: function(){
      //   this.mailHelper.sendTestMail();
      // }
    }
  });
});