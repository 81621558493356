define('supr-web-ember-v2/app', ['exports', 'supr-web-ember-v2/resolver', 'ember-load-initializers', 'supr-web-ember-v2/config/environment'], function (exports, _resolver, _emberLoadInitializers, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Application = Ember.Application;
  var RSVP = Ember.RSVP;

  // import { run } from '@ember/runloop';

  var App = Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  // Heavy handed debugging
  if (_environment.default.environment === 'development') {
    // run.backburner.DEBUG = true; // serious slow down: use for emergencies

    // V2: May not want to deal with all deprecations at this point and thus turn off these 2
    Ember.ENV.RAISE_ON_DEPRECATION = true;
    Ember.LOG_STACKTRACE_ON_DEPRECATION = true;

    Ember.LOG_BINDINGS = false;

    // Catch errors that happen within promises.
    // Errors within promises are not passed on to the catch block.
    // Assertions are removed from Production code.
    RSVP.on('error', function (error) {
      (true && !(false) && Ember.assert(error, false));

      console.error(error);
    });
  }

  exports.default = App;
});