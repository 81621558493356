define('supr-web-ember-v2/components/models-table/themes/ember-bootstrap-v3/data-group-by-select', ['exports', 'ember-models-table/components/models-table/themes/ember-bootstrap-v3/data-group-by-select'], function (exports, _dataGroupBySelect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _dataGroupBySelect.default;
    }
  });
});