define('supr-web-ember-v2/components/mt-booking-actions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  exports.default = Component.extend({
    classNames: ['mt-booking-actions'],
    sendAction: null,
    record: null,
    actions: {
      sendAction: function sendAction(actionName, record) {
        get(this, 'sendAction')(actionName, record);
      }
    }
  });
});