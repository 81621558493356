define('supr-web-ember-v2/components/models-table/cell-content-edit', ['exports', 'ember-models-table/components/models-table/cell-content-edit'], function (exports, _cellContentEdit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _cellContentEdit.default;
    }
  });
});