define('supr-web-ember-v2/components/my-bread-crumbs', ['exports', 'supr-web-ember-v2/templates/components/my-bread-crumbs'], function (exports, _myBreadCrumbs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var getOwner = Ember.getOwner;
  var get = Ember.get;
  var computed = Ember.computed;
  var getWithDefault = Ember.getWithDefault;
  var setProperties = Ember.setProperties;
  var typeOf = Ember.typeOf;
  var A = Ember.A;
  var classify = Ember.String.classify;
  var bool = Ember.computed.bool;
  exports.default = Component.extend({
    layout: _myBreadCrumbs.default,
    tagName: 'ol',
    linkable: true,
    reverse: false,
    minimumPathLength: 2,
    classNameBindings: ['breadCrumbClass'],
    hasBlock: bool('template').readOnly(),
    currentUrl: computed.readOnly('applicationRoute.router.url'),
    currentRouteName: computed.readOnly('applicationRoute.controller.currentRouteName'),

    routeHierarchy: computed('outputStyle', 'shouldDisplay', function () {
      var currentRouteName = getWithDefault(this, 'currentRouteName', false);

      (true && !(currentRouteName) && Ember.assert('[ember-crumbly] Could not find a curent route', currentRouteName));


      var routeNames = currentRouteName.split('.');
      var filteredRouteNames = this._filterIndexAndLoadingRoutes(routeNames);
      var crumbs = this._lookupBreadCrumb(routeNames, filteredRouteNames);

      return get(this, 'reverse') ? crumbs.reverse() : crumbs;
    }),

    breadCrumbClass: computed('outputStyle', 'shouldDisplay', function () {
      var className = 'breadcrumb';
      var outputStyle = getWithDefault(this, 'outputStyle', '');
      var lowerCaseOutputStyle = outputStyle.toLowerCase();

      if (lowerCaseOutputStyle === 'foundation') {
        className = 'breadcrumbs';
      }

      if (!get(this, 'shouldDisplay')) {
        className = 'noBreadCrumbs';
      }

      return className;
    }),

    shouldDisplay: computed('currentUrl', 'currentRouteName', 'minimumPathLength', function () {
      var display = true;
      var currentRouteName = getWithDefault(this, 'currentRouteName', false);

      (true && !(currentRouteName) && Ember.assert('[ember-crumbly] Could not find a curent route', currentRouteName));


      var routeNames = currentRouteName.split('.');
      var filteredRouteNames = this._filterIndexAndLoadingRoutes(routeNames);

      var pathLength = filteredRouteNames.length;

      if (pathLength < get(this, 'minimumPathLength')) {
        display = false;
      }
      return display;
    }),

    _guessRoutePath: function _guessRoutePath(routeNames, name, index) {
      var routes = routeNames.slice(0, index + 1);

      if (routes.length === 1) {
        var path = name + '.index';

        return this._lookupRoute(path) ? path : name;
      }

      return routes.join('.');
    },
    _filterIndexAndLoadingRoutes: function _filterIndexAndLoadingRoutes(routeNames) {
      return routeNames.filter(function (name) {
        return !(name === 'index' || name === 'loading');
      });
    },
    _lookupRoute: function _lookupRoute(routeName) {
      return getOwner(this).lookup('route:' + routeName);
    },
    _lookupBreadCrumb: function _lookupBreadCrumb(routeNames, filteredRouteNames) {
      var _this = this;

      var defaultLinkable = get(this, 'linkable');
      var pathLength = filteredRouteNames.length;

      var breadCrumbs = filteredRouteNames.map(function (name, index) {
        var path = _this._guessRoutePath(routeNames, name, index);
        var route = _this._lookupRoute(path);
        var isHead = index === 0;
        var isTail = index === pathLength - 1;

        var crumbLinkable = index === pathLength - 1 ? false : defaultLinkable;

        (true && !(route) && Ember.assert('[ember-crumbly] `route:' + path + '` was not found', route));


        var breadCrumb = getWithDefault(route, 'breadCrumb', {
          title: classify(name)
        });

        if (typeOf(breadCrumb) === 'null') {
          return;
        } else {
          setProperties(breadCrumb, {
            path: path,
            isHead: isHead,
            isTail: isTail,
            linkable: breadCrumb.hasOwnProperty('linkable') ? breadCrumb.linkable : crumbLinkable
          });
        }

        return breadCrumb;
      });

      return A(breadCrumbs.filter(function (breadCrumb) {
        return typeOf(breadCrumb) !== 'undefined';
      }));
    }
  });
});