define('supr-web-ember-v2/components/modals-container/alert', ['exports', 'ember-bootstrap-modals-manager/components/modals-container/alert'], function (exports, _alert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _alert.default;
    }
  });
});