define('supr-web-ember-v2/routes/authenticated/admin/students/student/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  var isEmpty = Ember.isEmpty;
  var isPresent = Ember.isPresent;
  exports.default = Route.extend({
    flashService: service(),

    afterModel: function afterModel() {
      return this.set('guides', this.store.findAll('guide'));
    },

    setupController: function setupController(controller, model) {
      controller.set('specialPrograms', ['IB', 'Masters', 'AP', 'Virtual High School', 'Home School', 'Other']);
      controller.set('selectedSpecialProgram', model.get('learningProfile').get('specialProgram'));
      controller.set('selectedTextbook', model.get('learningProfile').get('textBook'));
      controller.set('publishers', ['Nelson', 'Ryerson', 'Other']);
      controller.set('guides', this.get('guides'));
      controller.set('selectedPrimaryGuide', model.get('primaryGuide'));
      this._super(controller, model); // need this to pass the model on
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.reset();
      }
    },

    deactivate: function deactivate() {
      this.currentModel.rollbackAttributes();
    },

    actions: {
      save: function save() {
        var self = this;
        // Deal with Selections
        var learningProfile = this.currentModel.get('learningProfile');
        var selectedSpecialProgram = this.controller.get('selectedSpecialProgram');
        if (!isEmpty(selectedSpecialProgram)) {
          // apply the selected program
          learningProfile.set('specialProgram', selectedSpecialProgram);
        }
        var selectedTextbook = this.controller.get('selectedTextbook');
        if (!isEmpty(selectedTextbook)) {
          // apply the selected program
          learningProfile.set('textBook', selectedTextbook);
        }

        // If selected guide changed we remove the Student from it's old Guide and
        // add it to the new Guide
        var student = this.currentModel;
        var selectedPrimaryGuide = this.get('controller.selectedPrimaryGuide');
        if (isPresent(selectedPrimaryGuide) && selectedPrimaryGuide.get('id') !== student.get('primaryGuide.id')) {
          // remove from old Guide
          student.get('primaryGuide').then(function (oldGuide) {
            if (isPresent(oldGuide)) {
              return oldGuide.get('students').then(function (students) {
                students.removeObject(student);
                return oldGuide.save();
              });
            }
          }).then(function () {
            // update the Student
            student.set('primaryGuide', selectedPrimaryGuide);
            student.save();
            // add to new Guide
            selectedPrimaryGuide.get('students').then(function (students) {
              students.pushObject(student);
              selectedPrimaryGuide.save();
            });
          });
        }

        this.currentModel.get('learningObjective').then(function (theLearningObjective) {
          if (isPresent(theLearningObjective)) {
            theLearningObjective.save();
          }
        });
        this.currentModel.get('learningProfile').then(function (theLearningProfile) {
          if (isPresent(theLearningProfile)) {
            theLearningProfile.save();
          }
        });
        this.currentModel.save().then(function () {
          self.transitionTo('authenticated.admin.students.student');
        });
      },

      cancel: function cancel() {
        this.transitionTo('authenticated.admin.students');
      }
    }
  });
});